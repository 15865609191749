import { TableCell, TableRow, TableHead, TableBody, TableContainer, Paper, Table } from "@mui/material"
import React from "react"
import { Columns, CellData, TableData, Cell } from "../../Utils/tableInterface"


export default function GenericTable(props:TableData){

    const headerColumns = (column:Columns, index:number) =>{
        return(
          <TableCell key={index + column.name} style={(column.sticky && column.backgroundColor)?{ zIndex: 900, left: 0, position:'sticky', backgroundColor:column.backgroundColor}:column.sticky?{ zIndex: 900, left: 0, position:'sticky'}:column.backgroundColor?{ backgroundColor:column.backgroundColor}:{}} width={column.width?column.width:undefined} align={column.align!=undefined?column.align:'center'}>{column.name}</TableCell>
        )
      }
    
    
      const mapRows = (row:CellData, index:number)=>{
        return(
          <>
                      <TableRow key={index}>
                {row.cells.map((data, index)=>
                  mapRow(data, index)
                )}
                </TableRow></>
        )
      }
      const mapRow = (row:Cell, index:number)=>{
        return(
            <TableCell key={index + row.type} style={index==0?{ position: 'sticky', left: 0, zIndex: 800,}:{}} align={row.align!=undefined?row.align:'center'}>{row.text}</TableCell>
    
        )
      }
     
      
      const head = () =>{
        return(
          <TableHead>
          <TableRow>
          {props.columns.map((column, index)=>
            headerColumns(column, index)
          )}
          </TableRow>
        </TableHead>
            )
            }
    
            const body = () =>{
              return(
                <TableBody>
                {props.rows.map((rows, index)=>
                  mapRows(rows, index)
                )}
              </TableBody>
                  )
                  }
          
    
    
    
    return(
    <TableContainer component={Paper} style={{maxHeight:'450px', margin:'8px 0px', borderRadius:'0px'}}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        {head()}
        {body()}
      </Table>
    </TableContainer>)
}