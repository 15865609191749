import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import GymDailyPerformanceInputContainer from "../GymDailyPerformanceInput/GymDailyPerformanceInputContainer";
import TrainerComponentContainer from "../TrainerInput/TrainerComponentContainer";
import TrainerTableContainer from "../TrainerTable/TrainerTableContainer";
import {
  ChoiceData,
  FranchiseInterface,
  GymInterface,
} from "./ChoicePageContainer";
import genericStyle from "../genericStyle.module.css";
import { supabase } from "../../config/config";
import "@silevis/reactgrid/styles.css";
import { ThemeContext } from "../../context/ThemeContext";
import { getUuid } from "../../Utils/utils";
import TrainerTableIncassiContainer from "../TrainerTable Incassi/TrainerTableIncassiContainer";

const generateGymList = (data: GymInterface, clickEvent: Function) => {
  const handleClick = () => {
    clickEvent(data);
  };

  return (
    <label key={data.name + data.id} onClick={handleClick}>
      <button>{data.name}</button>
    </label>
  );
};
async function getGyms(corporate_id: number): Promise<GymInterface[]> {
  let uuid = await getUuid();
  let result = await supabase
    .from("gym")
    .select("*, gym_trainer(*)")
    .eq("corporate_id", corporate_id)
    .eq("gym_trainer.trainer_id", uuid)
    .order("name", { ascending: true });
  console.log(result);
  let data = result.data;
  //return only the data that has a list of gym_trainers grater than 0
  //@ts-ignore
  return data.filter((gym: GymInterface) => gym.gym_trainer.length > 0);
  //@ts-ignore
  return result.data;
}
const ChoicePage: FunctionComponent<ChoiceData> = (props: ChoiceData) => {
  console.log(props);
  const [franchiseData, setfranchiseData] = useState<FranchiseInterface | null>(
    null
  );
  const [gymData, setgymData] = useState<GymInterface | null>(null);
  const [showSelection, setshowSelection] = useState<boolean>(false);
  const [showGyms, setshowGyms] = useState<boolean>(false);
  const [option, setoption] = useState<string | null>(null);
  const themeContext = React.useContext(ThemeContext);
  const [gyms, setGyms] = useState<GymInterface[]>([]);
  const date = new Date();

  useEffect(() => {}, []);
  const selectGym = (data: GymInterface) => {
    setgymData(data);
    setshowSelection(true);
  };
  const selectFranchise = async (data: FranchiseInterface) => {
    setfranchiseData(data);
    //@ts-ignore
    let gyms = await getGyms(data.id);
    //@ts-ignore
    let gyms2 = await getGyms(data.corporate_name.id);
    setGyms(gyms2);
    //@ts-ignore
    setgymData(gyms);
    console.log(gyms);
    setshowGyms(true);
  };

  const handleButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setoption(event.currentTarget.value);
  };

  const goBack = () => {
    setoption(null);
  };

  const backToGyms = () => {
    setshowSelection(false);
  };
  const backToFranchises = () => {
    setshowGyms(false);
  };

  if (!showSelection) {
    if (!showGyms) {
      return (
        <div className={genericStyle.flexCont}>
          <div className={genericStyle.flexTitle}>
            <h2>Seleziona Società</h2>
            <span>Utente: {themeContext?.nameSurname}</span>
          </div>
          <div className={genericStyle.flexButtonMain}>
            {props.franchiseData
              ? props.franchiseData.map((franchise) =>
                  generateGymList(franchise, selectFranchise)
                )
              : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className={genericStyle.flexCont}>
          <div className={genericStyle.flexTitle}>
            <h2>Seleziona Palestra</h2>
            <div>
              <span>Società: {franchiseData?.name}</span>
              <span>Utente: {themeContext?.nameSurname}</span>
            </div>
          </div>
          <div className={genericStyle.flexButtonMain}>
            {gyms ? gyms.map((gym) => generateGymList(gym, selectGym)) : null}
          </div>
          <button
            onClick={backToFranchises}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    }
  } else {
    if (gymData && option == null) {
      return (
        <div className={genericStyle.flexCont}>
          <div className={genericStyle.flexTitle}>
            <h2>Sede: {gymData.name}</h2>
            <div>
              <span>Società: {franchiseData?.name}</span>
              <span>Utente: {themeContext?.nameSurname}</span>
            </div>
          </div>
          <div className={genericStyle.flexButtonCol}>
            <button value={"t_data"} onClick={handleButton}>
              Inserisci Dati lavoro
            </button>
            <button value={"e_data"} onClick={handleButton}>
              Inserisci Dati incassi
            </button>
            <button value={"get_t_data"} onClick={handleButton}>
              Visualizza storico dati lavoro
            </button>
            <button value={"get_i_data"} onClick={handleButton}>
              Visualizza storico dati incassi
            </button>
          </div>
          <button onClick={backToGyms} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && franchiseData && option == "t_data") {
      return (
        <div className={genericStyle.flexCont}>
          <TrainerComponentContainer
            franchiseData={franchiseData}
            gymData={gymData}
            backFunction ={goBack}
          />
          <button onClick={goBack} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && franchiseData && option == "e_data") {
      return (
        <div className={genericStyle.flexCont}>
          <GymDailyPerformanceInputContainer
            franchiseData={franchiseData}
            gymData={gymData}
            backFunction ={goBack}
          />
          <button onClick={goBack} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && franchiseData && option == "get_t_data") {
      return (
        <div className={genericStyle.flexContStart}>
          <TrainerTableContainer
            franchiseData={franchiseData}
            gymData={gymData}
          />
          <button onClick={goBack} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && franchiseData && option == "get_i_data") {
      return (
        <div className={genericStyle.flexContStart}>
          <TrainerTableIncassiContainer
            franchiseData={franchiseData}
            gymData={gymData}
          />
          <button onClick={goBack} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    }else {
      return <div></div>;
    }
  }
};
export default ChoicePage;
