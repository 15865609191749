import { Column, DefaultCellTypes, Row } from "@silevis/reactgrid";
import React, { useContext, useEffect, useState } from "react";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import TrainerTable from "./TrainerTable";
import { Database } from "../../../../database1.types";
import { supabase } from "../../config/config";
import { CellData, Columns } from "../../Utils/tableInterface";
import { getUuid } from "../../Utils/utils";
import MonthPicker from "../DatePicker/MonthDatePicker";
import {
  convertDateYMD,
  convertToItalyDate,
  getEndDate,
  getStartDate,
} from "../../Utils/DateUtil";
import genericStyle from "../genericStyle.module.css";
import FullDatePicker from "../DatePicker/FullDatePicker";

interface Person {
  name: string;
  surname: string;
}

export interface TableData {
  columns: Columns[];
  rows: CellData[];
  gymData: GymInterface;
  franchiseData: FranchiseInterface;
}

const getRows = (rows: finalTable[]) => [
  ...rows.map<CellData>((row, idx) => ({
    cells: [
      { type: "text", text: row.date ? convertToItalyDate(row.date) : "" },
      {
        type: "text",
        text:
          typeof row.performed_tests == "string"
            ? row.performed_tests
            : row.performed_tests.toString(),
      },
      {
        type: "text",
        text:
          typeof row.taken_tests == "string"
            ? row.taken_tests
            : row.taken_tests.toString(),
      },
      {
        type: "text",
        text:
          typeof row.planned_renewals == "string"
            ? row.planned_renewals
            : row.planned_renewals.toString(),
      },
      {
        type: "text",
        text:
          typeof row.made_renewals == "string"
            ? row.made_renewals
            : row.made_renewals.toString(),
      },
      {
        type: "text",
        text:
          typeof row.training_sessions == "string"
            ? row.training_sessions
            : row.training_sessions.toString(),
      },

      {
        type: "text",
        text:
          typeof row.work_hours == "string"
            ? row.work_hours
            : row.work_hours.toString(),
      },
      {
        type: "text",
        text:
          typeof row.break_hours == "string"
            ? row.break_hours
            : row.break_hours.toString(),
      },
      { type: "text", text: row.break_work ? row.break_work : "" },
      {
        type: "text",
        text:
          typeof row.progression_performed_tests == "string"
            ? row.progression_performed_tests
            : row.progression_performed_tests.toString(),
      },
      {
        type: "text",
        text:
          typeof row.progression_taken_tests == "string"
            ? row.progression_taken_tests
            : row.progression_taken_tests.toString(),
      },
      {
        type: "text",
        text:
          typeof row.progression_made_renewals == "string"
            ? row.progression_made_renewals
            : row.progression_made_renewals.toString(),
      },
      {
        type: "text",
        text:
          typeof row.progression_planned_renewals == "string"
            ? row.progression_planned_renewals
            : row.progression_planned_renewals.toString(),
      },
      {
        type: "text",
        text:
          typeof row.progression_training_sessions == "string"
            ? row.progression_training_sessions
            : row.progression_training_sessions.toString(),
      },
      {
        type: "text",
        text:
          typeof row.progression_work_hours == "string"
            ? row.progression_work_hours
            : row.progression_work_hours.toString(),
      },
      {
        type: "text",
        text:
          typeof row.progression_break_hours == "string"
            ? row.progression_break_hours
            : row.progression_break_hours.toString(),
      },

      { type: "text", text: row.upgrade_previsional },
      { type: "text", text: row.upgrade_done },
      { type: "text", text: row.progression_upgrade_previsional },
      { type: "text", text: row.progression_upgrade_done },
    ],
  })),
];

const headerRow: Row = {
  rowId: "header",
  cells: [
    { type: "header", text: "Data" },
    { type: "header", text: "Prove effettuate" },
    { type: "header", text: "Prove prese" },
    { type: "header", text: "Rinnovi previsti" },
    { type: "header", text: "Rinnovi fatti" },
    { type: "header", text: "Allenamenti" },
    { type: "header", text: "Ore lavoro" },
    { type: "header", text: "Ore buca" },

    { type: "header", text: "Prog. Prove effettuate" },
    { type: "header", text: "Prog. Prove prese" },
    { type: "header", text: "Prog. Rinnovi previsti" },
    { type: "header", text: "Prog. Rinnovi fatti" },
    { type: "header", text: "Prog. Allenamenti" },
    { type: "header", text: "Prog. Ore lavoro" },
    { type: "header", text: "Prog. Ore buca" },

    { type: "header", text: "Lavori svolti durante ore buca" },
  ],
};

const columns: Columns[] = [
  {
    name: "Data",
    width: 150,
    sticky: true,
  },
  {
    name: "Prove effettuate",
    width: 70,
  },
  {
    name: "Prove prese",
    width: 70,
  },
  {
    name: "Rinnovi previsti",
    width: 70,
  },
  {
    name: "Rinnovi fatti",
    width: 70,
  },
  {
    name: "Allenamenti",
    width: 70,
  },
  {
    name: "Orario lavoro",
    width: 70,
  },
  {
    name: "Ore buca",
    width: 70,
  },
  {
    name: "Lavori svolti durante ore buca",
    width: 500,
  },

  {
    name: "Prog. Prove effettuate",
    width: 70,
  },
  {
    name: "Prog. Prove prese",
    width: 70,
  },
  {
    name: "Prog. Rinnovi previsti",
    width: 70,
  },
  {
    name: "Prog. Rinnovi fatti",
    width: 70,
  },
  {
    name: "Prog. Allenamenti",
    width: 70,
  },
  {
    name: "Prog. Ore lavoro",
    width: 70,
  },
  {
    name: "Prog. Ore buca",
    width: 70,
  },
  {
    name: "Upgrade Previsti",
    width: 70,
  },
  {
    name: "Upgrade Fatti",
    width: 70,
  },
  {
    name: "Prog. Upgrade Previsti",
    width: 70,
  },
  {
    name: "Prog. Upgrade Fatti",
    width: 70,
  },
];

const getPeople = (): finalTable[] => [
  {
    break_hours: 1,
    break_work: "string",
    created_at: "string",
    date: "string",
    gym_id: 1,
    id: 1,
    made_renewals: 1,
    new_clients: 1,
    performed_tests: 1,
    planned_renewals: 1,
    taken_tests: 1,
    trainer_id: "string",
    training_sessions: 1,
    work_hours: 1,
    progression_performed_tests: 0,
    progression_taken_tests: 0,
    progression_planned_renewals: 0,
    progression_made_renewals: 0,
    progression_training_sessions: 0,
    progression_new_clients: 0,
    progression_work_hours: 0,
    progression_break_hours: 0,
    upgrade_previsional: 0,
    upgrade_done: 0,
    progression_upgrade_done: 0,
    progression_upgrade_previsional: 0,
  },
];

type trainerTableProps =
  Database["public"]["Tables"]["trainer_daily_performance"]["Row"];

export default function TrainerTableContainer(props: {
  gymData: GymInterface;
  franchiseData: FranchiseInterface;
}) {
  const currentDate = new Date();
  const [people, setPeople] = React.useState<finalTable[]>(getPeople());
  const [rows, setRows] = React.useState<CellData[]>(getRows(people));
  const [dateStart, setDateStart] = React.useState<Date>(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [dateEnd, setDateEnd] = React.useState<Date>(new Date());
  useEffect(() => {
    async function sendTrainerTableProps(gym_id: number) {
      let uuid = await getUuid();
      let start = getStartDate(dateStart);

      let end = getEndDate(dateEnd);
      supabase
        .from("trainer_daily_performance")
        .select("*")
        .eq("gym_id", parseInt(props.gymData.id))
        .eq("trainer_id", uuid)
        .gte("date", start)
        .lte("date", end)
        .then((res) => {
          //@ts-ignore
          let b = setProgression(res.data);
          setRows(getRows(b));
          console.log(res);
        });
    }
    sendTrainerTableProps(parseInt(props.gymData.id));
  }, []);

  useEffect(() => {
    console.log(rows);
  }, [rows]);

  const onFilter = async () => {
    let uuid = await getUuid();
    let start = getStartDate(dateStart);

    let end = getEndDate(dateEnd);
    supabase
      .from("trainer_daily_performance")
      .select("*")
      .eq("gym_id", parseInt(props.gymData.id))
      .eq("trainer_id", uuid)
      .gte("date", start)
      .lte("date", end)
      .then((res) => {
        //@ts-ignore
        let b = setProgression(res.data);
        setRows(getRows(b));
        console.log(res);
      });
  };
  return (
    <div>
      <div className={genericStyle.filterCont}>
        <label>
          Data Inizio:
          <FullDatePicker selectedDate={dateStart} dateSet={setDateStart} />
        </label>
        <label>
          Data fine:
          <FullDatePicker selectedDate={dateEnd} dateSet={setDateEnd} />
        </label>
        <button onClick={onFilter}>Filtra</button>
      </div>
      <TrainerTable
        franchiseData={props.franchiseData}
        gymData={props.gymData}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
interface Trainer_daily_performance {
  id: number /* primary key */;
  trainer_id?: string /* foreign key to profiles.id */;
  gym_id: number /* foreign key to gym.id */;
  performed_tests: number;
  taken_tests: number;
  planned_renewals: number;
  made_renewals: number;
  training_sessions: number;
  new_clients: number;
  work_hours: number;
  break_hours: number;
  break_work?: string;
  date?: string;
  created_at?: string;
  profiles?: string;
  gym?: number;
  upgrade_previsional: number;
  upgrade_done: number;
}
interface Trainer_daily_performance_progression {
  progression_performed_tests: number;
  progression_taken_tests: number;
  progression_planned_renewals: number;
  progression_made_renewals: number;
  progression_training_sessions: number;
  progression_new_clients: number;
  progression_work_hours: number;
  progression_break_hours: number;
  progression_upgrade_previsional: number;
  progression_upgrade_done: number;
}
type finalTable = Trainer_daily_performance &
  Trainer_daily_performance_progression;
function setProgression(data: Trainer_daily_performance[]) {
  let new_rows: finalTable[] = [];
  let progression: Trainer_daily_performance_progression = {
    progression_performed_tests: 0,
    progression_taken_tests: 0,
    progression_planned_renewals: 0,
    progression_made_renewals: 0,
    progression_training_sessions: 0,
    progression_new_clients: 0,
    progression_work_hours: 0,
    progression_break_hours: 0,
    progression_upgrade_previsional: 0,
    progression_upgrade_done: 0,
  };
  for (let raw_int = 0, raw_len = data.length; raw_int < raw_len; raw_int++) {
    let raw = data[raw_int];
    progression.progression_performed_tests += raw.performed_tests;
    progression.progression_taken_tests += raw.taken_tests;
    progression.progression_planned_renewals += raw.planned_renewals;
    progression.progression_made_renewals += raw.made_renewals;
    progression.progression_training_sessions += raw.training_sessions;
    progression.progression_new_clients += raw.new_clients;
    progression.progression_work_hours += raw.work_hours;
    progression.progression_break_hours += raw.break_hours;
    progression.progression_upgrade_previsional += raw.upgrade_previsional;
    progression.progression_upgrade_done += raw.upgrade_done;
    new_rows.push({ ...raw, ...progression });
  }
  console.log(new_rows);
  return new_rows;
}
