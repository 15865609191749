import React, { FunctionComponent, MouseEventHandler, useEffect, useState } from "react";
import { TrainerData } from "./RemoveTrainerContainer";
import genericStyle from "../genericStyle.module.css"

const CreateInput = (type:string, name:string, onChange:Function) => {
    return (
        <div className={genericStyle.inputCont} key={type+name}>
            <span>{name}</span>
            <input type={type} name={name} onChange={(e) => onChange(e.target.value)} />
        </div>
    )
}


const RemoveTrainer: FunctionComponent<TrainerData> = (props: TrainerData) => {


        return (<div className={genericStyle.formCont}>
            <div>
            <h2>Rimuovi Trainer</h2>
            {props.franchiseData.name!=''?<span>Società: {props.franchiseData.name}</span>:null}
            <span>Sede: {props.gymData.name}</span>

            </div>
            <div className={genericStyle.fullWidth}>

            <label className={genericStyle.inputCont}>
          Trainer:{" "}
          <select onChange={(e) => props.setData(e)}>
            <option key={'none'} value={""} data-trainername={""} data-trainersurname={""}>{""}</option>
            {props.trainerData.map((data) => (
              <option key={data.trainer_id} data-trainername={data.profiles.name} data-trainersurname={data.profiles.surname}value={data.trainer_id}>{data.profiles.name + ' ' + data.profiles.surname}</option>
            ))}
          </select>
        </label>
        </div>
            <button onClick={()=>props.uploadFunction()}>Rimuovi</button>
            </div>)

};
export default RemoveTrainer;
