import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FullDatePicker = (props: { selectedDate: any; dateSet: Function }) => {
  const handleChange = (date: any) => {
    props.dateSet(date);
  };

  return (
    <DatePicker
      selected={props.selectedDate}
      onChange={handleChange}
      dateFormat="dd/MM/yyyy"
    />
  );
};

export default FullDatePicker;
