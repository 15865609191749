import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import { TrainerData } from "./SetSedeContainer";
import genericStyle from "../genericStyle.module.css"

const CreateInput = (type:string, name:string, onChange:Function) => {
    return (
        <div className={genericStyle.inputCont} key={type+name}>
            <span>{name}</span>
            <input type={type} name={name} onChange={(e) => onChange(e.target.value)} />
        </div>
    )
}

const SetSede: FunctionComponent<TrainerData> = (props: TrainerData) => {

        return (<div className={genericStyle.formCont}>
            <h2>Aggiungi Sede</h2>
            <div className={genericStyle.inputCont}>
            </div>
            <div className={genericStyle.fullWidth}>
            {props.corporateList.length > 0 && (
        <label>
          Ragione sociale:{" "}
          <select
            name={"gymPick"}
            onChange={(e) => props.onChangeFunction(e.target.value)}
          >
            <option value={""}>{""}</option>
            {props.corporateList.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
        </label>
      )}
                {props.inputList.map((data)=>CreateInput(data.type, data.name, data.callableFunction ))}
            </div>
            <button onClick={()=>props.uploadFunction()}>Aggiungi</button>
            </div>)

};
export default SetSede;
