import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import genericStyle from "../genericStyle.module.css";

import { TrainerTargetProps } from "./TrainerTargetContainer";

const TrainerTargetPage: FunctionComponent<TrainerTargetProps> = (
  props: TrainerTargetProps
) => {
  console.log(props);

  // const { name, email, surname } = props.trainerData;

  const [assignToTrainer, setAssignToTrainer] = useState(false);

  const handleSubmit = async () => {
    // code for handling form submission
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {assignToTrainer && (
        <div className={genericStyle.inputCont} key={"trainer-email"}>
          <span>{"Email Trainer"}</span>
          <input
            placeholder={"Inserisci l'email del trainer"}
            onChange={(e) => props.setTrainerData({ email: e.target.value })}
          />
        </div>
      )}
      <div className={genericStyle.inputCont} key={"target"}>
        <span>{"Target Nuovi Clienti"}</span>
        <input
          onChange={(e) =>
            props.setTrainerData({ target_new_clients: e.target.value })
          }
        />
      </div>

      <div className={genericStyle.inputCont} key={"target upgrade"}>
        <span>{"Target Rinnovi"}</span>
        <input
          onChange={(e) =>
            props.setTrainerData({ target_renewals: e.target.value })
          }
        />
      </div>
      <div className={genericStyle.inputCont} key={"target-upgrades"}>
        <span>{"Target Upgrades"}</span>
        <input
          onChange={(e) =>
            props.setTrainerData({ target_upgrades: e.target.value })
          }
        />
      </div>

      <div className={genericStyle.inputCont} key={"target-guadagni"}>
        <span>{"Target incassi"}</span>
        <input
          onChange={(e) =>
            props.setTrainerData({ target_income: e.target.value })
          }
        />
      </div>

      {/* rest of the code */}
      <button disabled={props.disableButton} className={props.disableButton?genericStyle.disabledButton:''}  onClick={() => props.handleSubmit()}>Aggiungi</button>
    </div>
  );
};

export default TrainerTargetPage;
