//@ts-nocheck
import React, { useEffect, useState } from "react";
import ChoicePage from "./ChoicePage";
import { supabase } from "../../config/config";
import ChoicePageFranchise from "./ChoicePageFranchise";
import {
  getCoporates,
  getGymsFranchise,
  getOwnCorporate,
  getRole,
  getUserName,
  getUuid,
} from "../../Utils/utils";
import ChoicePageRagione from "./ChoicePageRagione";
import { useNavigate } from "react-router-dom";
import genericStyle from "../genericStyle.module.css";
import { ThemeContext } from "../../context/ThemeContext";
import { useStore, State } from "../../steteManagement/zustandState";

export interface ChoiceData {
  gymData: GymInterface[] | null;
  franchiseData: FranchiseInterface[] | null;
  updateGyms: Function;
  updateFranchises: Function;
  setGyms: Function;
}

export interface GymInterface {
  name: string;
  id: string;
}

export interface FranchiseInterface {
  name: string;
  id: string;
}

async function getGyms(int: number, role: string): Promise<GymInterface[]> {
  let uuid = await getUuid();
  let gyms = await supabase
    .from("gym_trainer")
    .select("gym_id")
    .eq("trainer_id", uuid);

  //@ts-ignore
  let gyms1 = gyms.data.map((gym: any) => gym.gym_id);
  let result = await supabase
    .from("gym")
    .select("*")
    .eq("corporate_id", int)
    .order("name", { ascending: true })
    .eq("visible", true);

  let real_result = [];
  //@ts-ignore
  if (role == "user") {
    //@ts-ignore
    for (let i = 0; i < result.data.length; i++) {
      //@ts-ignore
      if (gyms1.includes(result.data[i].id)) {
        //@ts-ignore
        real_result.push(result.data[i]);
      }
    }
    return real_result;
  } else {
    //@ts-ignore
    return result.data;
  }
  // supabase.auth.signOut()
  //@ts-ignore
}

export default function ChoiceContainer() {
  const themeContext = React.useContext(ThemeContext);
  const [gymData, setgymData] = useState<GymInterface[] | null>(null);
  const [franchises, setFranchises] = useState<FranchiseInterface[] | null>(
    null
  );
  const [isFranchise, setIsFranchise] = useState(false);
  const navigation = useNavigate();
  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
  } = useStore();

  //@bepe metti a posto anche qui, questa è la pagina che farà tutto, in base al ruolo passa altre cose,
  // le funzioni che ottengono ragione sociale e palestra sono da fare qui e da passare alle pagine

  // const franchises = [
  //     {name:"Franchise 1", id: "f1"},
  //     {name:"franchise 2", id:"f2"}
  // ]

  console.log(themeContext?.nameSurname);
  const handleSubmit = (data: ChoiceData) => {
    console.log(data);
  };
  const getGymData = async (franchises: any, role: string) => {
    //@ts-ignore
    try {
      //@ts-ignore
      setgymData(await getGyms(franchises[0].corporate_name.id, role));
    } catch (error) {
      //@ts-ignore

      setgymData(await getGyms(franchises.id));
      console.log("done");
    }
    //getGyms()
  };

  const getFranchises = async () => {
    let franchises = await getCoporates();
    //@ts-ignore
    setFranchises(franchises);
  };
  const startUp = async () => {
    let role = await getRole();
    let name = await getUserName();
    //@ts-ignore
    themeContext?.setNameSurname(
      name.data[0].name + " " + name.data[0].surname
    );
    updateRole(role);
    getGymsFranchise();
    if (role == "user") {
      let franchises = await getCoporates();

      //remove duplicate francises
      //@ts-ignore
      franchises = franchises.filter(
        (franchise, index, self) =>
          index ===
          self.findIndex((t: { name: any }) => t.name === franchise.name)
      );
      console.log(franchises);

      //@ts-ignore

      setFranchises(franchises);
      getGymData(franchises, role);
    } else {
      let franchises = await getOwnCorporate();
      //@ts-ignore

      setFranchises(franchises);
      getGymData(franchises, role);
    }
  };
  useEffect(() => {
    startUp();
  }, []);

  useEffect(() => {
    console.log(franchises);
  }, [franchises]);

  const logout = () => {
    supabase.auth.signOut().then((res) => {
      navigation("/");
    });
  };

  if (role == "user") {
    return (
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <ChoicePage
          franchiseData={franchises}
          gymData={gymData}
          updateGyms={() => {}}
          updateFranchises={() => {}}
        />
        <button onClick={logout} className={genericStyle.logout}>
          Logout
        </button>
      </div>
    );
  } else if (role == "gym_holder") {
    // return (
    //   <div style={{ justifyContent: "center" }}>
    //     Attenda che il franchisee accetti la sua sede
    //   </div>
    // );
    try {
      if (franchises.accepted == false) {
        return (
          <><div style={{ justifyContent: "center" }}>
            Attenda che il franchisee accetti la sua sede
          </div><button onClick={logout} className={genericStyle.logout}>
              Logout
            </button></>
        );
      }
    } catch (error) {}

    return (
      <div>
        <ChoicePageRagione
          franchiseData={franchises}
          gymData={gymData}
          updateGyms={getGymData}
          updateFranchises={getFranchises}
        />
        <button onClick={logout} className={genericStyle.logout}>
          Logout
        </button>
      </div>
    );
  } else if (role == "franchisee") {
    return (
      <div>
        <ChoicePageRagione
          franchiseData={franchises}
          gymData={gymData}
          updateGyms={getGymData}
          updateFranchises={getFranchises}
          setGyms={setgymData}
        />
        <button onClick={logout} className={genericStyle.logout}>
          Logout
        </button>
      </div>
    );
  } else {
    return <span>Non si possiede alcun ruolo</span>;
  }

  return <span>Non si possiede alcun ruolo</span>;
}
