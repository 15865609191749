import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import { TrainerData } from "./SetTrainerContainer";
import genericStyle from "../genericStyle.module.css"

const CreateInput = (type:string, name:string, onChange:Function) => {
    return (
        <div className={genericStyle.inputCont} key={type+name}>
            <span>{name}</span>
            <input type={type} name={name} onChange={(e) => onChange(e.target.value)} />
        </div>
    )
}

const SetTrainer: FunctionComponent<TrainerData> = (props: TrainerData) => {

        return (<div className={genericStyle.formCont}>
            <div>
            <h2>Assegna Trainer</h2>
            {props.franchiseData.name!=''?<span>Società: {props.franchiseData.name}</span>:null}
            <span>Sede: {props.gymData.name}</span>
            </div>
            <div className={genericStyle.fullWidth}>
                {props.inputList.map((data)=>CreateInput(data.type, data.name, data.callableFunction ))}
            </div>
            <button onClick={()=>props.uploadFunction()}>Aggiungi</button>
            </div>)

};
export default SetTrainer;
