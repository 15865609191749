import React, { useState } from "react";
import { FranchiseInterface, GymInterface } from "../ChioicePage/ChoicePageContainer";
import SetTrainer from "./SetTrainer";
import { Database } from "../../../../database.types";
import { supabase } from "../../config/config";
import { getIdFromMail, setTrainerGym } from "../../Utils/utils";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import genericStyle from "../genericStyle.module.css"


export interface TrainerData {
    inputList: FunctionData[],
    uploadFunction: Function;
    gymData: GymInterface;
    franchiseData: FranchiseInterface;
}

export interface FunctionData {
    callableFunction: Function;
    name: string;
    type: string;
}
type supabaseInsterTrainerPerformance = Database['public']['Tables']['trainer_daily_performance']['Insert']
async function insertTrainerDailyPerformance(data: supabaseInsterTrainerPerformance) {
    let result = await supabase.from('trainer_daily_performance').insert(data)
    console.log(result)
}

export default function SetTrainerContainer(props: { gymData: GymInterface, franchiseData: FranchiseInterface }) {
    const [mail, setMail] = useState('');
    const [name, setname] = useState('');
    const [surname, setsurname] = useState('');
    const [message, setmessage] = useState('');

    const inputList: FunctionData[] = [
        {
            callableFunction: setMail,
            name: 'Mail',
            type: 'text'
        },
        {
            callableFunction: setname,
            name: 'Nome',
            type: 'text'
        },
        {
            callableFunction: setsurname,
            name: 'Cognome',
            type: 'text'
        },

    ]


    //@calle fatto  gestisci i dati da aaggiungere
    const getCorporateFromGym = async (gym_id: string) => {
        let { data, error } = await supabase.from('gym').select('corporate_id').eq('id', gym_id)
        if (error) {
            console.log(error)
        }
        //@ts-ignore
        return data[0].corporate_id
    }

    const handleSubmit = async () => {
        hideConfirmModal()
        console.log(props)
        //@ts-ignore
        let franchise = props.gymData.corporate_id
        let newEmail = mail.toLowerCase()
        let trainer_id = await getIdFromMail(newEmail)
        let corporate = await getCorporateFromGym(props.gymData.id)

        //@ts-ignore
        // setTargetTrainerCorporate(trainer_id, props.franchiseData.id)
        setTrainerGym({ trainer_id: trainer_id, gym_id: props.gymData.id, corporate_id: corporate })
    };


    const [showMessageModal, hideMessageModal] = useModal(() => (
        <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
            <div className={genericStyle.flexModal}>
                <span>{message}</span>
                <button onClick={hideMessageModal}>Ho capito</button>
            </div>
        </GenericModal>
    ), [message]);

    const [showConfirmModal, hideConfirmModal] = useModal(() => (
        <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
            <div className={genericStyle.flexModal}>
                <h2>Riepilogo</h2>
                <div className={genericStyle.flexModal}>
                    <span>Mail: {mail}</span>
                    <span>Nome: {name}</span>
                    <span>Cognome: {surname}</span>
                </div>
                <div className={genericStyle.flexButton}>
                    <button onClick={hideConfirmModal}>Indietro</button>
                    <button onClick={handleSubmit}>Conferma</button>
                </div>
            </div>
        </GenericModal>
    ), [mail, name, surname]);

    return (
        <SetTrainer
            inputList={inputList}
            uploadFunction={showConfirmModal}
            gymData={props.gymData}
            franchiseData={props.franchiseData}
        />
    );
}
