import {envConfig} from "./envConfig";

class ConfigContainer{
    env = envConfig
   getEnv(key:string):string | undefined{
      return this.env.getEnv(key)
   }


}
export const envContainer = new ConfigContainer()
