import React, { FormEvent, FormEventHandler, useContext } from "react";
import { FunctionComponent } from "react";
import { LoginProps } from "./LoginContainer";
import { ThemeContext } from "../../context/ThemeContext";
import { StyleSheet } from "react-native";
import extraStyles from "./extraStyles.module.css";
import genericStyle from "../genericStyle.module.css";
import { Form, Link } from "react-router-dom";
import { supabase } from "../../config/config";
import { useNavigate } from "react-router";
import Logo from "../../Assets/training_lab.png";

const Login: FunctionComponent<LoginProps> = (props: LoginProps) => {
  const theme = useContext(ThemeContext);
  const navigation = useNavigate();
  if (props.move_to_dashboard) {
    navigation("/dashboard");
  }
  const style = StyleSheet.create({
    background: {
      height: "100%",
    },
    input: {
      borderWidth: 0,
      width: "100%",
      borderBottomWidth: 1,
    },
    smallText: {
      fontSize: 10,
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    createAccount: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onLoginClick();
  };

  return (
    <div
      style={{
        height: style.background.height,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={extraStyles.back}
    >
      <div className={extraStyles.container}>
        <div className={genericStyle.imgWrap}>
          <img src={Logo} className={genericStyle.logo} alt="" />
        </div>

        {props.loginError ? (
          <h4 className={genericStyle.errorText}>Login o password errati</h4>
        ) : null}

        <form onSubmit={handleSubmit} style={style.inputContainer}>
          <input type="submit" hidden />
          <h3 style={{}}>E-mail</h3>

          <div style={{}}>
            <input
              onChange={(e) => {
                props.onChangeUsername(e.target.value);
              }}
              type={"text"}
              placeholder={"Scrivi il tuo indirizzo e-mail"}
              style={style.input}
              className={[genericStyle.no_focus, extraStyles.text_size].join(
                " "
              )}
            />
          </div>

          <h3 style={{}}>Password</h3>

          <div style={{}}>
            <input
              onChange={(e) => {
                props.onChangePassword(e.target.value);
              }}
              type={"password"}
              placeholder={"Scrivi la tua password"}
              style={style.input}
              className={[genericStyle.no_focus, extraStyles.text_size].join(
                " "
              )}
            />
          </div>
        </form>

        <div onClick={props.onLoginClick} className={extraStyles.button_cont}>
          <button
            onClick={props.onLoginClick}
            className={extraStyles.wideButton}
          >
            LOGIN
          </button>
        </div>

        <div style={{ display: "none" }}>
          <h3 style={{}}>oppure accedi con</h3>
          <div style={{}}>
            <div>
              <Link to={""}>Icona</Link>
              <Link to={""}>Icona</Link>
              <Link to={""}>Icona</Link>
            </div>
          </div>
        </div>

        <div style={style.createAccount}>
          <h3 style={{}}>Non hai un account?</h3>
          <div className={extraStyles.inputCont}>
            <Link style={{}} to={"/registerfranchise"}>
              {"Registra società>"}
            </Link>
            <Link style={{}} to={"/registeruser"}>
              {"Registra Trainer>"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
