import React from "react";
import logo from "./logo.svg";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
//import { auth } from "./common/config/config";
import { ThemeProvider } from "./common/context/ThemeContext";
import LanguageProvider from "./common/context/LanguageContext";
import LoginContainer from "./common/Page/Login/LoginContainer";
import { LinkExample2, LinkExample } from "./web/LinkExample";
import { supabase } from "./common/config/config";
import SupabaseHandlerContainer from "./common/Page/SupabaseHandler/SupabaseHandlerContainer";
import ResetPasswordContainer from "./common/Page/ResetPassword/ResetPasswordContainer";
import SignUpContainer from "./common/Page/SignUp/SignUpContainer";

import { ModalProvider } from "react-modal-hook";
import BenchmarkContainer from "./common/Page/Benchmark/BenchmarkContainer";
import InputTest2 from "./common/Page/Input/InputTest2";
import ChoiceContainer from "./common/Page/ChioicePage/ChoicePageContainer";
import TableFranchiseContainer from "./common/Page/TableFranchise/TableFranchiseContainer";
import TableTest from "./common/Page/Tables/TablesTest";
import HeaderContainer from "./common/Page/Header/HeaderContainer";
import TestSupa from "./common/Page/Test/TestSupa";
import TrainerTableFullContainer from "./common/Page/TrainerTableFull/TrainerTableFullContainer";

function App() {
  return (
    <ThemeProvider>
      <LanguageProvider>
        <BrowserRouter>
          <ModalProvider>
            {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
            <Routes>
              

                  <Route path="/" element={<LoginContainer/>} />
                  <Route path="/test" element={<TrainerTableFullContainer/>} />
                  <Route path="/login" element={<LoginContainer/>} />
                  <Route path="/registeruser" element={<SignUpContainer type='user'/>} />
                  <Route path="/registerfranchise" element={<SignUpContainer type='franchise'/>} />
                <Route path="/" element={<HeaderContainer/>} >
                  <Route path="/dashboard" element={<ChoiceContainer/>} />
                </Route>
                
            </Routes>
          </ModalProvider>
        </BrowserRouter>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
