import { create } from "zustand";
import { useState } from "react";

export type State = {
  firstName: string;
  lastName: string;
  role: string;
  franchise_ids: number[];
};

export type Actions = {
  updateFirstName: (firstName: string) => void;
  updateLastName: (lastName: string) => void;
  updateRole: (role: string) => void;
  updateFranchiseIds: (franchise_ids: number[]) => void;
};

// Create your store, which includes both state and (optionally) actions
export const useStore = create<State & Actions>((set) => ({
  firstName: "John",
  lastName: "Doe",
  role: "user",
  franchise_ids: [],
  updateFirstName: (firstName: string) => set(() => ({ firstName })),
  updateLastName: (lastName: string) => set(() => ({ lastName })),
  updateRole: (role: string) => set(() => ({ role })),
  updateFranchiseIds: (franchise_ids: number[]) =>
    set(() => ({ franchise_ids })),
}));
