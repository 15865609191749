export const CurrentDateFormatted = (date?: string) => {
  let today = new Date();
  if (date) {
    today = new Date(date);
  }
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let td;
  let tm;

  if (dd < 10) {
    td = "0" + dd.toString();
  } else {
    td = dd.toString();
  }
  if (mm < 10) {
    tm = "0" + mm.toString();
  } else {
    tm = mm.toString();
  }

  const formattedToday = td + "-" + tm + "-" + yyyy.toString();
  return formattedToday;
};

export const CurrentMonthFormatted = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let td;
  let tm;

  if (dd < 10) {
    td = "0" + dd.toString();
  } else {
    td = dd.toString();
  }
  if (mm < 10) {
    tm = "0" + mm.toString();
  } else {
    tm = mm.toString();
  }

  const formattedToday = tm + "-" + yyyy.toString();
  return formattedToday;
};

export const convertDateYMD = (date: Date) => {
  let today = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // add leading zero if needed
  const day = String(date.getDate()).padStart(2, "0"); // add leading zero if needed

  const dateString = `${year}-${month}-${day}`;
  return dateString;
};

export const FixDate = (date: string) => {
  return CurrentDateFormatted(date);
};
export const getStartDate = (date: Date): string => {
  //const date1 = new Date(date.getFullYear(), date.getMonth(), date.getDay());
  return convertDateYMD(date);
};

export const getEndDate = (date: Date): string => {
  //const date1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return convertDateYMD(date);
};
export const convertToItalyDate = (date: string) => {
  const date1 = new Date(date);
  return date1.toLocaleDateString("it-IT").toString();
};
