import React, { FC, PropsWithChildren, ReactComponentElement, ReactNode } from "react";
import Modal, { Props } from 'react-modal';



export const GenericModal:FC<PropsWithChildren<Props>> =  ({ children, isOpen, style, onAfterOpen, onAfterClose, onRequestClose, className, overlayClassName }) => {

    const [modalIsOpen, setIsOpen] = React.useState(isOpen);
  
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    }
  
    function closeModal() {
    }

    return(
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        overlayClassName={overlayClassName}
        className={className}
        style={{}}
        contentLabel="Example Modal"
      >
        {children}
      </Modal>
    )
}