//@ts-nocheck
import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useStore, State } from "../../steteManagement/zustandState";
import GymDailyPerformanceInputContainer from "../GymDailyPerformanceInput/GymDailyPerformanceInputContainer";
import TrainerComponentContainer from "../TrainerInput/TrainerComponentContainer";
import TrainerTableContainer from "../TrainerTable/TrainerTableContainer";
import {
  ChoiceData,
  FranchiseInterface,
  GymInterface,
} from "./ChoicePageContainer";
import genericStyle from "../genericStyle.module.css";
import SetRoleContainer from "../SetRole/SetRoleContainer";
import SetTrainerContainer from "../SetTrainer/SetTrainerContainer";
import SetSedeContainer from "../SetSede/SetSedeContainer";
import UserListPageContainer from "../UserListPage/UserListPageContainer";
import TableFranchiseContainer from "../TableFranchise/TableFranchiseContainer";
import TableFranchiseTrainersContainer from "../TableFranchiseTrainers/TableFranchiseTrainersContainer";
import SetTargetContainer from "../SetTarget/SetTargetContainer";
import RemoveTrainerContainer from "../RemoveTrainer/RemoveTrainerContainer";
import TableReasonContainer from "../TableReason/TableReasonContainer";
import SetTargetTrainerContainer from "../SetTargetTrainer/SetTargetTrainerContainer";
import { FormControlLabel, Checkbox } from "@mui/material";
import {
  getVisible,
  parseGymFranchise,
  setVisibleCorporate,
} from "../../Utils/utils";
import TrainerSedeContainer from "../TrainerTarget/TrainerTargetContainer";
import AcceptCorporateContainer from "../AcceptCorporate/AcceptCorporateContainer";
import { supabase } from "../../config/config";
import TrainerTargetContainer from "../TrainerTarget/TrainerRealTargetContainer";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import EditTrainerComponentContainer from "../EditTrainerData/TrainerComponentContainer";
import EditGymDailyPerformanceInputContainer from "../EditIncassi/GymDailyPerformanceInputContainer";

const generateGymList = (
  data: GymInterface | FranchiseInterface,
  clickEvent: Function
) => {
  const handleClick = () => {
    clickEvent(data);
  };

  return (
    <label key={data.name + data.id} onClick={handleClick}>
      <button>{data.name}</button>
    </label>
  );
};
const generateOptions = (
  gymData: GymInterface[] | FranchiseInterface[],
  clickEvent: Function
) => {
  const handleClick = (event) => {
    clickEvent(gymData[event.target.selectedIndex - 1]);
  };

  return (
    <label className={genericStyle.inputCont}>
      Sede:{" "}
      <select onChange={handleClick}>
        <option key={"none"} value={""}>
          {""}
        </option>
        {gymData.map((data) => (
          <option key={data.id} value={data.id}>
            {data.name}
          </option>
        ))}
      </select>
    </label>
  );
};
const ChoicePageRagione: FunctionComponent<ChoiceData> = (
  props: ChoiceData
) => {
  const [franchiseData, setfranchiseData] = useState<FranchiseInterface | null>(
    null
  );
  const [gymData, setgymData] = useState<GymInterface | null>(null);
  const [showSelection, setshowSelection] = useState<boolean>(false);
  const [showFranchise, setshowFranchise] = useState<boolean>(false);
  const [newGym, setnewGym] = useState<string | null>(null);
  const [basicOption, setbasicOption] = useState<string | null>(null);
  const [secondOption, setsecondOption] = useState<string | null>(null);
  const [visibleOption, setvisibleOption] = useState<boolean>(false);
  const [visibleSedi, setvisibleSedi] = useState<boolean>(false);
  const [oldGym, setOldGym] = useState<boolean>(false);
  const [buttonHideSedi, setbuttonHideSedi] = useState<boolean>(false);
  const date = new Date();
  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
    franchise_ids,
    updateFranchiseIds,
  } = useStore();

  useEffect(() => {
    //@ts-ignore

    setfranchiseData(props.franchiseData);
    let b = props;
    console.log(b);
    if (props.franchiseData) {
      getVisbile(props.franchiseData.id);
    }
  }, [props.franchiseData]);
  useEffect(() => {
    startUp();
  }, []);
  async function startUp() {
    if (role == "franchisee") {
      setvisibleSedi(true);
    }
  }
  async function addGyms() {
    let result = await parseGymFranchise();
    let franchises_new = await supabase
      .from("corporate_name")
      .select("*")
      .eq("accepted", true);
    updateFranchiseIds(franchises_new.data);
    let newGyms: GymInterface[] = [];
    for (let i = 0; i < result.length; i++) {
      let singleResult = result[i];
      let newGym2 = {
        id: singleResult.id,
        name: singleResult.name + " (" + singleResult.corporate_name + ")",
      };
      newGyms.push(newGym2);
    }
    //props.gymData = [...props.gymData, ...newGyms];
    setOldGym(props.gymData);
    props.setGyms([...props.gymData, ...newGyms]);
    setvisibleSedi(false);
    setbuttonHideSedi(true);
  }

  async function getVisbile(id: number) {
    getVisible(id).then((res) => {
      setvisibleOption(res);
    });
  }
  const selectGym = (data: GymInterface) => {
    setgymData(data);
    setshowSelection(true);
  };
  const selectFranchise = (data: FranchiseInterface) => {
    props.updateGyms();
    setfranchiseData(data);
    setshowFranchise(true);
  };

  const handleGym = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setnewGym(event.currentTarget.value);
  };
  const handleButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setbasicOption(event.currentTarget.value);
  };
  const handleSecondButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setsecondOption(event.currentTarget.value);
  };

  const goBack = () => {
    setbasicOption(null);
  };

  const backToFranchise = () => {
    setshowFranchise(false);
  };

  const backToGyms = () => {
    setshowSelection(false);
  };

  const backToGymsFromAdd = () => {
    setnewGym(null);
  };

  const backToOptions = () => {
    setsecondOption(null);
  };
  const onChangePermission = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVisibleCorporate(props.franchiseData.id, event.target.checked);
    setvisibleOption(event.target.checked);
  };

  const confirmDelete = async () => {
    hideConfirmModal();
    let b = gymData;

    let result1 = await supabase
      .from("trainer_daily_performance")
      .select("*")
      .eq("gym_id", gymData.id);
    let result2 = await supabase
      .from("gym_daily_performance")
      .select("*")
      .eq("gym_id", gymData.id);
    let result3 = await supabase
      .from("targets_gym")
      .select("*")
      .eq("gym_id", gymData.id);
    let count1 = result1.data.length;
    let count2 = result2.data.length;
    let count3 = result3.data.length;
    if (count1 == 0 && count2 == 0 && count3 == 0) {
      let result = await supabase
        .from("gym")
        .update({ visible: false })
        .eq("id", gymData.id);
    } else {
      alert("Non puoi rimuovere una sede con dati associati");
    }
    console.log(b);
  };

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <GenericModal
        isOpen
        overlayClassName={genericStyle.modalOverlay}
        className={genericStyle.modalStyle}
      >
        <div className={genericStyle.flexModal}>
          <span>Rimuovere Sede: {gymData?.name}?</span>
          <div className={genericStyle.buttonWrapper}>
            <button onClick={hideConfirmModal}>Annulla</button>
            <button onClick={confirmDelete}>Rimuovi</button>
          </div>
        </div>
      </GenericModal>
    ),
    [gymData]
  );

  const [targetSede, setTargetSede] = useState<boolean>(false);
  const [targetTrainer, setTargetTrainer] = useState<boolean>(false);
  if (targetSede) {
    return (
      <div className={genericStyle.fullWidth}>
        <TrainerSedeContainer></TrainerSedeContainer>
        <button
          onClick={() => {
            setTargetSede(false);
          }}
          className={genericStyle.backButton}
        >
          Indietro
        </button>
      </div>
    );
  }
  if (targetTrainer) {
    return (
      <div className={genericStyle.fullWidth}>
        <TrainerTargetContainer></TrainerTargetContainer>
        <button
          onClick={() => {
            setTargetTrainer(false);
          }}
          className={genericStyle.backButton}
        >
          Indietro
        </button>
      </div>
    );
  }
  if (!showSelection) {
    if (newGym == null) {
      return (
        <div className={genericStyle.flexCont}>
          <h2>Seleziona Palestra</h2>
          <div className={genericStyle.tWidth}>
            <div className={visibleSedi?genericStyle.flexButtonMain:genericStyle.flexButtonMainSedi}>
              {visibleSedi
                ? props.gymData
                  ? props.gymData.map((gym) => generateGymList(gym, selectGym))
                  : null
                : props.gymData
                ? generateOptions(props.gymData, selectGym)
                : null}
            </div>
            <div className={genericStyle.flexButtonCol}>
              <button value={"add"} onClick={handleGym}>
                Aggiungi Sede
              </button>
              <button value={"list"} onClick={handleGym}>
                Visualizza Incassi
              </button>
              <button value={"insert_list"} onClick={handleGym}>
                Modifica Incassi
              </button>
              {/* <button value={"target_t"} onClick={handleGym}>
                Aggiungi Target Trainer
              </button> */}
              <button value={"r_trainer"} onClick={handleGym}>
                Riepilogo Trainer
              </button>
              <button value={"e_trainer"} onClick={handleGym}>
                Modifica dati Trainer
              </button>
              <button value={"r_sedi"} onClick={handleGym}>
                Riepilogo Sedi
              </button>
              <button
                value={"addTarget"}
                onClick={() => {
                  setTargetSede(true);
                }}
              >
                Aggiungi Target Ragione Sociale
              </button>
              <button
                value={"addTargetTrainer"}
                onClick={() => {
                  setTargetTrainer(true);
                }}
              >
                Aggiungi Target Trainer
              </button>
              {visibleSedi ? (
                <button value={"r_reason"} onClick={addGyms}>
                  Visualizza tutte le sedi
                </button>
              ) : null}
              {visibleSedi ? (
                <button value={"accept_corporate"} onClick={handleGym}>
                  Accetta nuove ragioni sociali
                </button>
              ) : null}
              {buttonHideSedi ? (
                <button
                  value={"r_reason"}
                  onClick={() => {
                    setbuttonHideSedi(false);
                    setvisibleSedi(true);
                    props.setGyms(oldGym);
                  }}
                >
                  Nascondi tutte le sedi
                </button>
              ) : null}
            </div>

            {/* <FormControlLabel
              control={
                visibleOption ? (
                  <Checkbox checked={true} onChange={onChangePermission} />
                ) : (
                  <Checkbox checked={false} onChange={onChangePermission} />
                )
              }
              label={"Permetto a 20 Training Lab di vedere i miei dati"}
            /> */}
          </div>
        </div>
      );
    } else if (newGym == "accept_corporate") {
      return (
        <div>
          <AcceptCorporateContainer></AcceptCorporateContainer>
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    } else if (props.gymData && franchiseData && newGym == "list") {
      return (
        <div className={genericStyle.flexCont}>
          <TableReasonContainer
            gymData={props.gymData}
            franchiseData={franchiseData}
          />
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    } else if (newGym == "add") {
      return (
        <div className={genericStyle.flexCont}>
          <SetSedeContainer
            franchise={props.franchiseData}
            updateGyms={props.updateGyms}
          />
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    } else if (props.franchiseData && newGym == "target_t") {
      return (
        <div className={genericStyle.flexCont}>
          <SetTargetTrainerContainer franchiseData={props.franchiseData} />
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    } else if (props.franchiseData && newGym == "r_trainer") {
      return (
        <div className={genericStyle.flexCont}>
          <TableFranchiseTrainersContainer
            franchiseData={props.franchiseData}
          />
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    } else if (props.franchiseData && newGym == "r_sedi") {
      return (
        <div className={genericStyle.flexCont}>
          <TableFranchiseContainer franchiseData={props.franchiseData} />
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    } else if (props.franchiseData && newGym == "e_trainer") {
      return (
        <div className={genericStyle.flexCont}>
          <EditTrainerComponentContainer franchiseData={props.franchiseData} />
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    } else if (props.franchiseData && newGym == "insert_list") {
      return (
        <div className={genericStyle.flexCont}>
          <EditGymDailyPerformanceInputContainer
            franchiseData={props.franchiseData}
          />
          <button
            onClick={backToGymsFromAdd}
            className={genericStyle.backButton}
          >
            Indietro
          </button>
        </div>
      );
    }
  } else {
    if (secondOption == null) {
      return (
        <div className={genericStyle.flexCont}>
          <div>
            <h2>Sede: {gymData?.name}</h2>
          </div>
          <div className={genericStyle.flexButtonCol}>
            <button value={"t_list"} onClick={handleSecondButton}>
              Visualizza lista trainer
            </button>
            <button value={"t_add"} onClick={handleSecondButton}>
              Aggiungi trainer
            </button>
            <button value={"t_rem"} onClick={handleSecondButton}>
              Rimuovi trainer
            </button>
            {/* <button value={"addTarget"} onClick={handleSecondButton}>
              Aggiungi target Sede
            </button> */}
            <button value={"addTarget"} onClick={handleSecondButton}>
              Aggiungi Target
            </button>
            <button value={"remove"} onClick={showConfirmModal}>
              Rimuovi Sede
            </button>
          </div>
          <button onClick={backToGyms} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && secondOption == "t_list") {
      return (
        <div className={genericStyle.flexCont}>
          <UserListPageContainer gymData={gymData} />
          <button onClick={backToOptions} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && secondOption == "t_add") {
      return (
        <div className={genericStyle.flexCont}>
          <SetTrainerContainer
            gymData={gymData}
            franchiseData={{ name: "", id: "" }}
          />
          <button onClick={backToOptions} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && secondOption == "t_rem") {
      return (
        <div className={genericStyle.flexCont}>
          <RemoveTrainerContainer
            gymData={gymData}
            franchiseData={{ name: "", id: "" }}
          />
          <button onClick={backToOptions} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (gymData && secondOption == "trg_add") {
      return (
        <div className={genericStyle.flexCont}>
          <SetTargetContainer
            gymData={gymData}
            franchiseData={{ name: "", id: "" }}
          />
          <button onClick={backToOptions} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    } else if (secondOption == "addTarget") {
      return (
        <div className={genericStyle.flexCont}>
          <TrainerSedeContainer
            gymData={gymData}
            franchiseData={{ name: "", id: "" }}
          />
          <button onClick={backToOptions} className={genericStyle.backButton}>
            Indietro
          </button>
        </div>
      );
    }
  }

  return <div></div>;
};
export default ChoicePageRagione;
