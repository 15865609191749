import { Column, DefaultCellTypes, Id, Row } from "@silevis/reactgrid";
import React, { useContext, useEffect, useState } from "react";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import TableFranchise from "./TableReason";
import {
  getMonthlyTargetByCorporateName,
  getMonthlyTargetByCorporateNameAllTrainers,
  get_corporate_daily_performance,
  get_gym_daily_performance,
  parseGymFranchise,
} from "../../Utils/utils";
import { Database } from "../../../../database.types";
import { CellData, Columns } from "../../Utils/tableInterface";
import MonthPicker from "../DatePicker/MonthDatePicker";
import genericStyle from "../genericStyle.module.css";
import { convertDateYMD, convertToItalyDate } from "../../Utils/DateUtil";
import FullDatePicker from "../DatePicker/FullDatePicker";
import { useStore } from "../../steteManagement/zustandState";
import { supabase } from "../../config/config";


interface Earnings {
  gym_id: number;
  date: string;
  cash_payments: string;
  pos_payments: string;
  satispay_payments: string;
  subscription_payments: string;
  wire_transfer_payments: string;
  p_cash_payments: string;
  p_pos_payments: string;
  p_satispay_payments: string;
  p_subscription_payments: string;
  p_wire_transfer_payments: string;
  p_total_payments: string;
}

export interface TableData {
  columns: Columns[];
  rows: Earnings[][];
  franchiseData: FranchiseInterface;
  gymData: GymInterface[];
  mapFunction: Function;
}

const color = "#c6c6c6";

const getRows = (rows: Earnings[]) => [
  ...rows.map<CellData>((row, idx) => ({
    cells: [
      { type: "text", text: row.date },
      {
        type: "text",
        text: row.subscription_payments,
      },
      {
        type: "text",
        text: row.pos_payments,
      },
      {
        type: "text",
        text: row.wire_transfer_payments,
      },
      {
        type: "text",
        text: row.cash_payments,
      },
      {
        type: "text",
        text: row.satispay_payments,
      },
      {
        type: "text",
        text: row.p_subscription_payments,
      },
      {
        type: "text",
        text: row.p_pos_payments,
      },
      {
        type: "text",
        text: row.p_wire_transfer_payments,
      },
      {
        type: "text",
        text: row.p_cash_payments,
      },
      {
        type: "text",
        text: row.p_satispay_payments,
      },
      {
        type: "text",
        text: row.p_total_payments,
      },
    ],
  })),
];

const columnList: Columns[] = [
  {
    name: "Data",
    width: 150,
    sticky: true,
  },
  {
    name: "Abb.",
    width: 100,
  },
  {
    name: "POS",
    width: 100,
  },
  {
    name: "Bonif.",
    width: 100,
  },
  {
    name: "Cont.",
    width: 100,
  },
  {
    name: "Sat.",
    width: 100,
  },
  {
    name: "Prg. Abb.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. POS",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. Bonif.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. Cont.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. Sat.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Totale",
    width: 100,
    backgroundColor: color,
  },
];

const getPeople = (): Earnings[] => [
  {
    gym_id: 1,
    date: "ads",
    cash_payments: "ads",
    pos_payments: "ads",
    satispay_payments: "ads",
    subscription_payments: "ads",
    wire_transfer_payments: "ads",
    p_cash_payments: "ads",
    p_pos_payments: "ads",
    p_satispay_payments: "ads",
    p_subscription_payments: "ads",
    p_wire_transfer_payments: "ads",
    p_total_payments: "ads",
  },
];
type ragioneSociale = {
  name: string;
  id: string;
};
type responseType =
  Database["public"]["Functions"]["get_gym_monthly_target_by_corporate_name_id"]["Returns"];

export default function TableReasonContainer(props: {
  franchiseData: FranchiseInterface;
  gymData: GymInterface[];
}) {
  const mapToInterface = (raw_data: any): Earnings[] => {
    return raw_data.map((el: any) => {
      return {
        date: convertToItalyDate(el.date),
        cash_payments: (
          Math.round(el.cash_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        pos_payments: (Math.round(el.pos_payments * 100) / 100).toLocaleString(
          "it-IT",
          {
            style: "currency",
            currency: "EUR",
          }
        ),
        satispay_payments: (
          Math.round(el.satispay_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        subscription_payments: (
          Math.round(el.subscription_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        wire_transfer_payments: (
          Math.round(el.wire_transfer_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        p_cash_payments: (
          Math.round(el.progressive_cash_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        p_pos_payments: (
          Math.round(el.progressive_pos_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        p_satispay_payments: (
          Math.round(el.progressive_satispay_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        p_subscription_payments: (
          Math.round(el.progressive_subscription_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        p_wire_transfer_payments: (
          Math.round(el.progressive_wire_transfer_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        p_total_payments: (
          Math.round(el.progressive_total_payments * 100) / 100
        ).toLocaleString("it-IT", {
          style: "currency",
          currency: "EUR",
        }),
        gym_id: Math.round(el.gym_id),
      };
    });
  };
  const [ragioneSociali, setRagioneSociali] = useState<ragioneSociale[]>([
    { name: "Noname", id: "0" },
  ]);
  const [people, setPeople] = React.useState<Earnings[]>(getPeople());
  const [rows, setRows] = React.useState<CellData[][]>([getRows(people)]);
  const [columns, setColumns] = React.useState<Columns[]>(columnList);
  const [gymData, setGymData] = React.useState<GymInterface[]>(props.gymData);
  const [franchise, setFranchise] = React.useState<string>("0");
  const [franchiseData, setFranchiseData] = React.useState<FranchiseInterface>(
    props.franchiseData
  );
  const [fullEarnings, setFullEarnings] = React.useState<any>({});
  const currentDate = new Date();

  const [dateStart, setdateStart] = React.useState<Date>(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [dateEnd, setdateEnd] = React.useState<Date>(new Date());
  const [gymName, setgym] = useState<string>("");
  const [trueRows, settrueRows] = useState<Earnings[][]>([
    mapToInterface(rows),
  ]);
  async function setAllFranchises() {
    let franchises = await supabase
      .from("corporate_name")
      .select("id, name")
      .eq("accepted", true);
    //@ts-ignore
    setRagioneSociali(franchises.data);
  }
  const { role } = useStore();
  //@beppe da fixare la conversione a oggetto corretto, oppure da passarmi quello corretto
  //function convertToFranchise(data:responseType):Franchise[]{
  //    let franchise:Franchise[] = []
  //    data.forEach((el)=>{
  //        franchise.push({
  //            name:el.gym_name,
  //            clients:el.new_clients.toString(),
  //            target:el.target.toString(),
  //            trials:el.performed_tests.toString(),
  //            closure:el.successful_percentage.toString(),
  //            delta:el.delta_target.toString()
  //        })
  //    })
  //    return franchise
  //}
  const myFunc = async () => {
    //@ts-ignore
    console.log(props.franchiseData.id);
    // let response = await getMonthlyTargetByCorporateName(props.franchiseData.id) as responseType
    // console.log(response)
    let now = new Date();
    let start = convertDateYMD(dateStart);
    let end = convertDateYMD(dateEnd);
    //@ts-ignore
    //@beppe controllare quando ci sono piu palestre e piu dati della stessa palestra
    const newRows = await get_corporate_daily_performance(
      //@ts-ignore
      franchise != "0" ? franchise : props.franchiseData.id,
      start,
      end
    );
    const realRows: Earnings[][] = [];
    const total: Earnings[] = [];
    newRows.forEach((row) => {
      if (row?.length != 0) {
        let data = mapToInterface(row);

        realRows.push(data);
      }
    });
    let fullEarning: Earnings = {
      date: "Totale",
      cash_payments: "0",
      pos_payments: "0",
      satispay_payments: "0",
      subscription_payments: "0",
      wire_transfer_payments: "0",
      p_cash_payments: "0",
      p_pos_payments: "0",
      p_satispay_payments: "0",
      p_subscription_payments: "0",
      p_wire_transfer_payments: "0",
      p_total_payments: "0",
      gym_id: 0,
    };
    newRows.forEach((row) => {
      let b = row;
      //@ts-ignore
      let lastRow = row[row.length - 1];
      const EURO_SYMBOL = "€"; // Unicode for the euro symbol

      fullEarning.cash_payments =
        (
          parseFloat(fullEarning.cash_payments) +
          parseFloat(lastRow.progressive_cash_payments)
        )
          .toFixed(2)
          .toString() +
        " " +
        EURO_SYMBOL;

      fullEarning.pos_payments =
        (
          parseFloat(fullEarning.pos_payments) +
          parseFloat(lastRow.progressive_pos_payments)
        )
          .toFixed(2)
          .toString() +
        " " +
        EURO_SYMBOL;

      fullEarning.satispay_payments =
        (
          parseFloat(fullEarning.satispay_payments) +
          parseFloat(lastRow.progressive_satispay_payments)
        )
          .toFixed(2)
          .toString() +
        " " +
        EURO_SYMBOL;

      fullEarning.subscription_payments =
        (
          parseFloat(fullEarning.subscription_payments) +
          parseFloat(lastRow.progressive_subscription_payments)
        )
          .toFixed(2)
          .toString() +
        " " +
        EURO_SYMBOL;

      fullEarning.wire_transfer_payments =
        (
          parseFloat(fullEarning.wire_transfer_payments) +
          parseFloat(lastRow.progressive_wire_transfer_payments)
        )
          .toFixed(2)
          .toString() +
        " " +
        EURO_SYMBOL;

      fullEarning.p_total_payments =
        (
          parseFloat(fullEarning.p_total_payments) +
          parseFloat(lastRow.progressive_total_payments)
        )
          .toFixed(2)
          .toString() +
        " " +
        EURO_SYMBOL;

      fullEarning.gym_id = -1;
    });
    // realRows.push([fullEarning]);
    //@ts-ignore
    let result = [];
    if (role == "franchisee") {
      let result = await setNames();
      setGymData([{ id: "-1", name: "Totali" }, ...result, ...props.gymData]);
    } else {
      setGymData([{ id: "-1", name: "Totali" }, ...props.gymData]);
    }
    setFullEarnings(fullEarning);
    let realRows1 = [[fullEarning], ...realRows];

    settrueRows(realRows1);
    //let newRows = convertToFranchise(response)
  };
  async function setNames() {
    let result = await parseGymFranchise();
    let newGyms: GymInterface[] = [];
    for (let i = 0; i < result.length; i++) {
      let singleResult = result[i];
      let newGym2 = {
        id: singleResult.id,
        name: singleResult.name + " (" + singleResult.corporate_name + ")",
      };
      newGyms.push(newGym2);
    }
    return newGyms;
  }
  useEffect(() => {
    //check if there is no Tutte
    let tutte = props.gymData.find((el) => el.name == "Visualizza Tutte");
    console.log(tutte);
    const test = props.franchiseData;
    // if (tutte == undefined) {
    //   props.gymData.push({ id: "", name: "Visualizza Tutte" });
    // }

    myFunc();
    setAllFranchises();
  }, []);
  const onFilter = async () => {
    //@ts-ignore
    let name = gymName;
    let start = convertDateYMD(dateStart);
    let end = convertDateYMD(dateEnd);
    if (role === "franchisee") {
    }
    if (name == "") {
      myFunc();
      return;
    }
    const data = await get_gym_daily_performance({
      start_date: start,
      end_date: end,
      p_gym_id: parseInt(name),
    });
    //string to int of name
    let realRows: Earnings[][] = [];
    //@ts-ignore
    realRows = [mapToInterface(data.data)];
    settrueRows(realRows);
    //let data1 = mapToInterface(data.data)
    //  //@ts-ignore
    //  setRows(getRows(data1))
  };

  useEffect(() => {
    console.log(trueRows);
  }, [trueRows]);

  return (
    <div>
      <div className={genericStyle.filterCont}>
        {role === "franchisee" ? (
          <label>
            Ragione Sociale:{" "}
            <select
              name={"gymPick"}
              onChange={(e) => setFranchise(e.target.value)}
            >
              <option value={"nessuna"}>{"nessuna"}</option>
              {ragioneSociali.map((data) => (
                <option value={data.id}>{data.name}</option>
              ))}
            </select>
          </label>
        ) : null}
        <label>
          Data Inizio:
          <FullDatePicker selectedDate={dateStart} dateSet={setdateStart} />
        </label>
        <label>
          Data fine:
          <FullDatePicker selectedDate={dateEnd} dateSet={setdateEnd} />
        </label>
        <label>
          Sede:{" "}
          <select name={"gymPick"} onChange={(e) => setgym(e.target.value)}>
            <option value={""}>{""}</option>
            {gymData.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
        </label>
        <button onClick={onFilter}>Filtra</button>
      </div>

      <TableFranchise
        rows={trueRows}
        columns={columns}
        mapFunction={getRows}
        franchiseData={franchiseData}
        gymData={gymData}
      />
    </div>
  );
}
