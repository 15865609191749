import React, { FunctionComponent, MouseEventHandler, useEffect, useState } from "react";
import { TrainerData } from "./TrainerComponentContainer";
import genericStyle from "../genericStyle.module.css"
import { ThemeContext } from "../../context/ThemeContext";
import FullDatePicker from "../DatePicker/FullDatePicker";
import { cleanInput } from "../../Utils/utils";

const CreateInput = (type:string, name:string, onChange:Function, placeholder:string='') => {
    return (
        <div className={genericStyle.inputCont} key={type+name}>
            <span>{name} ({placeholder})</span>
            <input type={'text'} min={0} name={name} onChange={(e) =>{
              if (type != 'number'){
                onChange(e.target.value)
              }
              else{
                e.target.value = cleanInput(e.target.value)
                  const numValue = parseFloat(e.target.value);
                  let roundedValue = Math.round(numValue * 100) / 100;
                  if(Number.isNaN(roundedValue)){
                    roundedValue=0
                  }
                  onChange(roundedValue);
              }
              }}/>
        </div>
    )
}

const TrainerPage: FunctionComponent<TrainerData> = (props: TrainerData) => {
    const themeContext = React.useContext(ThemeContext);

    const date = new Date()

    useEffect(()=>{
        console.log(props.trainerData)
    }, [props.trainerData])

        return (<div className={genericStyle.formCont}>
                <div className={genericStyle.flexTitle}>
            <h2>Modifica dati Trainer</h2>
            <div>
            <div className={genericStyle.subtitleCont}>
            <span>Utente: {themeContext?.nameSurname}</span>
            <span>Società: {props.franchiseData.name}</span>
            </div>
            </div>
            </div>
            <div className={genericStyle.fullWidth}>

            <label className={genericStyle.inputCont}>
          Sede:{" "}
          <select onChange={(e) => props.setgymId(e.target.value)}>
            <option key={'none'} value={""} data-trainername={""} data-trainersurname={""}>{""}</option>
            {props.gymList.map((data) => (
              <option key={data.id} value={data.id}>{data.name + ` (${data.corporate_name.name})`}</option>
            ))}
          </select>
        </label>
        <label className={genericStyle.inputCont}>
          Trainer:{" "}
          <select onChange={(e) => props.setData(e)} disabled={props.trainerData.length==0?true:false}>
            <option key={'none'} value={""} data-trainername={""} data-trainersurname={""}>{""}</option>
            {props.trainerData.map((data) => (
              <option key={data.trainer_id} data-trainername={data.profiles.name} data-trainersurname={data.profiles.surname}value={data.trainer_id}>{data.profiles.name + ' ' + data.profiles.surname}</option>
            ))}
          </select>
        </label>
        <label className={genericStyle.inputCont}>
            Data:
            <FullDatePicker selectedDate={props.date} dateSet={props.setDate} />
          </label>
                {props.inputList.map((data)=>CreateInput(data.type, data.name, data.callableFunction, data.placeholder ))}
            </div>
            <button disabled={props.disableButton} className={props.disableButton?genericStyle.disabledButton:''} onClick={()=>props.uploadFunction()}>Modifica Dati</button>
            </div>)

};
export default TrainerPage;
