import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import Login from "./LoginPage";
import React from "react";
import { supabase } from "../../config/config";
export interface LoginProps {
  username: string;
  password: string;
  usernamePlaceHolder: string;
  passwordPlaceHolder: string;
  usernameTitlePlaceHolder: string;
  passwordTitlePlaceHolder: string;
  onChangeUsername(username: string): void | undefined;
  onChangePassword(password: string): void | undefined;
  onLoginClick(): void;
  isLoginButtonVisible(): boolean | undefined;
  onClickRegister(): void;
  errorUsername: string;
  errorPassword: string;
  loginPlaceHolder: string;
  passwordSecureNumber: number;
  registerText: string;
  move_to_login: boolean;
  loginError: boolean;
  move_to_dashboard: boolean;
}

export default function LoginContainer() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [securePassword, setSecurePassword] = useState(0);
  const language = useContext(LanguageContext)!;
  let loginPlaceHolder = language.t("login");
  let usernamePlaceHolderText = language.t("username");
  let passwordPlaceHolderText = language.t("password");
  let usernameTitlePlaceHolderText = language.t("usernameTitle");
  let passwordTitlePlaceHolderText = language.t("passwordTitle");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [move_to_login, setMoveToLogin] = useState(false);
  const [loginError, setloginError] = useState(false);

  const [move_to_dashboard, setMoveToDashboard] = useState(false);

  useEffect(()=>{

    const checkLogged  = async ()=>{
      let logged = await supabase.auth.getUser()
      if (logged.data.user != null){
        setMoveToDashboard(true)
      }
    }
    checkLogged() 
  }, [])

  const onChangeUsername = (username: string) => {
    setUsername(username);
    let error = false;

    if (error) {
      setErrorUsername(error);
    } else {
      //setErrorUsername(error);
    }
  };
  function onClickRegister() {
    // navigation.navigate("SingUp");//this must be on the child component
  }
  function onChangePassword(password: string): void {
    setPassword(password);
    let error = false;
    if (error) {
      setErrorPassword(error);
    } else {
      //setErrorPassword(error);
    }
  }
  const onLoginClick = async () => {
    setloginError(false)
    //login only if email and password are valid and login with authCurrent
    onChangePassword(password);
    onChangeUsername(username);
    if (errorUsername === "" && errorPassword === "") {
      //let result = await auth.login(username, password);
      let result = await supabase.auth.signInWithPassword({
        email: username,
        password: password,
      });

      if (result["data"]["user"]) {
        setMoveToDashboard(true)
      } else {
        setloginError(true)
      }
    } else {
      alert("Please enter valid email and password");
    }
  };
  const isLoginButtonVisible = () => {
    return true;
  };

  const onChangeTheme = () => {};

  return (
    <Login
      username={username}
      password={password}
      usernamePlaceHolder={usernamePlaceHolderText}
      usernameTitlePlaceHolder={usernameTitlePlaceHolderText}
      passwordPlaceHolder={passwordPlaceHolderText}
      passwordTitlePlaceHolder={passwordTitlePlaceHolderText}
      onChangeUsername={onChangeUsername}
      onChangePassword={onChangePassword}
      onLoginClick={onLoginClick}
      isLoginButtonVisible={isLoginButtonVisible}
      onClickRegister={onClickRegister}
      errorUsername={""}
      errorPassword={""}
      loginPlaceHolder={loginPlaceHolder}
      passwordSecureNumber={0}
      registerText={""}
      move_to_login={false}
      loginError={loginError}
     move_to_dashboard={move_to_dashboard}></Login>
  );
}
