import React, { useContext, useEffect, useState } from "react";
interface Targets {
  trainer_id: string;
  id?: number;
  date: string;
  target: number;
  target_upgrade: number;
  target_done: number;
  gym_id?: number;
}
type tableRaw = {
  trainer_email: string;
  gym: string;
  target: number;
  target_upgrade: number;
  target_done: number;
  date: string;
  target_percentage: number;
  target_upgrade_percentage: number;
  target_done_percentage: number;
};
type TargetData = {
  id: number;
  date: string;
  target: number;
  target_upgrade: number;
  target_done: number;
  gym: {
    id: number;
    name: string;
    address: string;
    phone: string;
    email: string;
    website: string;
    description: string;
    image: string;
    corporate_id: number;
    owner: string;
    created_at: string;
    updated_at: string;
  };
  profiles: {
    id: string;
    name: string;
    surname: string;
    email: string;
    role: string;
    trainer_daily_performance: {
      id: number;
      trainer_id: string;
      gym_id: number;
      performed_tests: number;
      taken_tests: number;
      planned_renewals: number;
      made_renewals: number;
      training_sessions: number;
      new_clients: number;
      work_hours: number;
      break_hours: number;
      break_work: string;
      date: string;
      created_at: string;
    }[];
  }[];
};

type trainer_daily_performance = {
  break_hours: number;
  break_work: string | null;
  created_at: string | null;
  date: string | null;
  gym_id: number;
  id: number;
  made_renewals: number;
  new_clients: number;
  performed_tests: number;
  planned_renewals: number;
  taken_tests: number;
  trainer_id: string | null;
  training_sessions: number;
  work_hours: number;
};

function sumTableRaw(table: any[]) {
  let mapping = new Map<string, tableRaw>();

  for (let i = 0; i < table.length; i++) {
    let row = table[i];
    let current_row: tableRaw = {
      trainer_email: "",
      gym: "",
      target: 0,
      target_upgrade: 0,
      target_done: 0,
      date: "",
      target_percentage: 0,
      target_upgrade_percentage: 0,
      target_done_percentage: 0,
    };
    let mapping_key = row.trainer_email + row.gym + row.date;
    if (mapping_key in mapping) {
      let old_mapping = mapping.get(mapping_key);
      if (old_mapping != undefined) {
        current_row = old_mapping;
      }
    }
    current_row.trainer_email = row.trainer_email;
    current_row.gym = row.gym;
    current_row.target += row.target;
    current_row.target_upgrade += row.target_upgrade;
    current_row.target_done += row.target_done;
    current_row.date = row.date;
    current_row.target_percentage =
      current_row.target_done / current_row.target;
    current_row.target_upgrade_percentage =
      current_row.target_upgrade / current_row.target;
    current_row.target_done_percentage =
      current_row.target_done / current_row.target;
    mapping.set(mapping_key, current_row);
  }
  let result: tableRaw[] = [];
  mapping.forEach((value, key) => {
    result.push(value);
  });
  return result;
}
export interface TrainerTableFullProps {}
export default function TrainerTableFullContainer(
  props: TrainerTableFullProps
) {
  const data1 = [
    { id: 1, name: "John", surname: "Doe" },
    { id: 2, name: "Jane", surname: "Doe" },
    { id: 3, name: "Bob", surname: "Smith" },
  ];

  const headers1 = [
    "trainer_email",
    "palestra",
    "target",
    "target_upgrade",
    "target_done",
    "date",
    "target_percentage",
    "target_upgrade_percentage",
    "target_done_percentage",
  ];
  const [data, setData] = useState(data1);
  const [headers, setHeaders] = useState(headers1);
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [gyms, setGyms] = useState([119, 120]);
  let fetchData = async () => {
    let start = convertDateYMD(dateStart);
    let end = convertDateYMD(dateEnd);
    //@ts-ignore
    let data: TargetData = await supabase
      .from("targets")
      //@ts-ignore
      .select("*,gym(*),profiles(*,trainer_daily_performance(*))")
      //@ts-ignore
      .gte("date", start)
      .lte("date", end)
      .in("gym_id", gyms)
      .gte("profiles.trainer_daily_performance.date", start)
      .lte("profiles.trainer_daily_performance.date", end);
    //console.log(data.data);

    //@ts-ignore

    sumTableRaw(data.data);
  };
  useEffect(() => {
    fetchData();
  }, [dateStart, dateEnd]);
  return (
    <div>
      <label>
        Data Inizio:
        <MonthPicker selectedDate={dateStart} dateSet={setDateStart} />
      </label>
      <label>
        Data fine:
        <MonthPicker selectedDate={dateEnd} dateSet={setDateEnd} />
      </label>
      <MyTable data={data} headers={headers} />
    </div>
  );
}
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import MonthPicker from "../DatePicker/MonthDatePicker";
import { supabase } from "../../config/config";
import { convertDateYMD } from "../../Utils/DateUtil";

interface Props {
  data: Array<any>;
  headers: Array<string>;
}

function MyTable(props: Props) {
  const { data, headers } = props;

  // Define a function that renders the table rows based on the data array
  function renderRows() {
    return data.map((item) => (
      <TableRow key={item.id}>
        {Object.values(item).map((value: any, index) => (
          <TableCell key={index}>{value}</TableCell>
        ))}
      </TableRow>
    ));
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}
