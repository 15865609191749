import { User, UserResponse } from "@supabase/supabase-js";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Database } from "../../../../database.types";
import { supabase } from "../../config/config";
import Header from "./Header";
import style from "./headerStyles.module.css"


export interface HeaderContainerProps {
    data:any
}
//@ts-ignore
type question = Database["public"]["Tables"]["question"]["Insert"];
export default function HeaderContainer() {



  return (
      <div className={style.headerMainCont}>
        <Header></Header>
        <div className={style.remainingHeight}>
        <Outlet></Outlet>
        </div>
      </div>
  );
}



