import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import { TrainerData } from "./TrainerComponentContainer";
import genericStyle from "../genericStyle.module.css"
import { ThemeContext } from "../../context/ThemeContext";
import { cleanInput } from "../../Utils/utils";

const CreateInput = (type:string, name:string, onChange:Function) => {
    return (
        <div className={genericStyle.inputCont} key={type+name}>
            <span>{name}</span>
            <input type={'text'} min={0} name={name} onChange={(e) =>{
                              if (type != 'number'){
                                onChange(e.target.value)
                              }
                              else{
                                e.target.value = cleanInput(e.target.value)
                                  const numValue = parseFloat(e.target.value);
                                  let roundedValue = Math.round(numValue * 100) / 100;
                                  if(Number.isNaN(roundedValue)){
                                    roundedValue=0
                                  }
                                  onChange(roundedValue);
                              }
                              }}/>
        </div>
    )
}

const TrainerPage: FunctionComponent<TrainerData> = (props: TrainerData) => {
    const themeContext = React.useContext(ThemeContext);

    const date = new Date()

        return (<div className={genericStyle.formCont}>
                <div className={genericStyle.flexTitle}>
            <h2>Riepilogo Trainer di {date.toUTCString().substring(0, 16)}</h2>
            <div>
            <div>
            <span>Utente: {themeContext?.nameSurname}</span>
            <span>Società: {props.franchiseData.name}</span>
            <span>Sede: {props.gymData.name}</span>
            </div>
            </div>
            </div>
            <div className={genericStyle.fullWidth}>
                {props.inputList.map((data)=>CreateInput(data.type, data.name, data.callableFunction ))}
            </div>
            <button onClick={()=>props.uploadFunction()}>Invia Dati</button>
            </div>)

};
export default TrainerPage;
