import { useState } from "react";
import { Authentication } from "../auth/authentication";
import {envContainer} from "./envConfig/envConfigContainer";
import {createSupabaseClient, getSupabaseAuth} from "../auth/supabaseAuth";
//import { supabaseAuth } from "../auth/supabaseAuth";
// import { supabaseAuth } from "../auth/supabaseAuth";
export const env  = envContainer
const DEV_SUPABASE_URL = process.env.REACT_APP_DEV_SUPABASE_URL;
const DEV_SUPABASE_ANON_KEY =process.env.REACT_APP_DEV_SUPABASE_ANON_KEY;
const SUPABASE_URL = DEV_SUPABASE_URL
const SUPABASE_ANON_KEY = DEV_SUPABASE_ANON_KEY
//@ts-ignore
export const supabase = createSupabaseClient(SUPABASE_URL, SUPABASE_ANON_KEY);
export const auth = getSupabaseAuth(supabase);
//export const auth = supabaseAuth
// export const [auth, setAuth] = useState<Authentication>()
// setAuth(supabaseAuth)