//@ts-nocheck
import { Column, DefaultCellTypes, Id, Row } from "@silevis/reactgrid";
import React, { useContext, useEffect, useState } from "react";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import TableFranchiseTrainers from "./TableFranchiseTrainers";
import { Database } from "../../../../database.types";
import {
  getAllTrainers,
  getAllTrainersGyms,
  getMonthlyTargetByCorporateName,
  getMonthlyTargetByCorporateNameAllTrainers,
  returnsTableFranchiseFunction,
  tableFranchiseContainer,
} from "../../Utils/utils";
import { CellData, Columns } from "../../Utils/tableInterface";
import MonthPicker from "../DatePicker/MonthDatePicker";
import {
  convertDateYMD,
  convertToItalyDate,
  getEndDate,
  getStartDate,
} from "../../Utils/DateUtil";
import genericStyle from "../genericStyle.module.css";
import FullDatePicker from "../DatePicker/FullDatePicker";
import { useStore } from "../../steteManagement/zustandState";
import { supabase } from "../../config/config";
import { fullTableTrainer, tableTrainer } from "./utils";
interface Franchise {
  name: string;
  clients: string;
  target: string;
  trials: string;
  closure: string;
  trainer: string;
  date?: string;
  made_renewals?: string;
  planned_renewals?: string;
  targetRenewals?: number;
  targetUpgrade?: number;
  targetIncome?: number;
  income?: number;
  upgradeDone?: number;
}

const getRows = (people: Franchise[]) => [
  ...people.map<CellData>((person, idx) => ({
    cells: [
      { type: "text", text: person.name },
      { type: "text", text: person.trainer },
      { type: "text", text: person.clients },
      { type: "text", text: person.target },
      { type: "text", text: person.trials },
      { type: "text", text: person.closure },
      { type: "text", text: person.planned_renewals },
      { type: "text", text: person.made_renewals },
      { type: "text", text: person.targetRenewals },
      { type: "text", text: person.targetUpgrade },
      { type: "text", text: person.targetIncome },
      { type: "text", text: person.income },
      { type: "text", text: person.upgradeDone },
    ],
  })),
];

const columnList: Columns[] = [
  {
    name: "Sede",
    width: 150,
    align: "center",
    sticky: true,
  },
  {
    name: "Trainer",
    width: 200,
    align: "center",
  },
  {
    name: "Clienti Acquisiti",
    width: 100,
    align: "center",
  },
  {
    name: "Target",
    width: 100,
    align: "center",
  },
  {
    name: "Prove Effettuate",
    width: 100,
    align: "center",
  },
  {
    name: "% chiusura",
    width: 100,
    align: "center",
  },
  {
    name: "Rinnovi Previsisti",
    width: 100,
    align: "center",
  },
  {
    name: "Rinnovi Fatti",
    width: 100,
    align: "center",
  },
  {
    name: "Target Rinnovi",
    width: 100,
    align: "center",
  },
  {
    name: "Target Upgrade",
    width: 100,
    align: "center",
  },
  {
    name: "Target Incassi",
    width: 100,
    align: "center",
  },
  {
    name: "Incassi",
    width: 100,
    align: "center",
  },
  { name: "Upgrade Fatti", width: 100, align: "center" },
];

const getPeople = (): Franchise[] => [
  {
    name: "",
    clients: "",
    target: "",
    trials: "",
    closure: "",
    trainer: "",
  },
  { name: "", clients: "", target: "", trials: "", closure: "", trainer: "" },
];

type responseType =
  Database["public"]["Functions"]["get_gym_monthly_target_by_corporate_name_id_all_trainers"]["Returns"];
type Trainer = {
  name: string;
  id: string;
};
type Gym = {
  name: string;
  id: string;
};
export default function TableFranchiseTrainersContainer(props: {
  franchiseData: FranchiseInterface;
}) {
  const [people, setPeople] = React.useState<Franchise[]>(getPeople());
  const [rows, setRows] = React.useState<CellData[]>(getRows(people));
  const [columns, setColumns] = React.useState<Columns[]>(columnList);
  const currentDate = new Date();
  const [trainers, setTrainers] = React.useState<[]>([]);
  const [trainer_id, setTrainerId] = React.useState<string>("");
  const [dateStart, setDateStart] = React.useState<Date>(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [dateEnd, setDateEnd] = React.useState<Date>(new Date());
  const [gyms, setGyms] = React.useState<Gym[]>([]);
  const [gymid, setGymId] = React.useState<string>("");
  const [gymFranchise, setGymFranchise] = React.useState<Map<string, string[]>>(
    new Map()
  );
  const [firstTime, setFirstTime] = React.useState<boolean>(true);
  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
    franchise_ids,
    updateFranchiseIds,
  } = useStore();

  const myFunc = async () => {
    let b = props;
    let start = getStartDate(dateStart);
    let end = getEndDate(dateEnd);
    let gymsId = gyms.map((el) => el.id);
    let franchiseKeys = Array.from(gymFranchise.keys());
    let list_trainer_id = [];
    let gymsFranchise: Map<string, string[]> = gymFranchise;
    if (trainer_id) {
      list_trainer_id.push(trainer_id);
    }
    if (gymid) {
      gymsId = [gymid];
    }
    let tables: tableTrainer[] = await fullTableTrainer(
      start,
      end,
      gymsId,
      franchiseKeys,
      gymFranchise,
      list_trainer_id
    );
    //flatten into a list mappetTables
    let franchisesNew: Franchise[] = [];
    //convert to franchise
    for (let i = 0; i < tables.length; i++) {
      let currentTable = tables[i];
      let franchise: Franchise = {
        name: currentTable.gym_name,
        clients: currentTable.new_clients.toString(),
        // target: currentTable.target.toString(),
        trials: currentTable.performed_tests.toString(),
        target: currentTable.target_new_clients.toString(),
        // closure: currentTable.successful_percentage.toString(),
        // trainer: currentTable.trainer_name.toString(),
        //@ts-ignore
        made_renewals: currentTable.made_renewals.toString(),
        trainer: currentTable.trainer_name.toString(),
        //@ts-ignore
        planned_renewals: currentTable.planned_renewals.toString(),
        targetRenewals: currentTable.target_renewals.toString(),
        targetIncome: currentTable.target_income.toString(),
        targetUpgrade: currentTable.target_upgrades.toString(),
        upgradeDone: currentTable.upgrade_done.toString(),
        closure:
          (
            (currentTable.new_clients * 100) /
            currentTable.performed_tests
          ).toLocaleString("it-IT", { maximumFractionDigits: 2 }) + "%",

        income: currentTable.total_payments.toLocaleString("it-IT", {
          maximumFractionDigits: 2,
        }),
      };
      franchisesNew.push(franchise);
    }
    // let response = convertToFranchiseList(tables);
    // //@ts-ignore
    setRows(getRows(franchisesNew));
  };
  //mettere incassi e tutto con i numeri giusti
  const updateGyms = async () => {
    let result = await supabase
      .from("gym")
      .select("*,corporate_name(*)").eq('visible', true)
      .eq("corporate_id", props.franchiseData.id)
    if (role == "franchisee") {
      result = await supabase.from("gym").select("*,corporate_name(*)").eq('visible', true);
      // .eq("corporate_id", b.franchiseData.id);
    }
    let gyms1: Gym[] = [];
    result.data?.forEach((el) => {
      gyms1.push({
        name: el.name,
        id: el.id,
      });
    });
    let gymsFranchiseTemp: Map<string, string[]> = new Map();
    result.data?.forEach((el) => {
      if (gymsFranchiseTemp.has(el.corporate_id)) {
        //@ts-ignore
        gymsFranchiseTemp.get(el.corporate_id).push(el.id);
      } else {
        gymsFranchiseTemp.set(el.corporate_id, [el.id]);
      }
    });
    setGymFranchise(gymsFranchiseTemp);
    setGyms(gyms1);
  };
  useEffect(() => {
    let b = props;
    console.log(people);
    getAllTrainersGyms().then((data) => {
      //@ts-ignore
      setTrainers(data);
    });
    updateGyms().then((data) => { });
    setTimeout(() => {
      setFirstTime(false);
    }, 1000);
  }, []);
  const onFilter = () => {
    myFunc();
  };
  useEffect(() => {
    if (!firstTime) {
      myFunc();
    }
  }, [firstTime]);
  const setAllTrainers = async () => { };
  return (
    <div>
      <div className={genericStyle.filterCont}>
        <div className={genericStyle.filterCont}>
          <label>
            Data Inizio:
            <FullDatePicker selectedDate={dateStart} dateSet={setDateStart} />
          </label>
          <label>
            Data fine:
            <FullDatePicker selectedDate={dateEnd} dateSet={setDateEnd} />
          </label>
        </div>
        <div className={genericStyle.filterCont}>
          <label>
            Filtra per Trainer:
            <select
              name={"gymPick"}
              onChange={(e) => setTrainerId(e.target.value)}
            >
              <option value={""}>{""}</option>
              {trainers?.map((data: Trainer) => (
                <option value={data.id}>{data.name}</option>
              ))}
            </select>
          </label>
          <label>
            Filtra per Sede:
            <select name={"gymPick"} onChange={(e) => setGymId(e.target.value)}>
              <option value={""}>{""}</option>
              {gyms?.map((data: Gym) => (
                <option value={data.id}>{data.name}</option>
              ))}
            </select>
          </label>
        </div>

        <button onClick={onFilter}>Filtra</button>
      </div>
      <TableFranchiseTrainers rows={rows} columns={columns} />
    </div>
  );
}
function convertToFranchiseList(inputList: any[]): Franchise[] {
  // Group the input list by gym name
  const groupedByGym = inputList.reduce((acc, curr) => {
    let closure = (curr.new_clients * 100) / curr.performed_tests;
    const gymName = curr.name + " " + curr.surname + curr.gym_name;
    if (acc[gymName]) {
      acc[gymName].date = convertToItalyDate(curr.date);
      acc[gymName].clients += curr.new_clients;
      acc[gymName].trials += curr.performed_tests;
      let closure = (acc[gymName].clients * 100) / acc[gymName].trials;
      acc[gymName].closure = Number(closure.toFixed(2));
      acc[gymName].made_renewals += curr.made_renewals;
      acc[gymName].planned_renewals += curr.planned_renewals;
    } else {
      acc[gymName] = {
        trainer: curr.name + " " + curr.surname,
        name: curr.gym_name,
        clients: curr.new_clients,
        target: curr.target,
        trials: curr.performed_tests,
        closure: closure,
        made_renewals: curr.made_renewals,
        planned_renewals: curr.planned_renewals,
        delta: 0,
        date: convertToItalyDate(curr.date),
      };
    }

    return acc;
  }, {} as Record<string, Franchise>);
  let values: Franchise[] = Object.values(groupedByGym);
  //@ts-ignore
  return values;
}
