import { Column, DefaultCellTypes, Id, Row } from "@silevis/reactgrid";
import React, { useContext, useEffect, useState } from "react";
import { Database } from "../../../../database1.types";
import { supabase } from "../../config/config";
import { convertDateYMD, getEndDate, getStartDate } from "../../Utils/DateUtil";
import { CellData, Columns } from "../../Utils/tableInterface";
import {
  getAllTrainerDailyPerfromance,
  returnsTableFranchiseFunction,
  tableFranchiseContainer,
} from "../../Utils/utils";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import MonthPicker from "../DatePicker/MonthDatePicker";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import TableFranchise from "./TableFranchise";
import genericStyle from "../genericStyle.module.css";
import FullDatePicker from "../DatePicker/FullDatePicker";
import { useStore } from "../../steteManagement/zustandState";

interface Franchise {
  name: string;
  clients: number;
  target: number;
  trials: number;
  closure: number;
  delta: number;
  targetRenewals?: number;
  targetUpgrade?: number;
  targetIncome?: number;
  income?: number;
  plannedRenewals?: number;
  madeRenewals?: number;
}

export interface TableData {
  columns: Columns[];
  rows: CellData[];
}

interface data {
  //@beppe mettere apposto qua che non funzionera' uguale per il franchise ma soltanto per il gym_holder funziona per ora
  franchiseData: any;
}

const getRows = (people: Franchise[]) => [
  ...people.map<CellData>((person, idx) => ({
    cells: [
      { type: "text", text: person.name },

      { type: "text", text: person.clients },
      { type: "text", text: person.target },
      { type: "text", text: person.trials },
      { type: "text", text: person.closure },
      { type: "text", text: person.delta },
      { type: "text", text: person.targetRenewals },
      { type: "text", text: person.targetUpgrade },
      { type: "text", text: person.targetIncome },
      { type: "text", text: person.income },
      { type: "text", text: person.plannedRenewals },
      { type: "text", text: person.madeRenewals },
    ],
  })),
];

const columnList: Columns[] = [
  {
    name: "Sede",
    width: 150,
    align: "center",
    sticky: true,
  },
  {
    name: "Clienti Acquisiti",
    width: 100,
    align: "center",
  },
  {
    name: "Target Nuovi clienti",
    width: 100,
    align: "center",
  },
  {
    name: "Prove Effettuate",
    width: 100,
    align: "center",
  },
  {
    name: "% chiusura",
    width: 100,
    align: "center",
  },
  {
    name: "Delta Target",
    width: 100,
    align: "center",
  },
  {
    name: "Target Rinnovi",
    width: 100,
    align: "center",
  },
  {
    name: "Target Upgrade",
    width: 100,
    align: "center",
  },
  {
    name: "Target Incassi",
    width: 100,
    align: "center",
  },
  {
    name: "Incassi",
    width: 100,
    align: "center",
  },
  {
    name: "Rinnovi Programmati",
    width: 100,
    align: "center",
  },
  {
    name: "Rinnovi Effettuati",
    width: 100,
    align: "center",
  },
];

const getPeople = (): Franchise[] => [
  //@ts-ignore
  {
    name: "",
    //@ts-ignore
    clients: '',
    //@ts-ignore
    target: '',
    //@ts-ignore
    trials: '',
    //@ts-ignore
    closure: '',
    //@ts-ignore
    delta: '',
  },
];
interface TrainerDailyPerformance {
  id: number;
  trainer_id: string; // UUID
  gym_id: number;
  performed_tests: number;
  taken_tests: number;
  planned_renewals: number;
  made_renewals: number;
  training_sessions: number;
  new_clients: number;
  work_hours: number;
  break_hours: number;
  break_work: string;
  date: string; // Date
  created_at: string; // Timestamp
}
type GymDailyPerformance = {
  id: number;
  gym_id: number;
  created_by: string;
  date: string;
  subscription_payments: number;
  pos_payments: number;
  wire_transfer_payments: number;
  cash_payments: number;
  satispay_payments: number;
  total_payments: number;
  created_at: string;
};

interface TargetsGym {
  id: number;
  date: string; // Date
  target_new_clients: number;
  target_renewals: number;
  target_upgrades: number;
  target_income: number;
  gym_id: number;
}
type TableAddOn = {
  gym_name: string;
  deltaTarget: number;
  percentage_performed_new_clients: number;
  income?: number;
};
type tableSede = TargetsGym & TrainerDailyPerformance & TableAddOn;
async function sumAllTheResult(
  targets: TargetsGym[],
  result: TrainerDailyPerformance[],
  gymPerformance: GymDailyPerformance[]
): Promise<tableSede[]> {
  const myMap = new Map<number, tableSede>();
  for (let i = 0; i < result.length; i++) {
    let single_result = result[i];
    if (myMap.has(single_result.gym_id)) {
      let old_value = myMap.get(single_result.gym_id);
      if (old_value) {
        old_value.planned_renewals += single_result.planned_renewals;
        old_value.made_renewals += single_result.made_renewals;
        old_value.new_clients += single_result.new_clients;
        old_value.performed_tests += single_result.performed_tests;
        old_value.taken_tests += single_result.taken_tests;
        old_value.training_sessions += single_result.training_sessions;
        old_value.work_hours += single_result.work_hours;
        old_value.break_hours += single_result.break_hours;
        old_value.percentage_performed_new_clients = Math.round(
          (old_value.new_clients / old_value.performed_tests) * 100
        );
        myMap.set(single_result.gym_id, old_value);
      }
    } else {
      let newTable: tableSede = {
        id: 0,
        trainer_id: "",
        gym_id: single_result.gym_id,
        performed_tests: single_result.performed_tests,
        taken_tests: single_result.taken_tests,
        planned_renewals: single_result.planned_renewals,
        made_renewals: single_result.made_renewals,
        training_sessions: single_result.training_sessions,
        new_clients: single_result.new_clients,
        work_hours: single_result.work_hours,
        break_hours: single_result.break_hours,
        break_work: single_result.break_work,
        date: single_result.date,
        created_at: single_result.created_at,
        target_new_clients: 0,
        target_renewals: 0,
        target_upgrades: 0,
        target_income: 0,
        deltaTarget: 0,
        percentage_performed_new_clients: 0,
        gym_name: "",
      };

      myMap.set(single_result.gym_id, newTable);
    }
  }
  for (let i = 0; i < targets.length; i++) {
    let single_target = targets[i];
    if (myMap.has(single_target.gym_id)) {
      let old_value = myMap.get(single_target.gym_id);
      if (old_value) {
        old_value.target_new_clients += single_target.target_new_clients;
        old_value.target_renewals += single_target.target_renewals;
        old_value.target_upgrades += single_target.target_upgrades;
        old_value.target_income += single_target.target_income;
        old_value.deltaTarget =
          old_value.target_new_clients - old_value.new_clients;
        myMap.set(single_target.gym_id, old_value);
      }
    }
  }
  for (let i = 0; i < gymPerformance.length; i++) {
    let single_gymPerformance = gymPerformance[i];
    if (myMap.has(single_gymPerformance.gym_id)) {
      let old_value = myMap.get(single_gymPerformance.gym_id);
      if (old_value) {
        if (!old_value.income) {
          old_value.income = 0;
        }
        //@ts-ignore
        old_value.income += single_gymPerformance.total_payments;
      }
    }
  }
  let resultArray: tableSede[] = [];
  myMap.forEach((value) => {
    resultArray.push(value);
  });

  return resultArray;
}
export default function TableFranchiseContainer(props: data) {
  const currentDate = new Date();
  const [people, setPeople] = React.useState<Franchise[]>(getPeople());
  const [rows, setRows] = React.useState<CellData[]>(getRows(people));
  const [columns, setColumns] = React.useState<Columns[]>(columnList);
  const [dateStart, setDateStart] = React.useState<Date>(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [dateEnd, setDateEnd] = React.useState<Date>(new Date());
  const [gyms, setGyms] = React.useState<GymInterface[]>([]);
  const [gym, setGym] = React.useState<string>("");
  const onFilter = async () => {
    myFunc();
  };
  const setAllGyms = async () => {
    let result = supabase
      .from("gym")
      .select("*").eq('visible', true)
      .order("name", { ascending: true });
    const b = props;
    //@ts-ignore
    if (role != "franchisee") {
      //@ts-ignore
      result = result.eq("corporate_id", b.franchiseData.id);
    }
    //@ts-ignore
    result = await result;
    //@ts-ignore
    setGyms(result.data);
  };
  const newFilter = async () => {
    let start = getStartDate(dateStart);
    let gyms_ids = [];
    if (gym !== "") {
      gyms_ids = [parseInt(gym)];
    } else {
      gyms_ids = gyms
        .filter((gym) => typeof gym.id === "number")
        .map((gym) => gym.id);
    }
    let end = getEndDate(dateEnd);

    let result = await supabase
      .from("trainer_daily_performance")
      .select("*")
      .in("gym_id", gyms_ids)
      .gte("date", start)
      .lte("date", end);
    let targets = await supabase
      .from("targets_gym")
      .select("*")
      .in("gym_id", gyms_ids)
      .gte("date", start)
      .lte("date", end);
    //@ts-ignore
    let income = await supabase
      .from("gym_daily_performance")
      .select("*")
      .in("gym_id", gyms_ids)
      .gte("date", start)
      .lte("date", end);
    //@ts-ignore

    let list = await sumAllTheResult(targets.data, result.data, income.data);
    //now convert to franchise
    let franchiseList: Franchise[] = [];

    for (let i = 0; i < list.length; i++) {
      let single = list[i];

      let gym = gyms.find((el) => parseInt(el.id) == single.gym_id);
      if (gym) {
        let franchise: Franchise = {
          name: gym.name,
          clients: single.new_clients,
          target: single.target_new_clients,
          trials: single.performed_tests,
          //@ts-ignore
          closure: single.percentage_performed_new_clients.toLocaleString(
            "it-IT",
            {
              maximumFractionDigits: 2,
            }
          ),
          delta: single.deltaTarget,
          targetIncome: single.target_income,
          targetRenewals: single.target_renewals,

          income: 0,
          //@ts-ignore
          targetUpgrade: single.target_upgrades,
          //@ts-ignore
          madeRenewals: single.made_renewals,

          //@ts-ignore
          plannedRenewals: single.planned_renewals,
        };
        if (single.income) {
          //@ts-ignore
          franchise.income = single.income.toLocaleString("it-IT", {
            maximumFractionDigits: 2,
          });
        }
        franchiseList.push(franchise);
      }
    }
    setRows(getRows(franchiseList));
  };

  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
    franchise_ids,
    updateFranchiseIds,
  } = useStore();
  const myFunc = async () => {
    //aggiungi async function e setRows
    //@ts-ignore
    //let response = await getMonthlyTargetByCorporateNameAllTrainers(1) as responseType
    let b = props;
    let start = getStartDate(dateStart);

    let end = getEndDate(dateEnd);

    let table: returnsTableFranchiseFunction = await tableFranchiseContainer({
      in_corporate_id: b.franchiseData.id,
      datestart: start,
      dateend: end,
    });
    if (gym !== "") {
      table = table.filter((el) => el.gym_id === parseInt(gym));
    }
    let uuid = (await supabase.auth.getSession()).data.session?.user.id;
    type newIds = { id: number };

    let result = await supabase
      .from("gym")
      .select("*")
      .eq("corporate_id", b.franchiseData.id)
      .eq('visible', true)
    if (role == "franchisee") {
      result = await supabase.from("gym").select("*");
      // .eq("corporate_id", b.franchiseData.id);
    }
    let tutte = gyms.find((el) => el.name == "Visualizza Tutte");
    console.log(tutte);
    if (tutte == undefined) {
      gyms.push({ id: "", name: "Visualizza Tutte" });
    }
    //@ts-ignore
    setGyms([...result.data, { id: "", name: "Visualizza Tutte" }]);
    // .gte("date", start)
    // .lte("date", end);
    //@ts-ignore
    // let idsRaw:newIds[] = result.data
    // const ids = idsRaw.map((el: { id: any; }) => el.id)
    // let new_rows = await supabase.from('trainer_daily_performance').select('*').gte('date',start).lte('date',end).in('gym_id',ids)
    let rows = convertToFranchiseList(table);
    //transformPerformanceData(table)
    console.log(b);
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];
      let result1 = Number(row.clients / row.trials).toFixed(2);
      //@ts-ignore
      row.closure = result1;
    }
    //let newRows = convertToFranchise(response)
    setRows(getRows(rows));
    //console.log(getRows(newRows))
  };
  useEffect(() => {
    setAllGyms();
    // myFunc();
  }, []);
  useEffect(() => {
    newFilter();
  }, [gyms, gym]);
  return (
    <div>
      <div className={genericStyle.filterCont}>
        <label>
          Data Inizio:
          <FullDatePicker selectedDate={dateStart} dateSet={setDateStart} />
        </label>
        <label>
          Data fine:
          <FullDatePicker selectedDate={dateEnd} dateSet={setDateEnd} />
        </label>
        <label>
          Sede:{" "}
          <select name={"gymPick"} onChange={(e) => setGym(e.target.value)}>
            <option value={""}>{""}</option>
            {gyms.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
        </label>
        <button onClick={newFilter}>Filtra</button>
      </div>

      <TableFranchise rows={rows} columns={columns} />
    </div>
  );
}
function convertToFranchiseList(inputList: any[]): Franchise[] {
  // Group the input list by gym name
  const groupedByGym = inputList.reduce((acc, curr) => {
    const gymName = curr.gym_name;
    if (acc[gymName]) {
      let closure = (acc[gymName].clients * 100) / acc[gymName].trials;
      acc[gymName].clients += curr.new_clients;
      acc[gymName].trials += curr.performed_tests;
      acc[gymName].closure = Number(closure.toFixed(2));
      acc[gymName].delta = acc[gymName].target - acc[gymName].clients;
    } else {
      let closure = (curr.new_clients * 100) / curr.performed_tests;
      acc[gymName] = {
        name: gymName,
        clients: curr.new_clients,
        target: curr.target,
        trials: curr.performed_tests,
        closure: Number(closure.toFixed(2)),
        delta: 0,
      };
    }
    return acc;
  }, {} as Record<string, Franchise>);
  let values: Franchise[] = Object.values(groupedByGym);
  //@ts-ignore
  return values;
}

function getAllGyms(id: any) {
  throw new Error("Function not implemented.");
}
