import { Column, DefaultCellTypes, Row } from "@silevis/reactgrid";
import React, { useContext, useEffect, useState } from "react";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import TrainerTable from "./TrainerTableIncassi";
import { Database } from "../../../../database1.types";
import { supabase } from "../../config/config";
import { CellData, Columns } from "../../Utils/tableInterface";
import { getUuid } from "../../Utils/utils";
import MonthPicker from "../DatePicker/MonthDatePicker";
import {
  convertDateYMD,
  convertToItalyDate,
  getEndDate,
  getStartDate,
} from "../../Utils/DateUtil";
import genericStyle from "../genericStyle.module.css";
import FullDatePicker from "../DatePicker/FullDatePicker";

export interface TableData {
  columns: Columns[];
  rows: CellData[];
  gymData: GymInterface;
  franchiseData: FranchiseInterface;
}


type Earnings = {
  id: number /* primary key */;
  gym_id: number /* foreign key to gym.id */;
  created_by?: string /* foreign key to profiles.id */;
  date?: string;
  subscription_payments: number; // type unknown;
  pos_payments: number; // type unknown;
  wire_transfer_payments: number; // type unknown;
  cash_payments: number; // type unknown;
  satispay_payments: number; // type unknown;
  total_payments: number; // type unknown;
  created_at?: string;
  gym?: string;
  profiles?: string;
}
type ProgressiveGymDailyPerformance = {
  subscription_payments_progressive: number; // type unknown
  pos_payments_progressive: number; // type unknown
  wire_transfer_payments_progressive: number; // type unknown
  cash_payments_progressive: number; // type unknown
  satispay_payments_progressive: number; // type unknown
  total_payments_progressive: number; // type unknown
}
type finalTable = ProgressiveGymDailyPerformance & Earnings

function createRows(datas: Earnings[]) {
  let subscription_payments_progressive = 0;
  let pos_payments_progressive = 0;
  let wire_transfer_payments_progressive = 0;
  let cash_payments_progressive = 0;
  let satispay_payments_progressive = 0;
  let total_payments_progressive = 0;
  let rows: finalTable[] = [];
  for (let i = 0; i < datas.length; i++) {
    let data = datas[i];
    subscription_payments_progressive += data.subscription_payments;
    pos_payments_progressive += data.pos_payments;
    wire_transfer_payments_progressive += data.wire_transfer_payments;
    cash_payments_progressive += data.cash_payments;
    satispay_payments_progressive += data.satispay_payments;
    total_payments_progressive += data.total_payments;
    let row: finalTable = {
      ...data,
      subscription_payments_progressive,
      pos_payments_progressive,
      wire_transfer_payments_progressive,
      cash_payments_progressive,
      satispay_payments_progressive,
      total_payments_progressive,
    };
    rows.push(row);
  }
  return rows;
}


const getRows = (rows: finalTable[]) => [
  ...rows.map<CellData>((row, idx) => ({
    cells: [
      { type: "text", text: row.date },
      {
        type: "text",
        text: row.subscription_payments,
      },
      {
        type: "text",
        text: row.pos_payments,
      },
      {
        type: "text",
        text: row.wire_transfer_payments,
      },
      {
        type: "text",
        text: row.cash_payments,
      },
      {
        type: "text",
        text: row.satispay_payments,
      },
      {
        type: "text",
        text: row.subscription_payments_progressive,
      },
      {
        type: "text",
        text: row.pos_payments_progressive,
      },
      {
        type: "text",
        text: row.wire_transfer_payments_progressive,
      },
      {
        type: "text",
        text: row.cash_payments_progressive,
      },
      {
        type: "text",
        text: row.satispay_payments_progressive,
      },
      {
        type: "text",
        text: row.total_payments_progressive,
      },
    ],
  })),
];

const color = "#c6c6c6";

const columns: Columns[] = [
  {
    name: "Data",
    width: 150,
    sticky: true,
  },
  {
    name: "Abb.",
    width: 100,
  },
  {
    name: "POS",
    width: 100,
  },
  {
    name: "Bonif.",
    width: 100,
  },
  {
    name: "Cont.",
    width: 100,
  },
  {
    name: "Sat.",
    width: 100,
  },
  {
    name: "Prg. Abb.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. POS",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. Bonif.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. Cont.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Prg. Sat.",
    width: 100,
    backgroundColor: color,
  },
  {
    name: "Totale",
    width: 100,
    backgroundColor: color,
  },
];

const mapToInterface = (raw_data: any): finalTable[] => {
  return raw_data.map((el: any) => {
    return {
      date: convertToItalyDate(el.date),
      cash_payments: (
        Math.round(el.cash_payments * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      pos_payments: (Math.round(el.pos_payments * 100) / 100).toLocaleString(
        "it-IT",
        {
          style: "currency",
          currency: "EUR",
        }
      ),
      satispay_payments: (
        Math.round(el.satispay_payments * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      subscription_payments: (
        Math.round(el.subscription_payments * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      wire_transfer_payments: (
        Math.round(el.wire_transfer_payments * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      cash_payments_progressive: (
        Math.round(el.cash_payments_progressive * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      pos_payments_progressive: (
        Math.round(el.pos_payments_progressive * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      satispay_payments_progressive: (
        Math.round(el.satispay_payments_progressive * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      subscription_payments_progressive: (
        Math.round(el.subscription_payments_progressive * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      wire_transfer_payments_progressive: (
        Math.round(el.wire_transfer_payments_progressive * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      total_payments_progressive: (
        Math.round(el.total_payments_progressive * 100) / 100
      ).toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      gym_id: Math.round(el.gym_id),
    };
  });
};

const getPeople = (): finalTable[] => [
  {
    id:1,
    gym_id: 1,
    date: "0",
    cash_payments: 0,
    pos_payments: 0,
    satispay_payments: 0,
    subscription_payments: 0,
    wire_transfer_payments: 0,
    subscription_payments_progressive: 0,
    pos_payments_progressive: 0,
    wire_transfer_payments_progressive: 0,
    cash_payments_progressive: 0,
    satispay_payments_progressive: 0,
    total_payments_progressive: 0,
    total_payments: 0,
  },
];

type trainerTableProps =
  Database["public"]["Tables"]["trainer_daily_performance"]["Row"];

export default function TrainerTableIncassiContainer(props: {
  gymData: GymInterface;
  franchiseData: FranchiseInterface;
}) {
  const currentDate = new Date();
  const [people, setPeople] = React.useState<finalTable[]>(getPeople());
  const [rows, setRows] = React.useState<CellData[]>(getRows(people));
  const [dateStart, setDateStart] = React.useState<Date>(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [dateEnd, setDateEnd] = React.useState<Date>(new Date());
  const [trueRows, settrueRows] = useState<Earnings[][]>([
    mapToInterface(rows),
  ]);
  useEffect(() => {
    async function sendTrainerTableProps(gym_id: number) {
      let uuid = await getUuid();
      let start = getStartDate(dateStart);

      let end = getEndDate(dateEnd);
      supabase
        .from("gym_daily_performance")
        .select("*")
        .eq("gym_id", parseInt(props.gymData.id))
        .eq("created_by", uuid)
        .gte("date", start)
        .lte("date", end)
        .then((res) => {
          //@ts-ignore
          let b = mapToInterface(createRows(res.data));
          setRows(getRows(b));
          console.log(res);
        });
    }
    sendTrainerTableProps(parseInt(props.gymData.id));
  }, []);

  useEffect(() => {
    console.log(rows);
  }, [rows]);

  const onFilter = async () => {
    let uuid = await getUuid();
    let start = getStartDate(dateStart);

    let end = getEndDate(dateEnd);
    supabase
      .from("gym_daily_performance")
      .select("*")
      .eq("gym_id", parseInt(props.gymData.id))
      .eq("created_by", uuid)
      .gte("date", start)
      .lte("date", end)
      .then((res) => {
        //@ts-ignore
        let b = mapToInterface(createRows(res.data));
        setRows(getRows(b));
        console.log(res);
      });
  };

  return (
    <div>
      <div className={genericStyle.filterCont}>
        <label>
          Data Inizio:
          <FullDatePicker selectedDate={dateStart} dateSet={setDateStart} />
        </label>
        <label>
          Data fine:
          <FullDatePicker selectedDate={dateEnd} dateSet={setDateEnd} />
        </label>
        <button onClick={onFilter}>Filtra</button>
      </div>
      <TrainerTable
        rows={rows}
        columns={columns}
        franchiseData={props.franchiseData}
        gymData={props.gymData}
      />
    </div>
  );
}