import React, { useEffect, useState } from "react";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import SetSede from "./SetSede";
import { Database } from "../../../../database.types";
import { supabase } from "../../config/config";
import { addGym, getOwnCorporate, getUuid } from "../../Utils/utils";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import genericStyle from "../genericStyle.module.css";
import { useStore } from "../../steteManagement/zustandState";

export interface TrainerData {
  inputList: FunctionData[];
  uploadFunction: Function;
  updateGyms: Function;
  corporateList:any[]
  onChangeFunction:Function
}

export interface FunctionData {
  callableFunction: Function;
  name: string;
  type: string;
}
type supabaseInsterTrainerPerformance =
  Database["public"]["Tables"]["trainer_daily_performance"]["Insert"];
async function insertTrainerDailyPerformance(
  data: supabaseInsterTrainerPerformance
) {
  let result = await supabase.from("trainer_daily_performance").insert(data);
  console.log(result);
}
export interface ragioneSocialeId {
  ragioneSociale: string;
}
export default function SetSedeContainer(props: any) {
  const [sedeName, setSedeName] = useState("");
  const [via, setvia] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [ragioneSociale, setRagioneSociale] = useState("");
  const [message, setmessage] = useState("");
  const [corporateList, setCorporateList] = useState<FranchiseInterface[]>([]);
  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
    franchise_ids,
    updateFranchiseIds,
  } = useStore();
  const inputList: FunctionData[] = [
    {
      callableFunction: setSedeName,
      name: "Nome Sede",
      type: "text",
    },
    {
      callableFunction: setvia,
      name: "Via",
      type: "text",
    },
    {
      callableFunction: setphoneNumber,
      name: "Telefono",
      type: "text",
    },
  ];
  async function setRagioneSocialeId() {
    let result = await getOwnCorporate();
    console.log(result);
    //@ts-ignore
    setRagioneSociale(result.id);
  }
  useEffect(() => {
    setRagioneSocialeId();
    if (role == "franchisee") {
      getAllCorporate().then((data) => {
        //@ts-ignore
        setCorporateList(data);
      });
    }
  }, []);

  const getAllCorporate = async () => {
    let result = await supabase.from("corporate_name").select("*");
    console.log(result);
    return result.data;
  };
  const handleSubmit = async () => {
    hideConfirmModal();
    let uuid = await getUuid();
    let data = { name: sedeName, owner: uuid, corporate_id: ragioneSociale };
    console.log(data);
    //non ritornarmi void, ma la promessa stessa, da qui poi setti il messaggio (setMessage) e chiami showMessageModal
    let result = await addGym(data);
    if (result.error == null) {
      setmessage("Aggiunta Sede");
      props.updateGyms(props.franchise);
      showMessageModal();
    } else {
      setmessage(result.error.message);
      showMessageModal();
    }
  };

  const [showMessageModal, hideMessageModal] = useModal(
    () => (
      <GenericModal
        isOpen
        overlayClassName={genericStyle.modalOverlay}
        className={genericStyle.modalStyle}
      >
        <div className={genericStyle.flexModal}>
          <span>{message}</span>
          <button onClick={hideMessageModal}>Ho capito</button>
        </div>
      </GenericModal>
    ),
    [message]
  );

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <GenericModal
        isOpen
        overlayClassName={genericStyle.modalOverlay}
        className={genericStyle.modalStyle}
      >
        <div className={genericStyle.flexModal}>
          <h2>Riepilogo</h2>
          <div className={genericStyle.flexModal}>
            <span>Sede: {sedeName}</span>
            <span>Indirizzo: {via}</span>
            <span>Telefono: {phoneNumber}</span>
          </div>
          <div className={genericStyle.flexButton}>
            <button onClick={hideConfirmModal}>Indietro</button>
            <button onClick={handleSubmit}>Conferma</button>
          </div>
        </div>
      </GenericModal>
    ),
    [sedeName, via, phoneNumber]
  );

  return (
      <SetSede
        inputList={inputList}
        uploadFunction={showConfirmModal}
        updateGyms={props.updateGyms}
        corporateList={corporateList}
        onChangeFunction={setRagioneSociale}
      />
  );
}
