import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import { TrainerData } from "../TrainerInput/TrainerComponentContainer";
import genericStyle from "../genericStyle.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Cell, CellData, Columns, TableData } from "../../Utils/tableInterface";
import { FranchiseInterface } from "../ChioicePage/ChoicePageContainer";
import GenericTable from "../Tables/GenericTable";
import {
  CurrentDateFormatted,
  CurrentMonthFormatted,
} from "../../Utils/DateUtil";

const TableFranchiseTrainers: FunctionComponent<TableData> = (
  props: TableData
) => {
  const date = new Date();

  return (
    <div className={genericStyle.flexCont}>
      <h2>Riepilogo dei Trainer</h2>
      <div className={genericStyle.gridWrapper}>
        <GenericTable columns={props.columns} rows={props.rows} />
      </div>
    </div>
  );
};

export default TableFranchiseTrainers;
