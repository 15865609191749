import { AUTHSTATUS, Authentication } from "./authentication";
// import { SUPABASE_ANON_KEY, SUPABASE_URL } from "../config/config";
import {createClient, SupabaseClient} from "@supabase/supabase-js";
import AsyncStorage from "@react-native-async-storage/async-storage";


export class SupaBaseAuth extends Authentication {
  supabase:SupabaseClient;
  constructor(name:string,supabase:SupabaseClient) {
    super('supabase');
    this.supabase = supabase;
  }
  setError(error: string): void {
    throw new Error("Method not implemented.");

  }
  login(username: string, password: string): Promise<boolean> {
    //@ts-ignore
    return new Promise((resolve, reject) => {
      
      this.supabase.auth
        .signInWithPassword({ email: username, password: password })
        .then((res: any) => {
          if (res.error) {
            this.setError(res.error.message);
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });
  }
  loginSms(username: string, password: string): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  logOut(): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  register(username: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.supabase.auth
        .signUp({ email: username, password: password })
        .then((res: any) => {
          if (res.error) {
            this.setError(res.error.message);
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });
  }
  checkUsername(
    username: string
  ): Promise<boolean> | AUTHSTATUS.USERNAME_ALREADY_USED {
    throw new Error("Method not implemented.");
  }
  checkPassword(username: string, password: string): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  alreadyLogged(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const b = this.supabase.auth.getSession().then(
          (data) => {
            console.log(data.data.session)
            if (data.data.session) {
              resolve(true);
            }
            else{
              resolve(false);
            }

          }
      )

    } );
  }
  checkRegister(): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  getToken(): Promise<string | null> {
    throw new Error("Method not implemented.");
  }
  getId(): Promise<string> {
    throw new Error("Method not implemented.");
  }
  resetPassword(email: string): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
}
