import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../../config/config";
import { useStore } from "../../steteManagement/zustandState";
import { convertDateYMD } from "../../Utils/DateUtil";
import { getUuid } from "../../Utils/utils";
import { GymInterface } from "../ChioicePage/ChoicePageContainer";
import MonthPicker from "../DatePicker/MonthDatePicker";
import TrainerTargetPage from "./TrainerTargetPage";
import genericStyle from "./../genericStyle.module.css";

export interface TrainerTargetProps {
  gymData: GymInterface | null;
  handleSubmit: Function;
  trainerData: TrainerData;
  setTrainerData: Function; //(data: TrainerData) => void;
  disableButton: boolean;
}
export interface TrainerData {
  date: string;
  email: string;
  name: string;
  surname: string;
  target_new_clients?: number; // optional property
  target_renewals?: number; // optional property
  target_upgrades?: number; // optional property
  target_income?: number; // optional property
}
interface Target {
  id: string; // UUID string referencing profiles(id)
  month: number;
  year: number;
  target_new_clients?: number; // optional property
  target_renewals?: number; // optional property
  target_upgrades?: number; // optional property
  target_income?: number; // optional property
  gym_id: string;
}
type FranchiseData = {
  id: string;
  name: string;
};
export default function TrainerSedeContainer(props: TrainerTargetProps) {
  const [trainerData, setTrainerData] = useState<TrainerData>({
    date: "2021-01-01",
    email: "",
    name: "",
    surname: "",
    target_new_clients: 0,
    target_renewals: 0,
    target_upgrades: 0,
    target_income: 0,
    //@ts-ignore
  });
  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
    franchise_ids,
    updateFranchiseIds,
  } = useStore();
  const [dateStart, setDateStart] = useState<Date>(new Date());
  const [dateEnd, setDateEnd] = useState<Date>(new Date());
  const [franchiese_id, setFranchiese_id] = useState<string>("");
  const [franchises, setFranchises] = useState<FranchiseData[]>([]);
  const [gymsData, setGymsData] = useState<GymInterface[]>([]);
  const [currentGym, setCurrentGym] = useState<string | null>(null);
  const [disable, setdisable] = useState<boolean>(true);
  const updateState = (data: TrainerData) =>
    setTrainerData((state) => ({ ...state, ...data }));
  const handleSubmit = async () => {
    console.log(trainerData);
    // const uuid = await supabase
    //   .from("profiles")
    //   .select("id")
    //   .eq("email", trainerData.email);
    // if (uuid.data == null || uuid.data.length === 0) {
    //   alert("email non trovata");
    //   return;
    // }
    let start = convertDateYMD(dateStart).slice(0, -2) + "01";
    dateStart;
    start = start.substring(0, start.length - 2) + "01";
    let b = gymsData;
    let targetData = {
      date: start,
      //@ts-ignore
      target_new_clients: parseInt(trainerData.target_new_clients as string), // @ts-ignore - Ignore type error because target_new_clients is optional
      target_renewals: parseInt(trainerData.target_renewals as string), // @ts-ignore - Ignore type error because target_renewals is optional
      target_upgrades: parseInt(trainerData.target_upgrades as string), // @ts-ignore - Ignore type error because target_upgrades is optional
      target_income: parseInt(trainerData.target_income as string), // @ts-ignore - Ignore type error because target_income is optional
      gym_id: currentGym,
    };

    const { data, error } = await supabase
      .from("targets_gym")
      .upsert(targetData, { onConflict: "date, gym_id" });
    if (error) {
      alert(error.message);
      return;
    }
    alert("Aggiunto");
  };
  const setFranchiese = async () => {
    if (role == "franchisee") {
      let result = await supabase
        .from("corporate_name")
        .select("*")
        .eq("accepted", true);
      // @ts-ignore
      setFranchises(result.data);
      // @ts-ignore
      setFranchiese_id(result.data[0].id);
    } else {
      let uuid = await getUuid();
      let result = await supabase
        .from("corporate_name")
        .select("*")
        .eq("owner_id", uuid);
      // @ts-ignore
      setFranchiese_id(result.data[0].id);
    }
  };
  //quando viene selezionato un franchise appaiano tutte le scelte delle singole palestre
  useEffect(() => {
    setGyms();
  }, [franchiese_id]);
  const setGyms = async () => {
    let result = await supabase
      .from("gym")
      .select("*")
      .eq("corporate_id", franchiese_id);
    // @ts-ignore
    setGymsData(result.data);
    // @ts-ignore
    setCurrentGym(result.data[0].gym_id);
  };
  useEffect(() => {
    setFranchiese();
  }, []);
  useEffect(() => {
    if (franchiese_id != "" && currentGym != null && currentGym != undefined) {
      setdisable(false);
    }
  }, [franchiese_id, currentGym]);
  return (
    <div className={genericStyle.flexCont}>
      <h2>Aggiungi Target Ragione Sociale</h2>
      {/* <div>Ragione sociale: {props.gymData.name}</div> */}
      {role == "franchisee" ? <div>Seleziona la ragione sociale</div> : null}
      <div className={genericStyle.filterWrapper}>
        {role == "franchisee" ? (
          <label className={genericStyle.labelWrapper}>
            Filtra per Ragione Sociale:
            <select
              name={"gymPick"}
              onChange={(e) => setFranchiese_id(e.target.value)}
            >
              <option value={""}>{""}</option>
              {franchises?.map((data: FranchiseData) => (
                <option value={data.id}>{data.name}</option>
              ))}
            </select>
          </label>
        ) : null}
        {franchiese_id != "" ? (
          <label className={genericStyle.labelWrapper}>
            Filtra per Sede:
            <select
              name={"gymPick"}
              //@ts-ignore
              onChange={(e) => {
                setCurrentGym(e.target.value);
              }}
            >
              <option value={""}>{""}</option>
              {gymsData?.map((data: GymInterface) => (
                <option value={data.id}>{data.name}</option>
              ))}
            </select>
          </label>
        ) : null}
        <label className={genericStyle.labelWrapper}>
          Data Inizio:
          <MonthPicker selectedDate={dateStart} dateSet={setDateStart} />
        </label>

        <TrainerTargetPage
          gymData={null}
          handleSubmit={handleSubmit}
          trainerData={trainerData}
          setTrainerData={updateState}
          disableButton={disable}
        ></TrainerTargetPage>
      </div>
    </div>
  );
}
