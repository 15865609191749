import React, { useContext, useEffect, useState } from "react";
import { FranchiseInterface, GymInterface } from "../ChioicePage/ChoicePageContainer";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import GymDailyPerformanceInputPage from "./GymDailyPerformanceInputPage";
import {Database} from "../../../../database.types";
import {supabase} from "../../config/config";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import genericStyle from "../genericStyle.module.css"
import { getUuid } from "../../Utils/utils";
import { useStore } from "../../steteManagement/zustandState";
import { getStartDate } from "../../Utils/DateUtil";

type supabaseInsterGymPerformance = Database['public']['Tables']['gym_daily_performance']['Insert']
async function insertGymDailyPerformance(data:supabaseInsterGymPerformance,   dailyPerformance:any ){
  if (dailyPerformance){
    let result = await supabase.from("gym_daily_performance").update(data).eq('id', dailyPerformance.id);
  }
  else{
    let result = await  supabase.from('gym_daily_performance').insert(data)
  }
}
export default function EditGymDailyPerformanceInputContainer(props:{gymData:GymInterface, franchiseData:FranchiseInterface}) {
  const [subscriptionPayments, setSubscriptionPayments] = useState<number>(0);
  const [posPayments, setPosPayments] = useState<number>(0);
  const [wireTransferPayments, setWireTransferPayments] = useState<number>(0);
  const [cashPayments, setCashPayments] = useState<number>(0);
  const [satispayPayments, setSatispayPayments] = useState<number>(0);
  const [totalPayments, setTotalPayments] = useState<number>(0);
  const [message, setmessage] = useState('');
  const [gymId, setgymId] = useState('');
  const [trainers, settrainers] = useState<any>([]);
  const [disable, setdisable] = useState<boolean>(true);

  const [gymList, setgymList] = useState<any[]>([]);
  const [date, setDate] = React.useState<Date>(new Date());
  const [dailyPerformance, setdailyPerformance] = React.useState<any>(null);


  useEffect(()=>{
    setTotalPayments(parseFloat((subscriptionPayments + posPayments + wireTransferPayments + cashPayments + satispayPayments).toFixed(2)))
  }, [subscriptionPayments, posPayments, wireTransferPayments, cashPayments, satispayPayments])

  const defaultInputList:FunctionData[] = [
    {
        callableFunction:setSubscriptionPayments,
        name:'Tot Abbonamenti',
        type:'number'
    },
    {
        callableFunction:setPosPayments,
        name:'Tot Pos',
        type:'number'
    },
    {
        callableFunction:setWireTransferPayments,
        name:'Tot Bonifico',
        type:'number'
    },
    {
        callableFunction:setCashPayments,
        name:'Tot Contanti',
        type:'number'
    },
    {
        callableFunction:setSatispayPayments,
        name:'Tot Satispay',
        type:'number'
    },
    {
        callableFunction:setTotalPayments,
        name:'Totale',
        type:'number',
        placeholder: "",
        disabled: true
    },
  ]

  const [inputList, setInputList] = React.useState<FunctionData[]>(defaultInputList);

  const [trainerId, settrainerId] = useState('');
  const [name, setname] = useState('');
  const [surname, setsurname] = useState('');

  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
  } = useStore()
  const getOwner = async() =>{
    let test = null 
    if (role == 'franchisee'){
      //@ts-ignore
      test =  await supabase.from('gym').select('*, corporate_name(*)')
    }
    else{
      const uuid = await  getUuid()
      //@ts-ignore
       test =  await supabase.from('gym').select('*, corporate_name!inner(*)').eq('corporate_name.owner_id', uuid)
    }
    if(test.data != undefined){
      setgymList(test.data)
    }
  }

  const setData = (event:any ) =>{
    settrainerId(event.target.value)
    setname(event.target.options[event.target.selectedIndex].getAttribute('data-trainername'))
    setsurname(event.target.options[event.target.selectedIndex].getAttribute('data-trainersurname'))
}

const fetchTrainers = async () =>{
  let result = await  supabase.from('gym_trainer').select('*, profiles(*)').eq('gym_id', gymId).then(res=>{
      if (res.error == null){
          settrainers(res.data)
      }
  })
}
const fetchDayilyPerformance = async () =>{
  let result = await  supabase.from('gym_daily_performance').select('*').eq('gym_id', gymId).eq('created_by', trainerId).eq('date', getStartDate(date)).then(res=>{
      if (res.error == null && res.data.length >0){
        setdailyPerformance(res.data[0])
      }
      else if(res.error == null && res.data?.length == 0){
        setdailyPerformance(null)
      }
  })
}

const editInputList = (data:any) =>{
  if (data){
    setSubscriptionPayments( parseInt(data.subscription_payments))
    setPosPayments( parseInt(data.pos_payments))
    setWireTransferPayments( parseInt(data.wire_transfer_payments))
    setCashPayments( parseInt(data.cash_payments))
    setSatispayPayments( parseInt(data.satispay_payments))
    setInputList([
      {
        callableFunction:setSubscriptionPayments,
        name:'Tot Abbonamenti',
        type:'number',
        placeholder: data.subscription_payments
    },
    {
        callableFunction:setPosPayments,
        name:'Tot Pos',
        type:'number',
        placeholder: data.pos_payments
    },
    {
        callableFunction:setWireTransferPayments,
        name:'Tot Bonifico',
        type:'number',
        placeholder: data.wire_transfer_payments
    },
    {
        callableFunction:setCashPayments,
        name:'Tot Contanti',
        type:'number',
        placeholder: data.cash_payments
    },
    {
        callableFunction:setSatispayPayments,
        name:'Tot Satispay',
        type:'number',
        placeholder: data.satispay_payments
    },
    {
        callableFunction:setTotalPayments,
        name:'Totale',
        type:'number',
        placeholder: (Math.round((parseInt(data.subscription_payments) + parseInt(data.pos_payments) + parseInt(data.wire_transfer_payments) + parseInt(data.cash_payments) +  parseInt(data.satispay_payments))*100)/100).toString(),
        disabled: true
    },
  ]
    )
}
else{
  setInputList(defaultInputList)
  setSubscriptionPayments(0)
  setPosPayments(0)
  setWireTransferPayments(0)
  setCashPayments(0)
  setSatispayPayments(0)
  setTotalPayments(0)
}
}

useEffect(()=>{
  setInputList([
    {
        callableFunction:setSubscriptionPayments,
        name:'Tot Abbonamenti',
        type:'number'
    },
    {
        callableFunction:setPosPayments,
        name:'Tot Pos',
        type:'number'
    },
    {
        callableFunction:setWireTransferPayments,
        name:'Tot Bonifico',
        type:'number'
    },
    {
        callableFunction:setCashPayments,
        name:'Tot Contanti',
        type:'number'
    },
    {
        callableFunction:setSatispayPayments,
        name:'Tot Satispay',
        type:'number'
    },
    {
        callableFunction:setTotalPayments,
        name:'Totale',
        type:'number',
        placeholder: totalPayments.toString(),
        disabled: true
    },
  ])
}, [totalPayments])

useEffect(()=>{
  getOwner()
}, [])
useEffect(()=>{
  if(gymId!=''){
    fetchTrainers()
  }
}, [gymId])
useEffect(()=>{
  if(date && gymId!='' && trainerId != ''){
    setdisable(false)
    fetchDayilyPerformance()
  }
  else(
    setdisable(true)
  )
}, [date, gymId, trainerId])
useEffect(()=>{
  editInputList(dailyPerformance)
}, [dailyPerformance])


    const handleSubmit = async () => {
      hideConfirmModal()
        let data:supabaseInsterGymPerformance = {
            gym_id: parseInt(gymId),
            subscription_payments:subscriptionPayments,
            pos_payments:posPayments,
            wire_transfer_payments:wireTransferPayments,
            cash_payments:cashPayments,
            satispay_payments:satispayPayments,
            total_payments:totalPayments,
            created_by: trainerId,
            date: getStartDate(date)
            //total_payments:totalPayments
        }

        insertGymDailyPerformance(data, dailyPerformance).then(res=>
          fetchDayilyPerformance()
        )
    }

    //@beppe da aggiungere messaggio come per il resto


    const [showMessageModal, hideMessageModal] =  useModal(() => (
      <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
          <div className={genericStyle.flexModal}>
              <span>{message}</span>
          <button onClick={hideMessageModal}>Ho capito</button>
          </div>
      </GenericModal>
    ), [message]);

  const [showConfirmModal, hideConfirmModal] =  useModal(() => (
      <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
          <div className={genericStyle.flexModal}>
          <h2>Riepilogo</h2>
          <div className={genericStyle.flexModal}>
              <span>Abbonamenti: {subscriptionPayments}</span>
              <span>Pos: {posPayments}</span>
              <span>Bonifico: {wireTransferPayments}</span>
              <span>Contanti: {cashPayments}</span>
              <span>Satispay: {satispayPayments}</span>
              <span>Totale: {totalPayments}</span>
          </div>
          <div className={genericStyle.flexButton}>
          <button onClick={hideConfirmModal}>Indietro</button>
          <button onClick={handleSubmit}>Conferma</button>
          </div>
          </div>
      </GenericModal>
    ), [subscriptionPayments, posPayments, wireTransferPayments, cashPayments, satispayPayments, totalPayments]);


  return<GymDailyPerformanceInputPage
    inputList={inputList}
    uploadFunction={showConfirmModal}
    franchiseData={props.franchiseData}
    gymList={gymList}
    updateGymList={getOwner}
    setgymId={setgymId}
    trainerData={trainers}
    updateTrainers={fetchTrainers}
    setData={setData} date={date} setDate={setDate}
    disableButton={disable}    />
    }
