import React, {useEffect, useState} from "react";
import { FranchiseInterface, GymInterface } from "../ChioicePage/ChoicePageContainer";
import SetSede from "./SetTargetTrainer";
import {Database} from "../../../../database.types";
import {supabase} from "../../config/config";
import {addGym, getOwnCorporate, getUuid, setTargetGym, setTargetTrainerCorporate} from "../../Utils/utils";
import MonthPicker from "../DatePicker/MonthDatePicker";
import { GenericModal } from "../GenericModal/GenericModal";
import { profile } from "console";
import { useModal } from "react-modal-hook";
import genericStyle from "../genericStyle.module.css"


export interface TrainerData {
    inputList: FunctionData[],
    uploadFunction:Function;
    franchiseData:FranchiseInterface;
  }

export interface FunctionData{
    callableFunction:Function;
    name:string;
    type:string;
    other?:any;
}
type supabaseInsterTrainerPerformance = Database['public']['Tables']['trainer_daily_performance']['Insert']
async function insertTrainerDailyPerformance(data:supabaseInsterTrainerPerformance){
    let result = await  supabase.from('trainer_daily_performance').insert(data)
    console.log(result)
}
export interface ragioneSocialeId{
    ragioneSociale:string,
}
export default function SetTargetTrainerContainer(props:{franchiseData:FranchiseInterface}) {
    const [sedeName, setSedeName] = useState('');
    const [mail, setmail] = useState('');
    const [name, setname] = useState('');
    const [surname, setsurname] = useState('');
    const [date, setdate] = useState<Date>();
    const [ragioneSociale, setRagioneSociale] = useState('');
    const [message, setmessage] = useState('');
    const inputList:FunctionData[] = [

        {
            callableFunction:setSedeName,
            name:'Target',
            type:'number'
        },{
            callableFunction:setdate,
            name:'Mese',
            type:'date',
            other: date
        },
        {
            callableFunction:setname,
            name:'Nome',
            type:'text',
        },
        {
            callableFunction:setsurname,
            name:'Cognome',
            type:'text',
        },
        {
            callableFunction:setmail,
            name:'Mail',
            type:'text',
        },
    ]
    async function setRagioneSocialeId(){
        let result = await getOwnCorporate()
        console.log(result)
        //@ts-ignore 
        setRagioneSociale(result.id)
    }
    useEffect(() => {
        setRagioneSocialeId()
    },[])
 
    const handleSubmit = async() => {
        hideConfirmModal() 

        let uuid =await  getUuid()
        let b = props
        console.log(props)
        
        setTargetTrainerCorporate(parseInt(b.franchiseData.id),uuid,'2027-01-01',parseInt(sedeName))
        
        // let data = {date:date,gym_id:b.gymData.id,target:parseInt(sedeName)} 
         
        // console.log(data) 
        // //@ts-ignore
        // const year = date.getFullYear();
        //         //@ts-ignore

        // const month = String(date.getMonth() + 1).padStart(2, '0');
        //         //@ts-ignore

        // const day = String(date.getDate()).padStart(2, '0');
        
        // // Combine the year, month, and day into a string in the format "yyyy-mm-dd"
        // const dateString = `${year}-${month}-${day}`;
        
        // let dateStart = date?.toUTCString();
        // setTargetGym(props.gymData.id,parseInt(sedeName),dateString)
        //addGym(data)
    };

    const [showMessageModal, hideMessageModal] =  useModal(() => (
        <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
            <div className={genericStyle.flexModal}>
                <span>{message}</span>
            <button onClick={hideMessageModal}>Ho capito</button>
            </div>
        </GenericModal>
      ), [message]);

    const [showConfirmModal, hideConfirmModal] =  useModal(() => (
        <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
            <div className={genericStyle.flexModal}>
            <h2>Riepilogo</h2>
            <div className={genericStyle.flexModal}>
                <span>Target: {sedeName}</span>
                {date?<span>Mese: {(date.getMonth() + 1 ).toString() + "/" + date.getFullYear().toString()}</span>:<span>Mese assente</span>}
                <span>Nome: {name}</span>
                <span>Cognome: {surname}</span>
                <span>Mail: {mail}</span>
            </div>
            <div className={genericStyle.flexButton}>
            <button onClick={hideConfirmModal}>Indietro</button>
            <button onClick={handleSubmit}>Conferma</button>
            </div>
            </div>
        </GenericModal>
      ), [sedeName, date, name, surname, mail]);

    return (
        <SetSede
            inputList={inputList}
            uploadFunction={showConfirmModal}
            franchiseData={props.franchiseData}
        />  
    );
}
