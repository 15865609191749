import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import { TrainerData } from "../TrainerInput/TrainerComponentContainer";
import genericStyle from "../genericStyle.module.css";
import { ThemeContext } from "../../context/ThemeContext";
import { cleanInput } from "../../Utils/utils";

const CreateInput = (
  type: string,
  name: string,
  onChange: Function,
  disabled: boolean = false,
  placeholder: string | undefined
) => {

  return (
    <div className={genericStyle.inputCont} key={type + name}>
      <span>{name}</span>
      {placeholder == null ? (
        <input
        min={0}
          disabled={disabled}
          type={'text'}
          name={name}
          onChange={(e) => {

            if (type != 'number'){
              onChange(e.target.value)
            }
            else{
            e.target.value = cleanInput(e.target.value)
              const numValue = parseFloat(e.target.value);
              let roundedValue = Math.round(numValue * 100) / 100;
              if(Number.isNaN(roundedValue)){
                roundedValue=0
              }
              else{
                onChange(parseFloat(numValue.toFixed(2)));
              }
            }
          }}
        />
      ) : (
        <input
          disabled={disabled}
          min={0}
          type={'text'}
          name={name}
          placeholder={placeholder}
          onChange={(e) =>{
            
            if (type != 'number'){
              onChange(e.target.value)
            }
            else{
              e.target.value = cleanInput(e.target.value)
                const numValue = parseFloat(e.target.value);
                let roundedValue = Math.round(numValue * 100) / 100;
                if(Number.isNaN(roundedValue)){
                  roundedValue=0
                }
                else{
                  onChange(parseFloat(numValue.toFixed(2)));
                }
            }
            }}
        />
      )}
    </div>
  );
};

const GymDailyPerformanceInputPage: FunctionComponent<TrainerData> = (
  props: TrainerData
) => {
  const themeContext = React.useContext(ThemeContext);

  const date = new Date();

  return (
    <div className={genericStyle.formCont}>
      <div className={genericStyle.flexTitle}>
      <h2>Riepilogo Incassi di {date.toLocaleString('IT-it', {weekday:'short', year:'numeric', month:'2-digit', day:'2-digit'})}</h2>
        <div>
          <div className={genericStyle.inputCont}>
            <span>Utente: {themeContext?.nameSurname}</span>
            <span>Società: {props.franchiseData.name}</span>
            <span>Sede: {props.gymData.name}</span>
          </div>
        </div>
      </div>
      <div className={genericStyle.fullWidth}>
        {props.inputList.map((data) =>
          CreateInput(
            data.type,
            data.name,
            data.callableFunction,
            data.disabled,
            data.placeholder
          )
        )}
      </div>
      <button onClick={() => props.uploadFunction()}>Invia Dati</button>
    </div>
  );
};

export default GymDailyPerformanceInputPage;
