import React, { createContext, useState } from "react";
//import { StatusBar } from "react-native";
interface ThemeContextInterface {
  toggleTheme(): void;
  currentTheme: ThemeColor;
  nameSurname: string;
  setNameSurname(nameSurname: string): void;
}
export interface ThemeColor {
  backgroundColor: string;
  color: string;
  placeHolderBackgroundColor: string;
  placeHolderTextColor: string;
  errorTextColor: string;
}
const black = {
  backgroundColor: "black",
  color: "black",
  placeHolderBackgroundColor: "white",
  placeHolderTextColor: "grey",
  errorTextColor: "red",
};
const white = {
  backgroundColor: "white",
  color: "white",
  placeHolderBackgroundColor: "#d8d8d8",
  placeHolderTextColor: "white",
  errorTextColor: "red",
};
export const ThemeContext = createContext<ThemeContextInterface | null>(null);

export const ThemeProvider = ({ children }: any) => {
  const [theme, setTheme] = useState("light");
  const [currentTheme, setCurrentTheme] = useState(white);
  const [nameSurname, setNameSurname] = useState("");
  const toggleTheme = () => {
    if (currentTheme === white) {
      setCurrentTheme(black);
    } else {
      setCurrentTheme(white);
    }
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, currentTheme,nameSurname,setNameSurname }}>
      {children}
    </ThemeContext.Provider>
  );
};
