import React, { FormEvent, FormEventHandler, useContext, useState } from "react";
import { FunctionComponent } from "react";
import { LoginProps } from "./SignUpContainer";
import { ThemeContext } from "../../context/ThemeContext";
import { StyleSheet } from "react-native";
import extraStyles from "../Login/extraStyles.module.css";
import genericStyle from "../genericStyle.module.css"
import { Form, Link } from "react-router-dom";
import Logo from "../../Assets/training_lab.png" 
import { createCorporate, setGymHolder } from "../../Utils/utils";


const SignUpFranchise: FunctionComponent<LoginProps> = (props: LoginProps) => {
  const theme = useContext(ThemeContext);
  const [corporate, setCorporate] = useState<string>("")
  const style = StyleSheet.create({
    background: {
      height: "100%",
    },
    input: {
      borderWidth: 0,
      width: "100%",
      borderBottomWidth: 1,
    },
    smallText: {
      fontSize: 10,
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    createAccount: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  });
  async function clickLogin(){
    
    createCorporate(corporate)
    setGymHolder()
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) =>{
    e.preventDefault()
    props.onLoginClick()
  }

  const success = () =>{
   return( <div  style={{
      height: style.background.height,
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    className={extraStyles.gradient_background}
  >
     <div className={extraStyles.confirmCont}>
  
        <h3 style={{textAlign:'center'}}>Registazione avvenuta con successo, provvedere alla convalida via mail.</h3>
        <Link to={'/login'}>Ritorna al login</Link>
        </div>

    </div>
   )
  }

  return (
    <div
      style={{
        height: style.background.height,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={extraStyles.back}
    >
      <div  className={extraStyles.container}>
      <div className={genericStyle.imgWrap}>
          <img src={Logo} className={genericStyle.logo} alt="" />
        </div>

        {props.signUpError?<h4 className={genericStyle.errorText}>Dati non corretti </h4>:null}
        {props.signUpSuccess?success():null}


        {!props.signUpError && !props.signUpSuccess?
        (<><form onSubmit={handleSubmit} style={style.inputContainer}>
            <input type="submit" hidden />
            <h3 style={{}}>Nome Società</h3>

            <div style={{}}>
              <input
                onChange={(e) => {
                  props.onChangeName(e.target.value);
                  setCorporate(e.target.value);
                } }
                type={"text"}
                placeholder={"Scrivi il nome società"}
                style={style.input}
                className={[genericStyle.no_focus, extraStyles.text_size].join(
                  " "
                )} />
            </div>
            <h3 style={{}}>Indirizzo e-mail</h3>

            <div style={{}}>
              <input
                onChange={(e) => {
                  props.onChangeUsername(e.target.value);
                } }
                type={"text"}
                placeholder={"Scrivi il tuo indirizzo e-mail"}
                style={style.input}
                className={[genericStyle.no_focus, extraStyles.text_size].join(
                  " "
                )} />
            </div>

            <h3 style={{}}>Password</h3>

            <div style={{}}>
              <input
                onChange={(e) => {
                  props.onChangePassword(e.target.value);
                } }
                type={"password"}
                placeholder={"Scrivi la tua password"}
                style={style.input}
                className={[genericStyle.no_focus, extraStyles.text_size].join(
                  " "
                )} />
            </div>
          </form><div className={extraStyles.button_cont}>
              <button onClick={async () => {
                await props.onLoginClick();
                await clickLogin();
              } } className={extraStyles.wideButton}>
                REGISTRA
              </button>
            </div><div style={{ display: 'none' }}>
              <h3 style={{}}>oppure accedi con</h3>
              <div style={{}}>
                <div>
                  <Link to={""}>Icona</Link>
                  <Link to={""}>Icona</Link>
                  <Link to={""}>Icona</Link>
                </div>
              </div>
            </div><div style={style.createAccount}>
              <h3 style={{}}>Hai un account?</h3>
              <Link style={{}} to={"/login"}>
                {"Fai il login >"}
              </Link>
            </div></>):null}
      </div>
    </div>
  );
};

export default SignUpFranchise;
