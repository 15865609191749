import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import { TrainerData } from "./SetTargetTrainerContainer";
import genericStyle from "../genericStyle.module.css";
import MonthPicker from "../DatePicker/MonthDatePicker";

const CreateInput = (
  type: string,
  name: string,
  onChange: Function,
  other?: any
) => {
  if (type == "date") {
    return (
      <div className={genericStyle.inputCont} key={type + name}>
        <span>{name}</span>
        <MonthPicker selectedDate={other} dateSet={(e: any) => onChange(e)} />
      </div>
    );
  } else {
    return (
      <div className={genericStyle.inputCont} key={type + name}>
        <span>{name}</span>
        <input
          type={type}
          name={name}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    );
  }
};

const SetTargetTrainer: FunctionComponent<TrainerData> = (
  props: TrainerData
) => {
  return (
    <div className={genericStyle.formCont}>
      <div>
        <h2>Aggiungi Target per Trainer1</h2>
      </div>
      <div className={genericStyle.inputCont}></div>
      <div className={genericStyle.fullWidth}>
        {props.inputList.map((data) =>
          CreateInput(data.type, data.name, data.callableFunction, data.other)
        )}
      </div>
      <button onClick={() => props.uploadFunction()}>Aggiungi</button>
    </div>
  );
};
export default SetTargetTrainer;
