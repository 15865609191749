import React, { useContext, useEffect, useState } from "react";
import { FranchiseInterface, GymInterface } from "../ChioicePage/ChoicePageContainer";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import GymDailyPerformanceInputPage from "./GymDailyPerformanceInputPage";
import {Database} from "../../../../database.types";
import {supabase} from "../../config/config";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import genericStyle from "../genericStyle.module.css"

type supabaseInsterGymPerformance = Database['public']['Tables']['gym_daily_performance']['Insert']
async function insertGymDailyPerformance(data:supabaseInsterGymPerformance, backFunction:Function){
    console.log(data)
    let result = await  supabase.from('gym_daily_performance').insert(data)
    if (result.error == null){
      backFunction()
    }
}
export default function GymDailyPerformanceInputContainer(props:{gymData:GymInterface, franchiseData:FranchiseInterface, backFunction:Function}) {
  const [subscriptionPayments, setSubscriptionPayments] = useState<number>(0);
  const [posPayments, setPosPayments] = useState<number>(0);
  const [wireTransferPayments, setWireTransferPayments] = useState<number>(0);
  const [cashPayments, setCashPayments] = useState<number>(0);
  const [satispayPayments, setSatispayPayments] = useState<number>(0);
  const [totalPayments, setTotalPayments] = useState<number>(0);
  const [message, setmessage] = useState('');


  useEffect(()=>{
    setTotalPayments(parseFloat((subscriptionPayments + posPayments + wireTransferPayments + cashPayments + satispayPayments).toFixed(2)))
  }, [subscriptionPayments, posPayments, wireTransferPayments, cashPayments, satispayPayments])

  const inputList:FunctionData[] = [
    {
        callableFunction:setSubscriptionPayments,
        name:'Tot Abbonamenti',
        type:'number'
    },
    {
        callableFunction:setPosPayments,
        name:'Tot Pos',
        type:'number'
    },
    {
        callableFunction:setWireTransferPayments,
        name:'Tot Bonifico',
        type:'number'
    },
    {
        callableFunction:setCashPayments,
        name:'Tot Contanti',
        type:'number'
    },
    {
        callableFunction:setSatispayPayments,
        name:'Tot Satispay',
        type:'number'
    },
    {
        callableFunction:setTotalPayments,
        name:'Totale',
        type:'number',
        placeholder: totalPayments.toString(),
        disabled: true
    },
  ]
    const handleSubmit = async () => {
      hideConfirmModal()
        let data:supabaseInsterGymPerformance = {
            gym_id: parseInt(props.gymData.id) ,
            subscription_payments:subscriptionPayments,
            pos_payments:posPayments,
            wire_transfer_payments:wireTransferPayments,
            cash_payments:cashPayments,
            satispay_payments:satispayPayments,
            total_payments:totalPayments

            //total_payments:totalPayments
        }
        console.log(data)
        await insertGymDailyPerformance(data, props.backFunction)
    }

    //@beppe da aggiungere messaggio come per il resto


    const [showMessageModal, hideMessageModal] =  useModal(() => (
      <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
          <div className={genericStyle.flexModal}>
              <span>{message}</span>
          <button onClick={hideMessageModal}>Ho capito</button>
          </div>
      </GenericModal>
    ), [message]);

  const [showConfirmModal, hideConfirmModal] =  useModal(() => (
      <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
          <div className={genericStyle.flexModal}>
          <h2>Riepilogo</h2>
          <div className={genericStyle.flexModal}>
              <span>Abbonamenti: {subscriptionPayments}</span>
              <span>Pos: {posPayments}</span>
              <span>Bonifico: {wireTransferPayments}</span>
              <span>Contanti: {cashPayments}</span>
              <span>Satispay: {satispayPayments}</span>
              <span>Totale: {totalPayments}</span>
          </div>
          <div className={genericStyle.flexButton}>
          <button onClick={hideConfirmModal}>Indietro</button>
          <button onClick={handleSubmit}>Conferma</button>
          </div>
          </div>
      </GenericModal>
    ), [subscriptionPayments, posPayments, wireTransferPayments, cashPayments, satispayPayments, totalPayments]);


  return<GymDailyPerformanceInputPage
    inputList={inputList}
    uploadFunction={showConfirmModal}
    gymData={props.gymData}
    franchiseData={props.franchiseData}
    
  /> 
}
