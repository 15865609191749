import { supabase } from "../../config/config";

interface TrainerDailyPerformance {
  id: number;
  trainer_id: string; // UUID
  gym_id: number;
  performed_tests: number;
  taken_tests: number;
  planned_renewals: number;
  made_renewals: number;
  training_sessions: number;
  new_clients: number;
  work_hours: number;
  break_hours: number;
  break_work: string;
  date: string; // Date
  created_at: string; // Timestamp
  upgrade_done: number;
}

interface TargetsGym {
  id: number;
  date: string; // Date
  target_new_clients: number;
  target_renewals: number;
  target_upgrades: number;
  target_income: number;
  gym_id: number;
}
interface GymDailyPerformance {
  id: number /* primary key */;
  gym_id: number /* foreign key to gym.id */;
  created_by?: string /* foreign key to profiles.id */;
  date?: string;
  subscription_payments: any; // type unknown;
  pos_payments: any; // type unknown;
  wire_transfer_payments: any; // type unknown;
  cash_payments: any; // type unknown;
  satispay_payments: any; // type unknown;
  total_payments: number; // type unknown;
  created_at?: string;
  gym?: string;
  profiles?: string;
}
interface Targets_trainer {
  id: number /* primary key */;
  trainer_id?: string /* foreign key to profiles.id */;
  target_new_clients?: number;
  target_renewals?: number;
  target_upgrades?: number;
  target_income?: number;
  corporate_id: number /* foreign key to corporate_name.id */;
  date: string;
  created_at?: string;
  profiles?: string;
  corporate_name?: string;
}
type TableAddOn = {
  gym_name: string;
  deltaTarget: number;
  percentage_performed_new_clients: number;
  trainer_name?: string;
};
export type tableTrainer = Targets_trainer &
  TrainerDailyPerformance &
  TableAddOn &
  GymDailyPerformance;
async function sumAllTheResult(
  targets: Targets_trainer[],
  result: TrainerDailyPerformance[],
  gym_daily_performance: GymDailyPerformance[],
  corpate_gym: Map<string, string[]>
) {
  const myMap = new Map<string, tableTrainer>();
  for (let i = 0; i < result.length; i++) {
    let single_result = result[i];
    let evaluationName = single_result.gym_id + single_result.trainer_id;
    if (myMap.has(evaluationName)) {
      let old_value = myMap.get(evaluationName);
      if (old_value) {
        old_value.planned_renewals += single_result.planned_renewals;
        old_value.made_renewals += single_result.made_renewals;
        old_value.new_clients += single_result.new_clients;
        old_value.performed_tests += single_result.performed_tests;
        old_value.taken_tests += single_result.taken_tests;
        old_value.training_sessions += single_result.training_sessions;
        old_value.work_hours += single_result.work_hours;
        old_value.break_hours += single_result.break_hours;
        old_value.upgrade_done += single_result.upgrade_done;
        old_value.percentage_performed_new_clients = Math.round(
          (old_value.new_clients / old_value.performed_tests) * 100
        );
        myMap.set(evaluationName, old_value);
      }
    } else {
      let newTable: tableTrainer = {
        id: 0,
        trainer_id: single_result.trainer_id,
        gym_id: single_result.gym_id,
        performed_tests: single_result.performed_tests,
        taken_tests: single_result.taken_tests,
        planned_renewals: single_result.planned_renewals,
        made_renewals: single_result.made_renewals,
        training_sessions: single_result.training_sessions,
        new_clients: single_result.new_clients,
        work_hours: single_result.work_hours,
        break_hours: single_result.break_hours,
        break_work: single_result.break_work,
        date: single_result.date,
        created_at: single_result.created_at,
        target_new_clients: 0,
        target_renewals: 0,
        target_upgrades: 0,
        target_income: 0,
        deltaTarget: 0,
        percentage_performed_new_clients: 0,
        gym_name: "",
        corporate_id: 0,
        profiles: "",
        subscription_payments: 0,
        pos_payments: 0,
        wire_transfer_payments: 0,
        cash_payments: 0,
        satispay_payments: 0,
        total_payments: 0,
        upgrade_done: single_result.upgrade_done,
        //@ts-ignore
        trainer_name:
          //@ts-ignore
          single_result.profiles.name + " " + single_result.profiles.surname,
        //@ts-ignore
        gym_name: single_result.gym.name,
      };

      myMap.set(evaluationName, newTable);
    }
  }
  for (let i = 0; i < targets.length; i++) {
    let single_target = targets[i];
    let corporate_name = single_target.corporate_id;
    let possibleEvaulations: string[] = [];
    //@ts-ignore
    let gyms = corpate_gym.get(corporate_name);
    for (let [key, value] of corpate_gym) {
      //@ts-ignore
    }
    if (gyms) {
      possibleEvaulations = gyms.map((gym) => gym + single_target.trainer_id);
    }
    //iterate over the map
    for (let [key, value] of myMap) {
      //@ts-ignore
      for (let i = 0; i < possibleEvaulations.length; i++) {
        let possibleEvaluation = possibleEvaulations[i];
        if (key === possibleEvaluation) {
          //@ts-ignore
          value.target_new_clients += single_target.target_new_clients;
          //@ts-ignore
          value.target_renewals += single_target.target_renewals;
          //@ts-ignore
          value.target_upgrades += single_target.target_upgrades;
          //@ts-ignore
          value.target_income += single_target.target_income;
          //myMap.set(key, value);
        }
      }
    }
    for (let i = 0; i < gym_daily_performance.length; i++) {
      let single_gym_daily_performance = gym_daily_performance[i];
      // @ts-ignore
      let evaluationName =
        single_gym_daily_performance.gym_id +
        // @ts-ignore
        single_gym_daily_performance.created_by;
      if (myMap.has(evaluationName)) {
        let old_value = myMap.get(evaluationName);
        if (old_value) {
          old_value.subscription_payments +=
            single_gym_daily_performance.subscription_payments;
          old_value.pos_payments += single_gym_daily_performance.pos_payments;
          old_value.wire_transfer_payments +=
            single_gym_daily_performance.wire_transfer_payments;
          old_value.cash_payments += single_gym_daily_performance.cash_payments;
          old_value.satispay_payments +=
            single_gym_daily_performance.satispay_payments;
          old_value.total_payments +=
            single_gym_daily_performance.total_payments;
          myMap.set(evaluationName, old_value);
        }
      }
    }
  }
  let flatten = Array.from(myMap.values());
  return flatten;
}
export async function fullTableTrainer(
  first_date: string,
  last_date: string,
  gyms: string[],
  corporate_ids: string[],
  corpate_gym: Map<string, string[]>,
  trainer_ids?: string[]
) {
  //   let gyms = [119, 120, 140];
  //   let corporate_id = [15];
  //   let first_date = "2023-03-28";
  //   let last_date = "2023-09-30";
  //   let corpate_gym: Map<string, string[]> = new Map();
  //   corpate_gym.set("15", ["119", "120", "140"]);
  let result1 = supabase
    .from("trainer_daily_performance")
    .select("*,profiles(*),gym(*)")
    .in("gym_id", gyms)
    .gte("date", first_date)
    .lte("date", last_date);
  //@ts-ignore
  if (trainer_ids?.length > 0) {
    //@ts-ignore
    result1 = result1.in("trainer_id", trainer_ids);
  }
  let result = await result1;
  let targets = await supabase
    .from("targets_trainer")
    .select("*")
    .in("corporate_id", corporate_ids)
    .gte("date", first_date)
    .lte("date", last_date);
  //@ts-ignore
  let data: TrainerDailyPerformance[] = result.data;
  let gym_daily_performance = await supabase
    .from("gym_daily_performance")
    .select("*")
    .in("gym_id", gyms)
    .gte("date", first_date)
    .lte("date", last_date);
  //@ts-ignore
  let sum = await sumAllTheResult(
    //@ts-ignore
    targets.data,
    result.data,
    //@ts-ignore
    gym_daily_performance.data,
    corpate_gym
  );
  return sum;
}
