import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import { TrainerData } from "../TrainerInput/TrainerComponentContainer";
import { TableData } from "./TableReasonContainer";
import genericStyle from "../genericStyle.module.css";
import GenericTable from "../Tables/GenericTable";

const TableReason: FunctionComponent<TableData> = (props: TableData) => {
  const date = new Date();

  const getName = (id: number) => {
    let name: string = "";
    if (id) {
      props.gymData.forEach((data) => {
        console.log(typeof data.id, typeof id);
        //@ts-ignore
        if (data.id == id) {
          name = data.name;
        }
      });
    }
    return name;
  };

  return (
    <div className={genericStyle.flexCont}>
      <div>
        <h2>Riepilogo Incassi</h2>
        {props.franchiseData.name != "" ? (
          <span>Società: {props.franchiseData.name}</span>
        ) : null}
      </div>
      <div className={genericStyle.gridWrapper}>
        {props.rows.map((row) => {
          return (
            <div className={genericStyle.earningsTable} style={{ width: "90%" }}>
              {row[0] ? <span>Sede {getName(row[0].gym_id)}</span> : null}
              <GenericTable
                columns={props.columns}
                rows={props.mapFunction(row)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableReason;
