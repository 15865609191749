import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import React, { useContext, useEffect, useState,FunctionComponent } from "react";
import { TrainerData } from "../TrainerInput/TrainerComponentContainer";
import { TableData } from "./UserListPageContainer";
import genericStyle from "../genericStyle.module.css"
import GenericTable from "../Tables/GenericTable";


const UserListPage: FunctionComponent<TableData> = (props: TableData) => {

  return (<div  className={genericStyle.flexCont}>
    <div>
    <h2>Lista Trainer</h2>
    <span>Sede: {props.gymData.name}</span>
    </div>
    <div className={genericStyle.inputCont} style={{width:'auto'}}>
    </div>
    <div className={genericStyle.gridWrapper}>

    <GenericTable columns={props.columns} rows={props.rows} />
    </div>
    </div>)
};

export default UserListPage;
