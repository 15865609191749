import React from "react";
function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export abstract class Authentication {
  name: string;
  error: Object;
  constructor(name: string) {
    this.name = name;
    this.error = {};
  }
  abstract setError(error: string): void;
  abstract login(username: string, password: string): Promise<boolean>;
  abstract loginSms(username: string, password: string): Promise<boolean>;
  abstract logOut(): Promise<boolean>;
  abstract register(username: string, password: string): Promise<boolean>;
  abstract checkUsername(
    username: string
  ): Promise<boolean> | AUTHSTATUS.USERNAME_ALREADY_USED;
  abstract checkPassword(username: string, password: string): Promise<boolean>;
  abstract alreadyLogged(): Promise<boolean>;
  abstract checkRegister(): Promise<boolean>;
  abstract getToken(): Promise<string | null>;
  abstract getId(): Promise<string>;
  abstract resetPassword(email: string): Promise<boolean>;
}
export enum AUTHSTATUS {
  LOGGED,
  NOTLOGGED,
  VERIFYING,
  ERROR,
  REGISTER,
  PASSWORD_INVALID,
  USERNAME_INVALID,
  USERNAME_ALREADY_USED,
  LOGGED_OUT,
}

// write an enum for different firebase response type
