import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import { TrainerData } from "../TrainerInput/TrainerComponentContainer";
import { TableData } from "./TableFranchiseContainer";
import genericStyle from "../genericStyle.module.css";
import { CurrentMonthFormatted } from "../../Utils/DateUtil";
import GenericTable from "../Tables/GenericTable";

const TableFranchise: FunctionComponent<TableData> = (props: TableData) => {
  const date = new Date();

  return (
    <div className={genericStyle.flexCont}>
      <h2>Riepilogo clienti acquisiti per sedi </h2>
      <div className={genericStyle.gridWrapper}>
        <GenericTable columns={props.columns} rows={props.rows} />
      </div>
    </div>
  );
};

export default TableFranchise;
