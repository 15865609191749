import React, { useState } from "react";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import TrainerPage from "./TrainerPage";
import { Database } from "../../../../database.types";
import { supabase } from "../../config/config";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import genericStyle from "../genericStyle.module.css";

export interface TrainerData {
  inputList: FunctionData[];
  uploadFunction: Function;
  gymData: GymInterface;
  franchiseData: FranchiseInterface;
}

export interface FunctionData {
  callableFunction: Function;
  name: string;
  type: string;
  placeholder?: string;
  disabled?: boolean;
}
type supabaseInsterTrainerPerformance =
  Database["public"]["Tables"]["trainer_daily_performance"]["Insert"];
async function insertTrainerDailyPerformance(
  data: supabaseInsterTrainerPerformance,
  backFunction:Function
) {
  let result = await supabase.from("trainer_daily_performance").insert(data);
  if (result.error == null){
    backFunction()
  }
}

export default function TrainerComponentContainer(props: {
  gymData: GymInterface;
  franchiseData: FranchiseInterface;
  backFunction:Function
}) {
  const [message, setmessage] = useState("");
  const [performedTests, setPerformedTests] = useState(0);
  const [takenTests, setTakenTests] = useState(0);
  const [plannedRenewals, setPlannedRenewals] = useState(0);
  const [madeRenewals, setMadeRenewals] = useState(0);
  const [trainingSessions, setTrainingSessions] = useState(0);
  const [newClients, setNewClients] = useState(0);
  const [workHours, setWorkHours] = useState(0);
  const [breakHours, setBreakHours] = useState(0);
  const [breakWork, setBreakWork] = useState("");
  const [upgradePrevisti, setUpgradePrevisti] = useState(0);
  const [upgradeFatti, setUpgradeFatti] = useState(0);
  const inputList: FunctionData[] = [
    {
      callableFunction: setPerformedTests,
      name: "Prove effettuate",
      type: "number",
    },
    {
      callableFunction: setTakenTests,
      name: "Prove prese",
      type: "number",
    },
    {
      callableFunction: setPlannedRenewals,
      name: "Rinnovi previsti",
      type: "number",
    },
    {
      callableFunction: setMadeRenewals,
      name: "Rinnovi fatti",
      type: "number",
    },

    {
      callableFunction: setUpgradePrevisti,
      name: "Upgrade previsti",
      type: "number",
    },

    {
      callableFunction: setUpgradeFatti,
      name: "Upgrade fatti",
      type: "number",
    },
    {
      callableFunction: setTrainingSessions,
      name: "Allenamenti",
      type: "number",
    },
    // {
    //     callableFunction:setNewClients,
    //     name:'Nuovi clienti',
    //     type:'number'
    // },
    {
      callableFunction: setWorkHours,
      name: "Ore lavoro",
      type: "number",
    },
    {
      callableFunction: setBreakHours,
      name: "Ore buca",
      type: "number",
    },

    {
      callableFunction: setBreakWork,
      name: "Lavori svolti durante ore buca",
      type: "string",
    },
  ];

  const handleSubmit = () => {
    console.log(performedTests);
    hideConfirmModal();
    const parsedData: supabaseInsterTrainerPerformance = {
      gym_id: parseInt(props.gymData.id),
      performed_tests: performedTests,
      taken_tests: takenTests,
      planned_renewals: plannedRenewals,
      made_renewals: madeRenewals,
      training_sessions: trainingSessions,
      new_clients: takenTests,
      work_hours: workHours,
      break_hours: breakHours,
      break_work: breakWork,
      upgrade_previsional: upgradePrevisti,
      upgrade_done: upgradeFatti,
    };
    insertTrainerDailyPerformance(parsedData, props.backFunction);
  };

  const [showMessageModal, hideMessageModal] = useModal(
    () => (
      <GenericModal
        isOpen
        overlayClassName={genericStyle.modalOverlay}
        className={genericStyle.modalStyle}
      >
        <div className={genericStyle.flexModal}>
          <span>{message}</span>
          <button onClick={hideMessageModal}>Ho capito</button>
        </div>
      </GenericModal>
    ),
    [message]
  );

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <GenericModal
        isOpen
        overlayClassName={genericStyle.modalOverlay}
        className={genericStyle.modalStyle}
      >
        <div className={genericStyle.flexModal}>
          <h2>Riepilogo</h2>
          <div className={genericStyle.flexModal}>
            <span>Prove effettuate: {performedTests}</span>
            <span>Prove prese: {takenTests}</span>
            <span>Rinnovi previsti: {plannedRenewals}</span>
            <span>Rinnovi fatti: {madeRenewals}</span>
            <span>Upgrade previsti: {upgradePrevisti}</span>
            <span>Upgrade fatti: {upgradeFatti}</span>
            <span>Allenamenti: {trainingSessions}</span>
            {/* <span>Nuovi clienti: {newClients}</span> */}
            <span>Ore lavoro: {workHours}</span>
            <span>Ore buca: {breakHours}</span>
            <span>Lavori svolti durante ore buca: {breakWork}</span>
          </div>
          <div className={genericStyle.flexButton}>
            <button onClick={hideConfirmModal}>Indietro</button>
            <button onClick={handleSubmit}>Conferma</button>
          </div>
        </div>
      </GenericModal>
    ),
    [
      performedTests,
      takenTests,
      plannedRenewals,
      madeRenewals,
      trainingSessions,
      newClients,
      workHours,
      breakHours,
      breakWork,
    ]
  );

  return (
    <TrainerPage
      inputList={inputList}
      uploadFunction={showConfirmModal}
      gymData={props.gymData}
      franchiseData={props.franchiseData}
    />
  );
}
