//@ts-nocheck
import { supabase } from "../config/config";
import { Database } from "../../../database1.types";

export async function getUserName() {
  let uuid = await getUuid();
  console.log(uuid);
  let result = await supabase
    .from("profiles")
    .select("name, surname")
    .eq("id", uuid);
  return result;
}

export function cleanInput(input: string): string {
  let count = 0;
  let result = '';

  for (let i = 0; i < input.length; i++) {
      if (input[i] === '.' && count === 0) {
          result += input[i];
          count++;
      } else if (!isNaN(parseInt(input[i], 10))) {
          result += input[i];
      }
  }

  return result;
}


export async function getIdFromMail(mail: string) {
  let result = await supabase.from("profiles").select("id").eq("email", mail);
  console.log(result);
  return result.data[0].id;
}
type Role = Database["public"]["Enums"]["role"];
export async function getUuid(): string {
  let uuid = (await supabase.auth.getSession()).data.session?.user.id;
  console.log(uuid);
  return uuid;
}
export async function getRole(): Promise<Role> {
  let uuid = (await supabase.auth.getSession()).data.session?.user.id;
  console.log(uuid);
  const { data, error } = await supabase
    .from("profiles")
    .select("role")
    .eq("id", uuid);
  console.log(data);
  if (error) {
    throw new Error(error.message);
  }
  //@ts-ignore
  const role = data[0]?.role ?? null;
  if (!role) {
    throw new Error("No role found for this user.");
  }
  console.log(role);
  return role as Role;
}
export async function getCoporates(): Promise<
  Database["public"]["Tables"]["corporate_name"]
> {
  let uuid = (await supabase.auth.getSession()).data.session?.user.id;
  console.log(uuid);
  const { data, error } = await supabase
    .from("gym_trainer")
    .select(
      `
      id,
      corporate_name(id, name)
    `
    )
    .eq("trainer_id", uuid);
  console.log(data);

  if (error) {
    throw new Error(error.message);
  }
  //@ts-ignore
  for (let i = 0; i < data.length; i++) {
    data[i].name = data[i].corporate_name.name;
  }
  const franchises = data ?? null;
  if (!franchises) {
    throw new Error("No role found for this user.");
  }
  console.log(franchises);
  //@ts-ignore
  return franchises as Database["public"]["franchises"];
}

export async function addGym(data: {
  owner: string;
  corporate_id: string;
  name: string;
}) {
  let result = await supabase.from("gym").insert(data);
  return result;
}
export async function getOwnCorporate(): Promise<
  Database["public"]["Tables"]["corporate_name"]
> {
  let uuid = (await supabase.auth.getSession()).data.session?.user.id;
  console.log(uuid);
  let corporate = await supabase
    .from("corporate_name")
    .select("*")
    .eq("owner_id", uuid);
  return corporate.data[0];
}
export async function setTrainerGym(data: {
  gym_id: string;
  corporate_id: string;
  trainer_id: any;
}) {
  console.log(data);
  let result = await supabase.from("gym_trainer").insert(data);
  console.log(result);
}
export async function getMonthlyTargetByCorporateName(corporate_id: string) {
  let result = await supabase.rpc(
    "get_gym_monthly_target_by_corporate_name_id",
    { p_corporate_name_id: corporate_id }
  );
  return result.data;
}
export async function getMonthlyTargetByCorporateNameAllTrainers(
  corporate_id: string
) {
  let result = await supabase.rpc(
    "get_gyms_and_trainer_daily_performance_by_corporate_id",
    { p_corporate_name_id: corporate_id }
  );
  return result.data;
}
export async function setTargetGym(gym_id: number, target: number, date: any) {
  let existing = await supabase
    .from("gym_monthly_target")
    .select("*")
    .eq("gym_id", gym_id)
    .eq("date", date);
  if (existing.data.length > 0) {
    let result = await supabase
      .from("gym_monthly_target")
      .update({
        gym_id: gym_id,
        target: target,
        date: date,
      })
      .eq("gym_id", gym_id)
      .eq("date", date);
  } else {
    let result = await supabase.from("gym_monthly_target").insert({
      gym_id: gym_id,
      target: target,
      date: date,
    });
  }
  console.log(result);
}
export async function setTargetTrainerCorporate(
  corporate_id: number,
  trainer_id: string,
  date: string,
  target: number
) {
  let existing = await supabase
    .from("trainer_target_corporate")
    .select("*")
    .eq("trainer_id", trainer_id)
    .eq("date", date)
    .eq("corporate_id", corporate_id);
  if (existing.data.length > 0) {
    let result = await supabase
      .from("trainer_target_corporate")
      .update({
        trainer_id: trainer_id,
        corporate_id: corporate_id,
        target: target,
        date: date,
      })
      .eq("gym_id", gym_id)
      .eq("date", date);
  } else {
    let result = await supabase.from("trainer_target_corporate").insert({
      corporate_id: corporate_id,
      trainer_id: trainer_id,
      target: target,
      date: date,
    });
  }
  console.log(result);
}
export async function removeTrainer(trainer_id: any, gym_id: number) {
  let result = await supabase
    .from("gym_trainer")
    .delete()
    .eq("trainer_id", trainer_id)
    .eq("gym_id", gym_id);
  console.log(result);
  return result;
}
type getAllTrainer =
  Database["public"]["Functions"]["get_trainers_by_gym_id"]["Args"];
export async function getAllTrainers(
  gym_id: getAllTrainer["p_gym_id"]
): Promise<Database["public"]["Tables"]["trainer"]> {
  let result = await supabase.rpc("get_trainers_by_gym_id", { gym_id: gym_id });
  console.log(result);
  return result.data;
}

export async function setRole(email: string, role: string) {
  let result = await supabase
    .from("profiles")
    .update({ role: role })
    .eq("email", email);
  console.log(result);
}

export async function get_corporate_daily_performance(
  corporate_id: number,
  start_date: string,
  end_date: string
) {
  let args: supabaseGetDailyPerformance = {
    p_gym_id: 1,
    start_date: start_date,
    end_date: end_date,
  };
  let gyms = await getAllIds(corporate_id);
  let arr = [];
  for (let i = 0; i < gyms.length; i++) {
    args.p_gym_id = gyms[i];
    let result = await get_gym_daily_performance(args);
    try {
      //@ts-ignore
      if (result.data?.length == 0) {
        continue;
      }
      //@ts-ignore
      else {
        arr.push(result.data);
      }
      //@ts-ignore
    } catch (error) { }
  }

  console.log(arr);
  return arr;
}
async function getAllIds(corporate_id: number): Promise<number[]> {
  let gyms = await getAllGyms(corporate_id);
  if (gyms.length == 0) return [];
  let new_gyms: number[] = [];
  for (let i = 0; i < gyms.length; i++) {
    let element = gyms[i];
    new_gyms.push(element.id);
  }
  return new_gyms;
}
type supabaseGetDailyPerformance =
  Database["public"]["Functions"]["get_gym_daily_performance"]["Args"];

export async function get_gym_daily_performance(
  args: supabaseGetDailyPerformance
) {
  let result = await supabase.rpc("get_gym_daily_performance1", args);
  console.log(result);
  let data = result.data;
  if (data == null) return;
  let progressive_pos_payments = 0;
  let progressive_cash_payments = 0;
  let progressive_satispay_payments = 0;
  let progressive_wire_transfer_payments = 0;
  let progressive_subscription_payments = 0;
  let progressive_total_payments = 0;

  for (let i = 0; i < data.length; i++) {
    let element = data[i];
    progressive_pos_payments += element.pos_payments;
    progressive_cash_payments += element.cash_payments;
    progressive_satispay_payments += element.satispay_payments;
    progressive_wire_transfer_payments += element.wire_transfer_payments;
    progressive_subscription_payments += element.subscription_payments;
    progressive_total_payments += element.total_payments;
    element.progressive_pos_payments = progressive_pos_payments;
    element.progressive_cash_payments = progressive_cash_payments;
    element.progressive_satispay_payments = progressive_satispay_payments;
    element.progressive_wire_transfer_payments =
      progressive_wire_transfer_payments;
    element.progressive_subscription_payments =
      progressive_subscription_payments;
    element.progressive_total_payments = progressive_total_payments;
  }
  console.log(result);
  return result;
}
async function getAllGyms(corporate_id: number): Promise<any[]> {
  let uuid = (await supabase.auth.getSession()).data.session?.user.id;
  let result = await supabase
    .from("gym")
    .select("*")
    .eq("corporate_id", corporate_id);
  console.log(result.data);
  //@ts-ignore
  return result.data;
}
type gyms = Database["public"]["Tables"]["gym"]["Row"];
export async function getAllTrainerDailyPerfromance(
  corporate_id: number,
  date1: string,
  date2: string
) {
  let gyms: gyms[] = await getAllGyms(corporate_id);
  let arr = [];
  for (let i = 0; i < gyms.length; i++) {
    let element = gyms[i];
    let result = await supabase
      .from("trainer_daily_performance")
      .select("*")
      .eq("gym_id", element.id)
      .gte("date", date1)
      .lte("date", date2);
    arr.push(result.data);
  }
}
type argstableFranchiseFunction =
  Database["public"]["Functions"]["get_gyms_and_trainer_daily_performance_by_corporate_id"]["Args"];
export type returnsTableFranchiseFunction =
  Database["public"]["Functions"]["get_gyms_and_trainer_daily_performance_by_corporate_id"]["Returns"];
export async function tableFranchiseContainer(
  args: argstableFranchiseFunction
): Promise<returnsTableFranchiseFunction> {
  let result = await supabase.rpc(
    "get_gyms_and_trainer_daily_performance_by_corporate_id",
    args
  );
  console.log(result);
  return result.data;
}

export async function setVisibleCorporate(
  corporate_id: number,
  visible: boolean
) {
  let result = await supabase.from("corporate_name").upsert(
    {
      id: corporate_id,
      visible_by_franchise: visible,
    },
    { onConflict: "id" }
  );
  console.log(result);
}
export async function getVisible(corporate_id: number) {
  let result = await supabase
    .from("corporate_name")
    .select("*")
    .eq("id", corporate_id);
  console.log(result);
  return result.data[0].visible_by_franchise;
}
export async function get_trainers_for_gym(corporate_id: number) {
  let result = await supabase.rpc("get_trainers_for_gym", {
    corporate_id: corporate_id,
  });
  return result.data;
}

export async function set_name_surname(name: string, surname: string, uuid: string) {
  let result = await supabase
    .from("profiles")
    .update({ name: name, surname: surname })
    .eq("id", uuid);
  console.log(result);
}

export async function createCorporate(corporate_string: string) {
  let uuid = await getUuid();
  let result = await supabase.from("corporate_name").insert({
    name: corporate_string,
    visible_by_franchise: false,
    owner_id: uuid,
  });
  console.log(result);
}
export async function setGymHolder() {
  let uuid = await getUuid();
  let result = await supabase
    .from("profiles")
    .update({ role: "gym_holder" })
    .eq("id", uuid);
  console.log(result);
}

// export async function setTrainerGym(gym_id:number, trainer_id:number){
//   let result = await supabase.from('trainers').update({gym_id:gym_id}).eq('id', trainer_id)
//   console.log(result)
// }
export async function getGymsFranchise() {
  let uuid = await getUuid();
  let result = await supabase
    .from("gym")
    .select("*,corporate_name(*)")
    .neq("corporate_name.owner_id", uuid).
    eq('visible',true)
  console.log(result);
  return result.data;
}
export async function parseGymFranchise() {
  let result = await getGymsFranchise();
  let arr = [];

  for (let i = 0; i < result.length; i++) {
    try {
      let element = result[i];
      let obj = {
        id: element.id,
        name: element.name,
        corporate_name: element.corporate_name.name,
        corporate_id: element.corporate_name.id,
      };
      arr.push(obj);
    } catch (error) {
      console.log(error);
    }
  }
  //sort by name 
  arr.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  return arr;
}
export async function getAllCorporateNotAccepted() {
  let uuid = await getUuid();
  let result = await supabase
    .from("corporate_name")
    .select("*")
    .eq("accepted", false);
  console.log(result);
  return result.data;
}
export async function getAllTrainersGyms() {
  let result = await supabase.from("gym_trainer").select("*,profiles(*)");
  // .eq("trainer_id", "profiles.id");
  let data = result.data;

  let trainers = [];
  let names = [];
  for (let i = 0; i < data.length; i++) {
    let element = data[i];
    let fullName =
      element.profiles.name +
      " " +
      element.profiles.surname +
      " " +
      element.profiles.id;
    if (names.includes(fullName)) {
      continue;
    }
    names.push(
      element.profiles.name +
      " " +
      element.profiles.surname +
      " " +
      element.profiles.id
    );

    let object = {
      id: element.profiles.id,
      name: element.profiles.name + " " + element.profiles.surname,
    };
    trainers.push(object);
  }
  return trainers;
}
