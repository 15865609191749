import React, { useEffect, useState } from "react";
import { FranchiseInterface, GymInterface } from "../ChioicePage/ChoicePageContainer";
import RemoveTrainer from "./RemoveTrainer";
import {Database} from "../../../../database.types";
import {supabase} from "../../config/config";
import {getIdFromMail, removeTrainer, setTrainerGym} from "../../Utils/utils";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import genericStyle from "../genericStyle.module.css"


export interface TrainerData {
    inputList: FunctionData[],
    uploadFunction:Function;
    gymData:GymInterface;
    franchiseData:FranchiseInterface;
    trainerData:any[];
    updateTrainers:Function;
    setData:Function;
  }

export interface FunctionData{
    callableFunction:Function;
    name:string;
    type:string;
}
type supabaseInsterTrainerPerformance = Database['public']['Tables']['trainer_daily_performance']['Insert']
async function insertTrainerDailyPerformance(data:supabaseInsterTrainerPerformance){
    let result = await  supabase.from('trainer_daily_performance').insert(data)
    console.log(result)
}

export default function RemoveTrainerContainer(props:{gymData:GymInterface, franchiseData:FranchiseInterface}) {
    const [trainerId, settrainerId] = useState('');
    const [name, setname] = useState('');
    const [surname, setsurname] = useState('');
    const [message, setmessage] = useState('');
    const [trainers, settrainers] = useState<any>([]);

    const fetchTrainers = async () =>{
        let result = await  supabase.from('gym_trainer').select('*, profiles(*)').eq('gym_id', props.gymData.id).then(res=>{
            if (res.error == null){
                settrainers(res.data)
            }
        })

    }

    useEffect(()=>{
        fetchTrainers()
    }, [])

    const inputList:FunctionData[] = [
        {
            callableFunction:settrainerId,
            name:'trainerId',
            type:'text'
        },        {
            callableFunction:setname,
            name:'Nome',
            type:'text'
        },
        {
            callableFunction:setsurname,
            name:'Cognome',
            type:'text'
        },
        
    ]

    const setData = (event:any ) =>{
        settrainerId(event.target.value)
        setname(event.target.options[event.target.selectedIndex].getAttribute('data-trainername'))
        setsurname(event.target.options[event.target.selectedIndex].getAttribute('data-trainersurname'))
    }


    //@calle fatto  gestisci i dati da aaggiungere

    const handleSubmit = async() => {
        hideConfirmModal()
        let data = {trainer_id:trainerId,gym_id:props.gymData.id,corporate_id:props.franchiseData.id}
        //non ritornarmi void, ma la promessa stessa, da qui poi setti il messaggio (setMessage) e chiami showMessageModal
        //@ts-ignore
        let result = await removeTrainer(trainerId,data.gym_id)
    };

    
    const [showMessageModal, hideMessageModal] =  useModal(() => (
        <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
            <div className={genericStyle.flexModal}>
                <span>{message}</span>
            <button onClick={hideMessageModal}>Ho capito</button>
            </div>
        </GenericModal>
      ), [message]);

    const [showConfirmModal, hideConfirmModal] =  useModal(() => (
        <GenericModal isOpen overlayClassName={genericStyle.modalOverlay} className={genericStyle.modalStyle}>
            <div className={genericStyle.flexModal}>
            <h2>Rimuovere Trainer</h2>
            <div className={genericStyle.flexModal}>
                <span>{name} {surname}</span>
            </div>
            <div className={genericStyle.buttonWrapper}>
            <button onClick={hideConfirmModal}>Indietro</button>
            <button onClick={handleSubmit}>Conferma</button>
            </div>
            </div>
        </GenericModal>
      ), [name, surname]);

    return (
        <RemoveTrainer
            inputList={inputList}
            uploadFunction={showConfirmModal}
            gymData={props.gymData}
            franchiseData={props.franchiseData}
            trainerData={trainers}
            updateTrainers = {fetchTrainers}
            setData={setData}
        />  
    );
}
