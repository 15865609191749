import React, { useEffect, useState } from "react";
import {
  FranchiseInterface,
  GymInterface,
} from "../ChioicePage/ChoicePageContainer";
import TrainerPage from "./TrainerPage";
import { Database } from "../../../../database.types";
import { supabase } from "../../config/config";
import { useModal } from "react-modal-hook";
import { GenericModal } from "../GenericModal/GenericModal";
import genericStyle from "../genericStyle.module.css";
import { getUuid } from "../../Utils/utils";
import { useStore } from "../../steteManagement/zustandState";
import { getStartDate } from "../../Utils/DateUtil";

export interface TrainerData {
  inputList: FunctionData[];
  uploadFunction: Function;
  franchiseData: FranchiseInterface;
  gymList: any[];
  updateGymList: Function;
  setgymId: Function;
  trainerData: any[];
  updateTrainers: Function;
  setData: Function;
  date: Date;
  setDate: Function;
  disableButton: boolean;
}

export interface FunctionData {
  callableFunction: Function;
  name: string;
  type: string;
  placeholder?: string | "";
  disabled?: boolean;
}
type supabaseInsterTrainerPerformance =
  Database["public"]["Tables"]["trainer_daily_performance"]["Insert"];
async function insertTrainerDailyPerformance(
  data: supabaseInsterTrainerPerformance,
  dailyPerformance: any
) {
  if (dailyPerformance) {
    let result = await supabase
      .from("trainer_daily_performance")
      .update(data)
      .eq("id", dailyPerformance.id);
  } else {
    let result = await supabase.from("trainer_daily_performance").insert(data);
  }
}

export default function EditTrainerComponentContainer(props: {
  franchiseData: FranchiseInterface;
}) {
  const [message, setmessage] = useState("");
  const [performedTests, setPerformedTests] = useState(0);
  const [takenTests, setTakenTests] = useState(0);
  const [plannedRenewals, setPlannedRenewals] = useState(0);
  const [madeRenewals, setMadeRenewals] = useState(0);
  const [trainingSessions, setTrainingSessions] = useState(0);
  const [newClients, setNewClients] = useState(0);
  const [workHours, setWorkHours] = useState(0);
  const [breakHours, setBreakHours] = useState(0);
  const [breakWork, setBreakWork] = useState("");
  const [upgradePrevisti, setUpgradePrevisti] = useState(0);
  const [upgradeFatti, setUpgradeFatti] = useState(0);
  const [gymId, setgymId] = useState("");
  const [trainers, settrainers] = useState<any>([]);
  const [disable, setdisable] = useState<boolean>(true);

  const [gymList, setgymList] = useState<any[]>([]);
  const [date, setDate] = React.useState<Date>(new Date());
  const [dailyPerformance, setdailyPerformance] = React.useState<any[]>([]);

  const defaultInputList = [
    {
      callableFunction: setPerformedTests,
      name: "Prove effettuate",
      type: "number",
    },
    {
      callableFunction: setTakenTests,
      name: "Prove prese",
      type: "number",
    },
    {
      callableFunction: setPlannedRenewals,
      name: "Rinnovi previsti",
      type: "number",
    },
    {
      callableFunction: setMadeRenewals,
      name: "Rinnovi fatti",
      type: "number",
    },

    {
      callableFunction: setUpgradePrevisti,
      name: "Upgrade previsti",
      type: "number",
    },

    {
      callableFunction: setUpgradeFatti,
      name: "Upgrade fatti",
      type: "number",
    },
    {
      callableFunction: setTrainingSessions,
      name: "Allenamenti",
      type: "number",
    },
    // {
    //     callableFunction:setNewClients,
    //     name:'Nuovi clienti',
    //     type:'number'
    // },
    {
      callableFunction: setWorkHours,
      name: "Ore lavoro",
      type: "number",
    },
    {
      callableFunction: setBreakHours,
      name: "Ore buca",
      type: "number",
    },

    {
      callableFunction: setBreakWork,
      name: "Lavori svolti durante ore buca",
      type: "string",
    },
  ];

  const [inputList, setInputList] =
    React.useState<FunctionData[]>(defaultInputList);

  const [trainerId, settrainerId] = useState("");
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");

  const {
    firstName,
    lastName,
    role,
    updateFirstName,
    updateLastName,
    updateRole,
  } = useStore();
  const getOwner = async () => {
    let test = null;
    if (role == "franchisee") {
      //@ts-ignore
      test = await supabase
        .from("gym")
        .select("*, corporate_name(*)")
        .order("name", { ascending: true });
    } else {
      const uuid = await getUuid();
      //@ts-ignore
      test = await supabase
        .from("gym")
        .select("*, corporate_name!inner(*)")
        .eq("corporate_name.owner_id", uuid)
        .order("name", { ascending: true });
    }
    if (test.data) {
      setgymList(test.data);
    }
  };

  const setData = (event: any) => {
    settrainerId(event.target.value);
    setname(
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-trainername"
      )
    );
    setsurname(
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-trainersurname"
      )
    );
  };

  const fetchTrainers = async () => {
    let result = await supabase
      .from("gym_trainer")
      .select("*, profiles(*)")
      .eq("gym_id", gymId)
      .then((res) => {
        if (res.error == null) {
          settrainers(res.data);
        }
      });
  };
  const fetchDayilyPerformance = async () => {
    let result = await supabase
      .from("trainer_daily_performance")
      .select("*")
      .eq("gym_id", gymId)
      .eq("trainer_id", trainerId)
      .eq("date", getStartDate(date))
      .then((res) => {
        if (res.error == null) {
          setdailyPerformance(res.data[0]);
        }
      });
  };

  const editInputList = (data: any) => {
    if (data) {
      setInputList([
        {
          callableFunction: setPerformedTests,
          name: "Prove effettuate",
          type: "number",
          placeholder: data.performed_tests,
        },
        {
          callableFunction: setTakenTests,
          name: "Prove prese",
          type: "number",
          placeholder: data.taken_tests,
        },
        {
          callableFunction: setPlannedRenewals,
          name: "Rinnovi previsti",
          type: "number",
          placeholder: data.planned_renewals,
        },
        {
          callableFunction: setMadeRenewals,
          name: "Rinnovi fatti",
          type: "number",
          placeholder: data.made_renewals,
        },

        {
          callableFunction: setUpgradePrevisti,
          name: "Upgrade previsti",
          type: "number",
          placeholder: data.upgrade_previsional,
        },

        {
          callableFunction: setUpgradeFatti,
          name: "Upgrade fatti",
          type: "number",
          placeholder: data.upgrade_done,
        },
        {
          callableFunction: setTrainingSessions,
          name: "Allenamenti",
          type: "number",
          placeholder: data.training_sessions,
        },
        // {
        //     callableFunction:setNewClients,
        //     name:'Nuovi clienti',
        //     type:'number'
        // },
        {
          callableFunction: setWorkHours,
          name: "Ore lavoro",
          type: "number",
          placeholder: data.work_hours,
        },
        {
          callableFunction: setBreakHours,
          name: "Ore buca",
          type: "number",
          placeholder: data.break_hours,
        },

        {
          callableFunction: setBreakWork,
          name: "Lavori svolti durante ore buca",
          type: "string",
          placeholder: data.break_work,
        },
      ]);
      setBreakHours(data.break_hours);
      setBreakWork(data.break_work);
      setWorkHours(data.work_hours);
      setTrainingSessions(data.training_sessions);
      setPerformedTests(data.performed_tests);
      setTakenTests(data.taken_tests);
      setPlannedRenewals(data.planned_renewals);
      setMadeRenewals(data.made_renewals);
      setUpgradePrevisti(data.upgrade_previsional);
      setUpgradeFatti(data.upgrade_done);
    } else {
      setInputList(defaultInputList);
      setBreakHours(0);
      setBreakWork("");
      setWorkHours(0);
      setTrainingSessions(0);
      setPerformedTests(0);
      setTakenTests(0);
      setPlannedRenewals(0);
      setMadeRenewals(0);
      setUpgradePrevisti(0);
      setUpgradeFatti(0);
    }
  };
  useEffect(() => {
    if (trainerId != "" && gymId != undefined) {
      setdisable(false);
    }
  }, [trainerId, gymId]);
  useEffect(() => {
    getOwner();
  }, []);
  useEffect(() => {
    if (gymId != "") {
      fetchTrainers();
    }
  }, [gymId]);
  useEffect(() => {
    if (date && gymId != "" && trainerId != "") {
      setdisable(false);
      fetchDayilyPerformance();
    } else setdisable(true);
  }, [date, gymId, trainerId]);
  useEffect(() => {
    editInputList(dailyPerformance);
  }, [dailyPerformance]);

  const handleSubmit = () => {
    console.log(performedTests);
    hideConfirmModal();
    const parsedData: supabaseInsterTrainerPerformance = {
      gym_id: parseInt(gymId),
      performed_tests: performedTests,
      taken_tests: takenTests,
      planned_renewals: plannedRenewals,
      made_renewals: madeRenewals,
      training_sessions: trainingSessions,
      new_clients: takenTests,
      work_hours: workHours,
      break_hours: breakHours,
      break_work: breakWork,
      upgrade_previsional: upgradePrevisti,
      upgrade_done: upgradeFatti,
    };
    insertTrainerDailyPerformance(parsedData, dailyPerformance).then((res) =>
      fetchDayilyPerformance()
    );
  };

  const [showMessageModal, hideMessageModal] = useModal(
    () => (
      <GenericModal
        isOpen
        overlayClassName={genericStyle.modalOverlay}
        className={genericStyle.modalStyle}
      >
        <div className={genericStyle.flexModal}>
          <span>{message}</span>
          <button onClick={hideMessageModal}>Ho capito</button>
        </div>
      </GenericModal>
    ),
    [message]
  );

  const getSede = () => {
    for (let x in gymList) {
      if ((gymList[x].id = gymId)) {
        return gymList[x].name;
      }
    }
    return "";
  };

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <GenericModal
        isOpen
        overlayClassName={genericStyle.modalOverlay}
        className={genericStyle.modalStyle}
      >
        <div className={genericStyle.flexModal}>
          <h2>Riepilogo</h2>
          <div className={genericStyle.flexModal}>
            <span>Sede: {getSede()}</span>
            <span>Trainer: {name + " " + surname}</span>
            <span>Data: {getStartDate(date)}</span>
            <span>Prove effettuate: {performedTests}</span>
            <span>Prove prese: {takenTests}</span>
            <span>Rinnovi previsti: {plannedRenewals}</span>
            <span>Rinnovi fatti: {madeRenewals}</span>
            <span>Upgrade previsti: {upgradePrevisti}</span>
            <span>Upgrade fatti: {upgradeFatti}</span>
            <span>Allenamenti: {trainingSessions}</span>
            {/* <span>Nuovi clienti: {newClients}</span> */}
            <span>Ore lavoro: {workHours}</span>
            <span>Ore buca: {breakHours}</span>
            <span>Lavori svolti durante ore buca: {breakWork}</span>
          </div>
          <div className={genericStyle.flexButton}>
            <button onClick={hideConfirmModal}>Indietro</button>
            <button onClick={handleSubmit}>Conferma</button>
          </div>
        </div>
      </GenericModal>
    ),
    [
      performedTests,
      takenTests,
      plannedRenewals,
      madeRenewals,
      trainingSessions,
      newClients,
      workHours,
      breakHours,
      breakWork,
    ]
  );

  return (
    <TrainerPage
      inputList={inputList}
      uploadFunction={showConfirmModal}
      franchiseData={props.franchiseData}
      gymList={gymList}
      updateGymList={getOwner}
      setgymId={setgymId}
      trainerData={trainers}
      updateTrainers={fetchTrainers}
      setData={setData}
      date={date}
      setDate={setDate}
      disableButton={disable}
    />
  );
}
