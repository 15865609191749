import { Column, DefaultCellTypes, Row } from "@silevis/reactgrid";
import React, { useContext, useEffect, useState } from "react";
import { FranchiseInterface, GymInterface } from "../ChioicePage/ChoicePageContainer";
import { FunctionData } from "../TrainerInput/TrainerComponentContainer";
import UserListPage from "./UserListPage";
import { Database } from "../../../../database1.types";
import { supabase } from "../../config/config";
import { CellData, Columns } from "../../Utils/tableInterface";
import { getAllTrainers } from "../../Utils/utils";

interface Person {
  name: string;
  surname: string;
}

export interface TableData {
  columns: Columns[];
  rows: CellData[];
  gymData: GymInterface
}

const getRows = (rows: userData[]) => [
  
  ...rows.map<CellData>((row, idx) => ({

    cells: [
      { type: "text", text: row.email ? row.email : "" },
      {
        type: "text",
        text: row.name ? row.name : "",
      },
      {
        type: "text",
        text: row.surname ? row.surname : "",
      },
    ],
  }))
];

const columns: Columns[] = [
  {
    name: "Mail",
    width: 250,
    sticky:true,
  },
  {
    name: "Nome",
    width: 250,
  },
  {
    name: "Cognome",
    width: 250,
  },
  {
    name: "Target",
    width: 250,
  },
  
];

const getPeople = (): userData[] => [
  {
    email: 'a',
    id: 'string',
    name: 'string',
    role: 'admin',
    surname: 'string',
  },
];

//@beppe, da mettere a posto oggetto che ricevo
type userData =
  Database["public"]["Tables"]["profiles"]["Row"];

export interface TempProps{
  gymData:GymInterface
}

export default function UserListPageContainer(props:TempProps) {
  const [people, setPeople] = React.useState<userData[]>(getPeople());
  const [rows, setRows] = React.useState<CellData[]>(
    getRows(people) 
  );


  useEffect(() => {
    async function sendTrainerTableProps(gym_id: number) {
      //let b  = await supabase.rpc('get_trainers_by_gym_id', { p_gym_id:gym_id});
      let c1 = await getAllTrainers(gym_id)
      //@ts-ignore 
      for (let i = 0; i < c1.length; i++) {
        //@ts-ignore  
        c1[i].name = c1[i].trainer_name
        c1[i].surname = c1[i].trainer_surname
        c1[i].email = c1[i].trainer_email 
      }
      if (c1.length > 0) {
        setRows(getRows(c1)) 
      }
      else{
        setRows([])
      }
    } 
    sendTrainerTableProps(parseInt(props.gymData.id));
  }, []);

  useEffect(() => {
    console.log(rows);
  }, [rows]);

  return <UserListPage rows={rows} columns={columns} gymData={props.gymData} />;
}
