import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import { HeaderContainerProps } from "./HeaderContainer";
import style from "./headerStyles.module.css"
import adminStyle from "./../AdminPage/ListUser/AdminCss.module.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import genericStyle from "../genericStyle.module.css"
import Logo from "../../Assets/training_lab.png" 




const Header: FunctionComponent = () => {

  return (
    <div className={style.headerCont}>
      <div className={genericStyle.imgWrap}>
        <img src={Logo} className={genericStyle.logo} alt="" />
      </div>
    </div>
  );
};

export default Header;
