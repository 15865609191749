import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import { TrainerData } from "../TrainerInput/TrainerComponentContainer";
import { TableData } from "./TrainerTableIncassiContainer";
import genericStyle from "../genericStyle.module.css";
import GenericTable from "../Tables/GenericTable";
import { ThemeContext } from "../../context/ThemeContext";

const CreateInput = (type: string, name: string, onChange: Function) => {
  return (
    <div key={type + name}>
      <span>{name}</span>
      <input
        type={type}
        name={name}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

const TrainerTable: FunctionComponent<TableData> = (props: TableData) => {
  const themeContext = React.useContext(ThemeContext);

  const date = new Date();

  return (
    <div className={genericStyle.flexContUp}>
      <div className={genericStyle.flexTitle}>
        <h2>Riepilogo Lavoro</h2>
        <div>
          <div className={genericStyle.inputCont} style={{ width: "auto" }}>
            <span>Utente: {themeContext?.nameSurname}</span>
            <span>Società: {props.franchiseData.name}</span>
            <span>Sede: {props.gymData.name}</span>
          </div>
        </div>
        <div className={genericStyle.gridWrapper}>
          <GenericTable columns={props.columns} rows={props.rows} />
        </div>
      </div>
    </div>
  );
};

export default TrainerTable;
