import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import SignUpFranchise from "./SignUpFranchise";
import React from "react";
import { supabase } from "../../config/config";
import { useNavigate } from "react-router";
import SignUpUser from "./SignUpUser";
import { set_name_surname } from "../../Utils/utils";
export interface LoginProps {
  username: string;
  password: string;
  name: string;
  surname?: string;
  usernamePlaceHolder: string;
  passwordPlaceHolder: string;
  usernameTitlePlaceHolder: string;
  passwordTitlePlaceHolder: string;
  onChangeUsername(username: string): void | undefined;
  onChangePassword(password: string): void | undefined;
  onChangeName(name: string): void | undefined;
  onChangeSurname?(surname: string): void | undefined;
  onLoginClick(): void;
  isLoginButtonVisible(): boolean | undefined;
  onClickRegister(): void;
  errorUsername: string;
  errorPassword: string;
  loginPlaceHolder: string;
  passwordSecureNumber: number;
  registerText: string;
  move_to_login: boolean;
  signUpError: boolean;
  signUpSuccess: boolean
}

export default function SignUpContainer(props: { type: string }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [securePassword, setSecurePassword] = useState(0);
  const language = useContext(LanguageContext)!;
  let loginPlaceHolder = language.t("login");
  let usernamePlaceHolderText = language.t("username");
  let passwordPlaceHolderText = language.t("password");
  let usernameTitlePlaceHolderText = language.t("usernameTitle");
  let passwordTitlePlaceHolderText = language.t("passwordTitle");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorSurname, setErrorSurname] = useState("");
  const [move_to_login, setMoveToLogin] = useState(false);
  const [signUperror, setsignUpError] = useState(false)
  const [signUpSuccess, setsignUpSuccess] = useState(false)
  const navigation = useNavigate()

  useEffect(() => {

    const checkLogged = async () => {
      let logged = await supabase.auth.getUser()
      if (logged.data.user != null) {
        navigation('/dashboard')
      }
    }
    checkLogged()
  }, [])

  const onChangeUsername = (username: string) => {
    setUsername(username);
    let error = false;

    if (error) {
      setErrorUsername(error);
    } else {
      //setErrorUsername(error);
    }
  };
  const onChangeName = (username: string) => {
    setname(username);
    let error = false;

    if (error) {
      setErrorName(error);
    } else {
      //setErrorUsername(error);
    }
  };
  const onChangeSurname = (surname: string) => {
    setsurname(surname);
    let error = false;

    if (error) {
      setErrorSurname(error);
    } else {
      //setErrorUsername(error);
    }
  };
  function onClickRegister() {
    // navigation.navigate("SingUp");//this must be on the child component
  }
  function onChangePassword(password: string): void {
    setPassword(password);
    let error = false;
    if (error) {
      setErrorPassword(error);
    } else {
      //setErrorPassword(error);
    }
  }
  const onLoginClick = async () => {
    setsignUpError(false)
    //login only if email and password are valid and login with authCurrent
    onChangePassword(password);
    onChangeUsername(username);
    const name1 = name;
    const surname1 = surname;
    if (errorUsername === "" && errorPassword === "") {
      //let result = await auth.login(username, password);
      let result = await supabase.auth.signUp({
        email: username,
        password: password,
      });
      //@ts-ignore
      let id = result.data?.user.id;

      if (result.error == null) {
        setsignUpSuccess(true)
        set_name_surname(name1, surname1, id)
        //alert("successfull registration");
        //navigation('/dashboard')
        //setMoveToLogin(true);
      } else {
        setsignUpError(true)
      }
    } else {
      alert("Please enter valid email and password");
    }
  };
  const isLoginButtonVisible = () => {
    return true;
  };

  const onChangeTheme = () => { };
  if (props.type == 'franchise') {
    return (
      <SignUpFranchise
        username={username}
        password={password}
        name={name}
        usernamePlaceHolder={usernamePlaceHolderText}
        usernameTitlePlaceHolder={usernameTitlePlaceHolderText}
        passwordPlaceHolder={passwordPlaceHolderText}
        passwordTitlePlaceHolder={passwordTitlePlaceHolderText}
        onChangeUsername={onChangeUsername}
        onChangePassword={onChangePassword}
        onChangeName={onChangeName}
        onLoginClick={onLoginClick}
        isLoginButtonVisible={isLoginButtonVisible}
        onClickRegister={onClickRegister}
        errorUsername={""}
        errorPassword={""}
        loginPlaceHolder={loginPlaceHolder}
        passwordSecureNumber={0}
        registerText={""}
        move_to_login={false}
        signUpError={signUperror}
        signUpSuccess={signUpSuccess}
      ></SignUpFranchise>
    );
  }

  else if (props.type == 'user') {
    return (
      <SignUpUser
        username={username}
        password={password}
        name={name}
        surname={surname}
        usernamePlaceHolder={usernamePlaceHolderText}
        usernameTitlePlaceHolder={usernameTitlePlaceHolderText}
        passwordPlaceHolder={passwordPlaceHolderText}
        passwordTitlePlaceHolder={passwordTitlePlaceHolderText}
        onChangeUsername={onChangeUsername}
        onChangePassword={onChangePassword}
        onChangeName={onChangeName}
        onChangeSurname={onChangeSurname}
        onLoginClick={onLoginClick}
        isLoginButtonVisible={isLoginButtonVisible}
        onClickRegister={onClickRegister}
        errorUsername={""}
        errorPassword={""}
        loginPlaceHolder={loginPlaceHolder}
        passwordSecureNumber={0}
        registerText={""}
        move_to_login={false}
        signUpError={signUperror}
        signUpSuccess={signUpSuccess}
      ></SignUpUser>
    );
  }
  else {
    return (
      <div></div>
    )
  }
}
