import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../../config/config";
import { getAllCorporateNotAccepted } from "../../Utils/utils";

interface CorporateName {
  id: number;
  owner_id: string;
  name: string;
  visible_by_franchise: boolean;
  accepted?: boolean;
}

export interface AcceptCorporateProps {}
export default function AcceptCorporateContainer(props: AcceptCorporateProps) {
  const [corporatesToAccept, setCorporatesToAccept] = useState<CorporateName[]>(
    []
  );
  useEffect(() => {
    getAllCorporateNotAccepted().then((data) => {
      //@ts-ignore
      setCorporatesToAccept(data);
    });
  }, []);

  const handleAccept = async (corporate: CorporateName) => {
    const { data, error } = await supabase
      .from("corporate_name")
      .update({ accepted: true })
      .eq("id", corporate.id);
    if (error) {
      console.error(error);
    } else {
      setCorporatesToAccept((prevState) =>
        prevState.filter((c) => c.id !== corporate.id)
      );
    }
  };

  const handleDecline = async (corporate: CorporateName) => {
    const { data, error } = await supabase
      .from("corporate_name")
      .delete()
      .eq("id", corporate.id);
    if (error) {
      console.error(error);
    } else {
      setCorporatesToAccept((prevState) =>
        prevState.filter((c) => c.id !== corporate.id)
      );
    }
  };

  if (corporatesToAccept.length === 0) {
    return <div>Non ci sono nuove richieste di corporate</div>;
  }

  return (
    <div style={{backgroundColor:'#f2f2f2'}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        {corporatesToAccept.map((corporate) => (
          <div
            key={corporate.id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              margin: "8px 0",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <p style={{ margin: 0, flex: 1 }}>{corporate.name}</p>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <button onClick={() => handleAccept(corporate)}>Accetta</button>
              <button onClick={() => handleDecline(corporate)}>Rifiuta</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
