// @ts-nocheck
import { createContext, FunctionComponent } from "react";
// import { languageUtils } from "./LanguageUtils";

interface LanguageProviderProps {
  currentLanguage: string;
  t(key: string): string;
}
export const LanguageContext = createContext<LanguageProviderProps | null>(
  null
);
//@ts-ignore
const LanguageProvider = ({ children }: any) => {
  const currentLanguage: string = "en";
  function t(key: string): string {
    try {
      //@ts-ignore
      let b: any = languages[currentLanguage][key];

      return b;
    } catch (error) {
      return "not implemented";
    }
  }
  return (
    <LanguageContext.Provider value={{ currentLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};
const languages = {
  en: {
    username: "username",
    password: "password",
    resetPassword: "Reset password",
    confirmPassword: "Confirm password",
    newPassword: "New password",
    test: "testENG",
  },
  it: {
    username: "Scrivi il tuo indirizzo e-mail",
    password: "Scrivi la tua password ",
    usernameTitle: "Indirizzo e-mail",
    passwordTitle: "Password",
    resetPassword: "Reset password",
    confirmPassword: "Conferma password",
    newPassword: "Nuova password",
    welcome: "Ciao",
    test: "testITA",
  },
};

export default LanguageProvider;
